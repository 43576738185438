import { v4 as uuidv4 } from "uuid";
import { mapMutations, mapState } from "vuex";

import ModalTaxInformation from "../../components/ModalTaxInformation/Index-TaxInformation.vue";
import ModalCreateCategory from "../../components/Modals/ModalCreateCategory.vue"
import ModalCreateNcm from "../../components/Modals/ModalCreateNcm.vue"
import ProductsComposition from "../../components/ProductsComposition/ProductsComposition.vue"
import InputSelect from "../../components/UI/CustomInputs/InputSelect.vue"
import InputQuant from "../../components/UI/CustomInputs/InputQuant.vue"

import toastAlertErros from "../../utils/toastAlertErros";
import ServiceProducts from "../../services/serviceProducts";
import ServiceCategory from "../../services/serviceCategory";
import ServiceTax from "../../services/serviceTax";
import { mascaraMoeda } from "../../utils/maskMoney";
import { removeSpecialCharacters } from "../../utils/removeSpecialCharacters"
import { HandleErrors } from "../../utils/handleErrors"
import api from "../../services/axios";
import serviceProductLogs from "../../services/serviceProductLogs";
import { unidade } from "../../common/index";
import serviceNcms from "../../services/serviceNcms";
const mixinProducts = {
  props: {
    readOrEditProducts: {
      type: Object,
    },
    idProductFromSearch: {
      type: String
    }
  },
  components: {
    ModalTaxInformation,
    ModalCreateCategory,
    ModalCreateNcm,
    ProductsComposition,
    InputSelect,
    InputQuant
  },
  data() {
    return {
      dataProducts: {
        id: "",
        idCategoria: "",
        nome: "",
        custoInicial: 0.0,
        frete: 0.0,
        encargos: 0.0,
        lucro: 0.0,
        valor: 0.0,
        valorVenda: 0.0,
        valorVenda2: 0.0,
        valorVenda3: 0.0,
        margem1: 0,
        margem2: 0,
        margem3: 0,
        unidade: "",
        descricao: "",
        codBarras: "",
        codReferencia: "",
        estoque: 0,
        estoqueMin: 0,
        refFiscal: "",
        cest: "",
        nomeCest: "",
        ncm: "",
        nomeNcm: "",
        status: "Ativo",
        composicao: [],
        localizacao: "",
      },
      dataProviderProducts: {
        idFornecedor: "",
        idProduto: "",
        estoque: "",
        valorUnitario: "",
        valorTotal: "",
        descricao: "",
      },
      dataLogsProducts: {
        idProduto: "",
        descricao: "",
        dados: {
          from: "",
          to: "",
        },
      },
      refsFiscaisFromSelectBox: [],
      supplierForSelectBox: [],
      refsFiscais: [],
      createdNewRef: false,
      ncms: [],
      cests: [],
      categories: [],
      stockAdjustmentIsEnable: false,
      stockAmount: 0,
      getProducts: false,
      status: [
        { value: "Ativo", text: "Ativo" },
        { value: "Inativo", text: "Inativo" },
      ],
      compositionIsEnaled: false,
      clearComposition: false,
      unidade: unidade,
      percentFrete: 0,
      percentEncargos: 0,
    };
  },
  methods: {
    ...mapMutations([
      "SET_IS_SEARCH_REGISTERS",
      "ALTER_SIZE_MODAL_REGISTER",
      "ALTER_TYPE_REGISTER",
      "SET_COLAPSE_REGISTER",
      "ALTER_MODAL_CONTAINER_REGISTER",
      "SET_DATA_SEARCH_FROM_PRODUCT",
    ]),
    saveOrUpdateProducts() {
      this.handleComposition()

      this.dataProducts.id === "" || this.dataProducts.id === null
        ? this.saveProducts()
        : this.updateProducts();
    },

    async updateProducts() {
      try {
        await ServiceProducts.updateProducts(this.dataProducts);
        if (this.dataProviderProducts.idFornecedor) {
          await this.assingProviderProduct(this.dataProducts);
        }
        this.$emit("getAllProducts", this.getProducts);
        this.getProducts = !this.getProducts;
        this.SET_IS_SEARCH_REGISTERS();
        this.clear();
        return this.$toast.open({
          message: "Produto Atualizado com Sucesso",
          type: "success",
        });

      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });

        const typeError = HandleErrors(message);
        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }
        else {
          toastAlertErros.validateErroDoesNotContainFor(
            error,
            this.$toast
          );
        }
      }
    },

    async saveProducts() {
      try {
        const id = await ServiceProducts.saveProducts(this.dataProducts);
        this.dataProducts.id = id;
        if (this.dataProviderProducts.idFornecedor) {
          await this.assingProviderProduct(this.dataProducts);
        }

        this.findCestGrupBy();
        this.ncmCombobox();

        this.$emit("getAllProducts", this.getProducts);
        this.getProducts = !this.getProducts;
        this.SET_IS_SEARCH_REGISTERS();
        this.clear();
        return this.$toast.open({
          message: "Produto Salvo com Sucesso",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });

        const typeError = HandleErrors(message);
        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }
        else if (!isNaN(error.response.data.erros)) {
          toastAlertErros.validateErroDoesNotContainFor(
            error,
            this.$toast
          );
        } else {
          this.$toast.open({
            message: error?.response?.data?.message || "Ocorreu um problema ao registrar o produto!",
            type: "info",
          });
        }
      }
    },

    async handleStockAdjustment() {
      this.$bvModal.hide('modal-ajustStock')

      if (!this.dataProducts.id) {
        this.dataProducts.estoque = this.stockAmount
        this.closeStockAdjustment();
      } else {
        this.stockAdjustment()
        this.closeStockAdjustment()
      }
    },

    handleComposition() {
      this.dataProducts.composicao = this.dataProducts.composicao.filter(item => item.idProduto)
    },

    stockAdjustment() {
      this.dataLogsProducts.dados.from = this.dataProducts.estoque
      this.dataLogsProducts.dados.to = this.stockAmount
      this.dataProducts.estoque = this.stockAmount
      this.saveOrUpdateProducts()
      this.assignLogs()
    },

    async assignLogs() {
      this.dataLogsProducts.idProduto = this.dataProducts.id
      this.dataLogsProducts.descricao = "movManual"
      await serviceProductLogs.save(this.dataLogsProducts);
    },

    enableStockAdjustment() {
      this.stockAdjustmentIsEnable = true
    },

    closeStockAdjustment() {
      this.stockAdjustmentIsEnable = false
      this.stockAmount = 0
    },

    async findAllRefsFromSelectBox() {
      try {
        const result = await ServiceTax.findAllRefs();
        this.refsFiscaisFromSelectBox = result.data.map((e) => {
          return {
            id: e.id,
            descricao: ` ${e.ref} - ${e.descricao}`,
            ref: e.ref,
            refObject: e.refObject,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },

    async findCestGrupBy() {
      const result = await ServiceProducts.findCestGrupBy()
      this.cests = result
    },

    async findAllCategories() {
      try {
        this.categories = await ServiceCategory.fillComobobox()
      } catch (error) {
        this.$toast.open({
          message: error?.response?.data?.message || "Ocorreu um problema ao buscar as categorias do produto",
          type: "info"
        })
      }
    },

    async ncmCombobox() {
      try {
        this.ncms = await serviceNcms.fillComobobox()

      } catch (error) {
        this.$toast.open({
          message: error?.response?.data?.message || "Ocorreu um problema ao buscar os ncms do produto",
          type: "info"
        })
      }
    },

    async findById(id) {
      const product = await ServiceProducts.getProductById(id)
      return product
    },

    async handleModalCreateProduct() {
      const product = await this.findById(this.idProductFromSearch)
      this.SET_DATA_SEARCH_FROM_PRODUCT(product)
      this.assingEditProduct();
    },

    clear() {
      this.dataProducts = {
        id: "",
        nome: "",
        valor: 0.0,
        valorVenda: 0.0,
        valorVenda2: 0.0,
        valorVenda3: 0.0,
        custoInicial: 0.0,
        frete: 0.0,
        encargos: 0.0,
        lucro: 0.0,
        margem1: 0,
        margem2: 0,
        margem3: 0,
        unidade: "",
        descricao: "",
        codBarras: "",
        localizacao: "",
        codReferencia: "",
        estoque: 0,
        estoqueMin: 0,
        ncm: "",
        refFiscal: "",
        status: "Ativo",
        composicao: [],
        idCategoria: "",
      };
      this.percentEncargos = 0
      this.percentFrete = 0
      this.$refs.refCategories.valueItems = ""
      this.$refs.refNcm.valueItems = ""
      this.clearComposition = !this.clearComposition
      this.SET_DATA_SEARCH_FROM_PRODUCT({})
    },

    handleNcmOrCest(value, isNcm = false) {
      const formattedValue = removeSpecialCharacters(value)

      if (isNcm) this.dataProducts.ncm = formattedValue
      else this.dataProducts.cest = formattedValue

      return formattedValue
    },

    openModals(name) {
      this.$bvModal.show(name);
    },

    maskCurrency(valor, locale = "pt-BR") {
      if (!valor) {
        valor = 0;
      }
      return parseFloat(valor).toLocaleString(locale, {
        style: "currency",
        currency: "BRL",
      });
    },

    maskPercentage(valor, locale = "pt-BR") {
      if (!valor) {
        valor = 0;
      }

      return `% ${parseFloat(valor)
        .toLocaleString(locale, {
          minimumFractionDigits: 2,
        })
        .replace(",", ".")}`;
    },

    formatMoney(event, value) {
      this.dataProducts[value] = mascaraMoeda(event);
    },

    formatPercente(event, value) {
      this[value] = mascaraMoeda(event);
    },

    formatPercenteProd(event, value) {
      this.dataProducts[value] = mascaraMoeda(event);
    },

    assingEditProduct() {
      this.dataProducts.id = this.dataSearchProduct.id
      this.dataProducts.idCategoria = this.dataSearchProduct.idCategoria
      this.dataProducts.nome = this.dataSearchProduct.nome
      this.dataProducts.valor = this.dataSearchProduct.valor || 0
      this.dataProducts.valorVenda = this.dataSearchProduct.valorVenda || 0
      this.dataProducts.valorVenda2 = this.dataSearchProduct.valorVenda2 || 0
      this.dataProducts.valorVenda3 = this.dataSearchProduct.valorVenda3 || 0
      this.dataProducts.custoInicial = this.dataSearchProduct.custoInicial || 0
      this.dataProducts.frete = this.dataSearchProduct.frete || 0
      this.dataProducts.encargos = this.dataSearchProduct.encargos || 0
      this.dataProducts.lucro = this.dataSearchProduct.lucro || 0
      this.dataProducts.unidade = this.dataSearchProduct.unidade
      this.dataProducts.descricao = this.dataSearchProduct.descricao
      this.dataProducts.codBarras = this.dataSearchProduct.codBarras
      this.dataProducts.localizacao = this.dataSearchProduct.localizacao
      this.dataProducts.codReferencia = this.dataSearchProduct.codReferencia
      this.dataProducts.estoque = this.dataSearchProduct.estoque
      this.dataProducts.estoqueMin = this.dataSearchProduct.estoqueMin
      this.dataProducts.refFiscal = this.dataSearchProduct.refFiscal
      this.dataProducts.nomeNcm = this.dataSearchProduct.nomeNcm
      this.dataProducts.ncm = this.dataSearchProduct.ncm
      this.$refs.refNcm.valueItems = this.dataSearchProduct.ncm
      this.dataProducts.nomeCest = this.dataSearchProduct.nomeCest
      this.dataProducts.cest = this.dataSearchProduct.cest
      this.dataProducts.status = this.dataSearchProduct.status

      this.$refs.refCategories.valueItems = this.dataSearchProduct.idCategoria

      if (this.dataSearchProduct.composicao && this.dataSearchProduct.composicao.length) {
        this.dataProducts.composicao = this.dataSearchProduct.composicao.map(composition => {
          return {
            ...composition,
            idList: uuidv4()
          }
        })

        this.compositionIsEnaled = true
      }
    },

    async assingProviderProduct(product) {
      this.dataProviderProducts.idProduto = this.dataProducts.id
      this.dataProviderProducts.valorUnitario = product.valorVenda
      this.dataProviderProducts.valorTotal = product.valorVenda
      this.dataProviderProducts.descricao = product.descricao
      this.dataProviderProducts.estoque = product.estoque
      await api.post('/products-by-provider', this.dataProviderProducts)
    },

    toggleEnableComposition() {
      this.compositionIsEnaled = !this.compositionIsEnaled
    },
  },
  watch: {
    readOrEditProducts() {
      this.dataProducts = this.readOrEditProducts;
    },
    createdNewRef() {
      this.findAllRefsFromSelectBox();
    },
    valorFrete(newValue) {
      this.dataProducts.frete = newValue;
    },
    valorEncargos(newValue) {
      this.dataProducts.encargos = newValue;
    },
    valorCustoFinal(newValue) {
      this.dataProducts.valor = newValue;
    },
    valorVenda1(newValue) {
      this.dataProducts.valorVenda = newValue;
    },
    valorMargemLucro(newValue) {
      this.dataProducts.lucro = newValue;
    },
    valorVenda2(newValue) {
      this.dataProducts.valorVenda2 = newValue;
    },
    valorVenda3(newValue) {
      this.dataProducts.valorVenda3 = newValue;
    }
  },
  async mounted() {
    if (this.dataSearchProduct.id) {
      this.assingEditProduct();
    } else if (this.idProductFromSearch) {
      this.handleModalCreateProduct()
    }

    await this.findAllRefsFromSelectBox();
    await this.findCestGrupBy()
    this.findAllCategories()
    await this.ncmCombobox()
  },

  computed: {
    ...mapState({
      dataSearchProduct: (state) => state.dataSearchProduct
    }),
    valorFrete: {
      get() {
        if (this.percentFrete) {
          return (parseFloat(this.dataProducts.custoInicial) * parseFloat(this.percentFrete)) / 100;
        }
        return this.dataProducts.frete;
      },
      set(value) {
        this.dataProducts.frete = value;
      }
    },
    valorEncargos: {
      get() {
        if (this.percentEncargos) {
          return (parseFloat(this.dataProducts.custoInicial) * parseFloat(this.percentEncargos)) / 100;
        }
        return this.dataProducts.encargos;
      },
      set(value) {
        this.dataProducts.encargos = value;
      }
    },
    valorCustoFinal: {
      get() {
        let custoInicial = parseFloat(this.dataProducts.custoInicial) || 0;
        let frete = parseFloat(this.dataProducts.frete) || 0;
        let encargos = parseFloat(this.dataProducts.encargos) || 0;

        return custoInicial + frete + encargos;
      },
      set(value) {
        this.dataProducts.valor = value;
      }
    },
    valorVenda2: {
      get() {
        let valorCustoFinal = parseFloat(this.dataProducts.valorVenda) || 0;
        if (this.dataProducts.margem2) {
          this.dataProducts.lucro2 = (valorCustoFinal * parseFloat(this.dataProducts.margem2)) / 100;
        }
        console.log(this.dataProducts.valorVenda2 ,"valorVenda2");

        return valorCustoFinal + (parseFloat(this.dataProducts.lucro2) || 0);
      },
      set(value) {
        console.log(value, "2");

        this.dataProducts.valorVenda2 = value;
      }
    },
    valorVenda3: {
      get() {
        let valorCustoFinal = parseFloat(this.dataProducts.valorVenda) || 0;
        if (this.dataProducts.margem3) {
          this.dataProducts.lucro3 = (valorCustoFinal * parseFloat(this.dataProducts.margem3)) / 100;
        }

        return valorCustoFinal + (parseFloat(this.dataProducts.lucro3) || 0);
      },
      set(value) {
        console.log(value, "3");
        this.dataProducts.valorVenda3 = value;
      }
    },
    valorMargemLucro: {
      get() {
        let valorCustoFinal = parseFloat(this.dataProducts.valor) || 0;

        return this.dataProducts.margem1
          ? (valorCustoFinal * parseFloat(this.dataProducts.margem1)) / 100
          : this.dataProducts.lucro;
      },
      set(value) {
        this.dataProducts.lucro = value;
      }
    }
  },

  beforeDestroy() {
    this.clear();
  }
}

export default mixinProducts