<template>
  <div style="max-height: 100px">
    <b-modal size="xl" id="modalNatures" centered hide-header>
      <template #modal-footer>
        <div style="width: 100%; display: flex; justify-content: space-between">
          <button
            class="btnsModal"
            variant="info"
            size="sm"
            @click="handleCloseModalAndClear"
          >
            Fechar
          </button>
          <button
            class="btnsModal btnSave"
            variant="info"
            size="sm"
            @click="saveOrUpdate"
          >
            Salvar
          </button>
        </div>
      </template>
      <div class="mt-3">
        <div class="rowContainer">
          <span>
            <b-icon-card-checklist></b-icon-card-checklist> Nova Natureza de Operação
          </span>
          <div class="rowContent">
            <Input
              label="Código Natureza de Operação"
              style="width: 20% !important"
              type="text"
              :dataInput.sync="dataNature.codigoOp"
              ref="codigoOp"
            />
            <Input
              label="Descrição da Natureza de Operação"
              style="width: 80% !important"
              type="text"
              :dataInput.sync="dataNature.descricao"
              ref="descricao"
            />
          </div>
        </div>

        <span class="titleNcm">Natureza de Operação Cadastradas</span>
        <div class="ContentTable">
          <table>
            <thead class="headTable">
              <tr style="width: 100%">
                <th style="font-size: 14px; font-weight: 600">Código</th>
                <th style="width: 80%; font-size: 14px; font-weight: 600">
                  Descrição
                </th>
                <th
                  style="
                    text-align: center;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 0 10px;
                  "
                >
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="nature in allNatures" :key="nature.id">
                <td style="text-align: center; font-size: 13px">
                  {{ nature.codigoOp || "--" }}
                </td>
                <td style="width: 80%; text-align: center; font-size: 13px">
                  {{ nature.descricao }}
                </td>
                <td
                  style="
                    text-align: center;
                    padding: 0 !important;
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    gap: 20px;
                    padding: 0.5rem;
                  "
                >
                  <b-icon-pencil
                    @click="findById(nature.id)"
                    scale="1"
                    role="button"
                  />

                  <b-icon-x-circle
                    @click="deleteNature(nature.id)"
                    role="button"
                    style="color: red"
                    scale="1"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import serviceNatureOps from "../../services/serviceNatureOps";
import Input from "../UI/CustomInputs/Input.vue";

export default {
  data() {
    return {
      dataNature: {
        id: "",
        descricao: "",
        codigoOp: "",
      },
      allNatures: [],
    };
  },
  components: {
    Input,
  },
  methods: {
    async saveNature() {
      try {
        await serviceNatureOps.save(this.dataNature);
        this.getAllNatures();

        this.$emit("getNewNature");

        return this.$toast.open({
          message: "Salvo com sucesso!",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message:
            `${error.response.data.erros}` || "Ocorreu um erro o salvar!",
          type: "info",
        });
      }
    },

    async updateNature() {
      try {
        await serviceNatureOps.update(this.dataNature);
        this.getAllNatures();

        this.$emit("getNewNature");

        return this.$toast.open({
          message: "Atualizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um problema ao deletar!",
          type: "info",
        });
      }
    },

    async deleteNature(idNature) {
      try {
        await serviceNatureOps.delete(idNature);
        this.getAllNatures();

        this.$emit("getNewNature");

        return this.$toast.open({
          message: "deletado com sucesso!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um problema ao deletar!",
          type: "info",
        });
      }
    },

    async findById(idNature) {
      const result = await serviceNatureOps.findById(idNature);
      Object.assign(this.dataNature, result);
      this.$refs.codigoOp.valueInput = result.codigoOp;
      this.$refs.descricao.valueInput = result.descricao;
    },

    async getAllNatures() {
      const result = await serviceNatureOps.fillComobobox();
      this.allNatures = result;
    },

    async saveOrUpdate() {
      this.dataNature.id === ""
        ? await this.saveNature()
        : await this.updateNature();
      this.clear();
    },

    clear() {
      this.dataNature.id = "";
      this.dataNature.descricao = "";
      this.dataNature.codigoOp = "";
      this.$refs.codigoOp.valueInput = "";
      this.$refs.descricao.valueInput = "";
    },

    handleCloseModalAndClear() {
      this.clear();
      this.$bvModal.hide("modalNatures");
    },
  },
  mounted() {
    this.getAllNatures();
  },
};
</script>

<style scoped>
.styleHoverDelete:hover {
  background-color: #ff6347;
  border: solid 1px black;
}

.tabsContentSearch {
  overflow-y: scroll;
  max-height: 300px;
}

.btnsModal {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  height: 28px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.rowContainer {
  position: relative;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
}

.rowContainer span {
  font-weight: 600;
  font-size: 14px;
}

.ContentTable {
  display: flex;
  flex-direction: column;
  height: 50vh;
  max-height: 50vh;
  overflow: auto;
  width: 100%;
  max-width: 100%;
}

.titleNcm {
  margin-top: 30px;
  width: 100%;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #3a4750;
  justify-content: flex-start;
}

tr:nth-child(even) {
  background: #f2f3f3;
}

.headTable {
  color: whitesmoke;
  background: #3a4750;
  text-align: center;
  height: 30px;
}

.btnSave {
  background-color: transparent;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.titleCard {
  margin-bottom: 32px !important;
  font-weight: 600;
  color: #666360;
  font-size: 16px;
}
</style>