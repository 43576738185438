const pagamento = [
  { value: 0, text: "Pagamento à vista" },
  { value: 1, text: "Pagamento à prazo" },
]

const formaPagamento = [
  { value: "01", text: "Dinheiro" },
  { value: "02", text: "Cheque" },
  { value: "03", text: "Cartão de Crédito" },
  { value: "04", text: "Cartão de Débito" },
  { value: "05", text: "Crédito Loja" },
  { value: "10", text: "Vale Alimentação" },
  { value: "11", text: "Vale Refeição" },
  { value: "12", text: "Vale Presente" },
  { value: "13", text: "Vale Combustível" },
  { value: "14", text: "Duplicata Mercantil" },
  { value: "15", text: "Boleto Bancário" },
  { value: "16", text: "Depósito Bancário" },
  { value: "17", text: "Pagamento Instantâneo (PIX)" },
  { value: "18", text: "Transferência bancária, Carteira Digital" },
  { value: "19", text: "Programa de fidelidade, Cashback, Crédito Virtual" },
  { value: "90", text: "Sem pagamento" },
]

const tipoArquivo = [
  { value: "Contrato" },
  { value: "Documento Fiscal" },
  { value: "Documento de Cobrança" },
  { value: "Outros" },
]

const cfop = [
  {
    text: "1101 - Compra para industrialização - Dentro do Estado.",
    value: "1101",
  },
  {
    text: "1102 - Compra para comercialização - Dentro do Estado.",
    value: "1102",
  },
  {
    text: "2101 - Compra para industrialização - Outro Estado.",
    value: "2101",
  },
  {
    text: "2102 - Compra para comercialização - Outro Estado.",
    value: "2102",
  },
  {
    text: "2403 - Compra para comercialização em operação com mercadoria sujeita ao regime de substituição tributária - Outro Estado.",
    value: "2403",
  },
  {
    text: "2551 - Classificam-se neste código as compras de produtos destinadas ao uso ou consumo do estabelecimento - Outro Estado.",
    value: "2551",
  },
  {
    text: "2556 - Compra de bem para o ativo imobilizado - Outro Estado.",
    value: "2556",
  },
]

const defaultUserPermission = {
  vendas: {
    painelPrincipal: false,
    novaVenda: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novoOrcamento: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novoFrenteDeCaixa: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    }
  },
  ordensDeServico: {
    painelPrincipal: false,
    novaOrdem: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    }
  },
  cadastros: {
    painelPrincipal: false,
    novoCliente: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novoFornecedor: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novoProduto: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novoFuncionario: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novoServico: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
  },
  compras: {
    painelPrincipal: false,
    novaCompra: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    notasRecebidas: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    }
  },
  financeiro: {
    painelPrincipal: false,
    novaContaReceber: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novaContaPagar: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    }
  },
  emissaoFiscal: {
    painelPrincipal: false,
    novaNfe: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novaNfce: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novaNfse: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
  },
  caixa: {
    painelPrincipal: false,
    abrirCaixa: false,
    fecharCaixa: false,
    sangria: false,
    suprimentosConta: false,
    suprimentosGaveta: false,
    transferencia: false,
    ajusteDeCaixa: false,
    ajusteDeConta: false,
    caixaAutomatico: false,
  },
  agenda: {
    painelPrincipal: false,
  },
  relatorios: {
    painelPrincipal: false,
    imprimir: false,
  },
  configuracoes: {
    painelPrincipal: false,
    empresa: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
    },
    registroPonto: {
      painelPrincipal: false,
    }
  }
}

const defaultAdmPermission = {
  vendas: {
    painelPrincipal: true,
    novaVenda: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novoOrcamento: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novoFrenteDeCaixa: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    }
  },
  ordensDeServico: {
    painelPrincipal: true,
    novaOrdem: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    }
  },
  cadastros: {
    painelPrincipal: true,
    novoCliente: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novoFornecedor: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novoProduto: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novoFuncionario: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novoServico: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
  },
  compras: {
    painelPrincipal: true,
    novaCompra: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    notasRecebidas: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    }
  },
  financeiro: {
    painelPrincipal: true,
    novaContaReceber: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novaContaPagar: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    }
  },
  emissaoFiscal: {
    painelPrincipal: true,
    novaNfe: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novaNfce: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novaNfse: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
  },
  caixa: {
    painelPrincipal: true,
    abrirCaixa: true,
    fecharCaixa: true,
    sangria: true,
    suprimentosConta: true,
    suprimentosGaveta: true,
    transferencia: true,
    ajusteDeCaixa: true,
    ajusteDeConta: true,
    caixaAutomatico: true,
  },
  agenda: {
    painelPrincipal: true,
  },
  relatorios: {
    painelPrincipal: true,
    imprimir: true,
  },
  configuracoes: {
    painelPrincipal: true,
    empresa: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
    },
    registroPonto: {
      painelPrincipal: false,
    }
  }
}

const defaultWorkingSchedule = {
  domingo: {
    ativo: false,
    inicio: null,
    intervalo: null,
    fim: null,
    carga_horaria: null,
  },
  segunda: {
    ativo: false,
    inicio: null,
    intervalo: null,
    fim: null,
    carga_horaria: null,
  },
  terca: {
    ativo: false,
    inicio: null,
    intervalo: null,
    fim: null,
    carga_horaria: null,
  },
  quarta: {
    ativo: false,
    inicio: null,
    intervalo: null,
    fim: null,
    carga_horaria: null,
  },
  quinta: {
    ativo: false,
    inicio: null,
    intervalo: null,
    fim: null,
    carga_horaria: null,
  },
  sexta: {
    ativo: false,
    inicio: null,
    intervalo: null,
    fim: null,
    carga_horaria: null,
  },
  sabado: {
    ativo: false,
    inicio: null,
    intervalo: null,
    fim: null,
    carga_horaria: null,
  },
}

const ufs = [
  { text: 'AC', value: 'AC' },
  { text: 'AL', value: 'AL' },
  { text: 'AP', value: 'AP' },
  { text: 'AM', value: 'AM' },
  { text: 'BA', value: 'BA' },
  { text: 'CE', value: 'CE' },
  { text: 'DF', value: 'DF' },
  { text: 'ES', value: 'ES' },
  { text: 'GO', value: 'GO' },
  { text: 'MA', value: 'MA' },
  { text: 'MT', value: 'MT' },
  { text: 'MS', value: 'MS' },
  { text: 'MG', value: 'MG' },
  { text: 'PA', value: 'PA' },
  { text: 'PB', value: 'PB' },
  { text: 'PR', value: 'PR' },
  { text: 'PE', value: 'PE' },
  { text: 'PI', value: 'PI' },
  { text: 'RJ', value: 'RJ' },
  { text: 'RN', value: 'RN' },
  { text: 'RS', value: 'RS' },
  { text: 'RO', value: 'RO' },
  { text: 'RR', value: 'RR' },
  { text: 'SC', value: 'SC' },
  { text: 'SP', value: 'SP' },
  { text: 'SE', value: 'SE' },
  { text: 'TO', value: 'TO' }
];

const nfseSituationsNfse = [
  { text: 'Pendente', value: "Pendente" },
  { text: 'Em andamento', value: "Em andamento" },
  { text: 'Finalizado', value: "Finalizado" },
  { text: 'Faturado', value: "Faturado" },
  { text: 'Cancelado', value: "Cancelado" },
]

const typesInstallment = [
  { text: "Venda à vista", value: 1 },
  { text: "2x", value: 2 },
  { text: "3x", value: 3 },
  { text: "4x", value: 4 },
  { text: "5x", value: 5 },
  { text: "6x", value: 6 },
  { text: "7x", value: 7 },
  { text: "8x", value: 8 },
  { text: "9x", value: 9 },
  { text: "10x", value: 10 },
  { text: "11x", value: 11 },
  { text: "12x", value: 12 },
]

const departaments = [
  {
    text: "Recursos humanos",
    value: "recursos_humanos",
  },
  { text: "Fiscal", value: "fiscal" },
  {
    text: "Contabilidade e Imposto de Renda",
    value: "contabilidade_imposto_renda",
  },
  { text: "Auditoria", value: "auditoria" },
  {
    text: "Previdenciário e legislação",
    value: "previdenciario_legislacao",
  },
  {
    text: "Adminstrativo",
    value: "adminstrativo",
  },
  { text: "Outros", value: "outros" },
]

const resumeTypes = [
  { text: "Diários", value: "diario" },
  { text: "Semanal", value: "semanal" },
  { text: "Mensal", value: "mensal" },
]

const typesKeyPix = [
  { text: "CPF", value: 1 },
  { text: "CNPJ", value: 2 },
  { text: "E-mail", value: 3 },
  { text: "Telefone Celular", value: 4 },
  { text: "Chave aleatória", value: 5 },
]

const decimalPlacesOptions = [
  { text: "2 casas após a vírgula", value: 2 },
  { text: "3 casas após a vírgula", value: 3 },
  { text: "4 casas após a vírgula", value: 4 },
  { text: "5 casas após a vírgula", value: 5 },
]

const unidade = [
  { text: 'Ampola', value: 'AMP' },
  { text: 'Barril', value: 'BR' },
  { text: 'Caixa', value: 'CX' },
  { text: 'Centímetro (cm)', value: 'CM' },
  { text: 'Conjunto', value: 'CONJ' },
  { text: 'Dúzia', value: 'DZ' },
  { text: 'Envolope', value: 'ENV' },
  { text: 'Fardo', value: 'Fardo' },
  { text: 'Frasco', value: 'Frasco' },
  { text: 'Galão', value: 'GL' },
  { text: 'Grama (g)', value: 'GR' },
  { text: 'Jogo', value: 'JG' },
  { text: 'Kit', value: 'Kit' },
  { text: 'Litro (l)', value: 'L' },
  { text: 'Lote', value: 'Lote' },
  { text: 'Metro (m)', value: 'MT' },
  { text: 'Metro Quadrado (mt²)', value: 'MT2' },
  { text: 'Mililitro (ml)', value: 'ML' },
  { text: 'Milímetro (mm)', value: 'MM' },
  { text: 'Pacote', value: 'PCT' },
  { text: 'Palete', value: 'PBR' },
  { text: 'Par', value: 'PAR' },
  { text: 'Peça', value: 'PC' },
  { text: 'Quilograma (kg)', value: 'KG' },
  { text: 'Rolo', value: 'Rolo' },
  { text: 'Saco', value: 'Saco' },
  { text: 'Tubo', value: 'Tubo' },
  { text: 'Tonelada', value: 'TON' },
  { text: 'Unidade', value: 'UND' }
];

const genero = [
  { value: '00', text: 'Serviço' },
  { value: '01', text: 'Animais vivos' },
  { value: '02', text: 'Carnes e miudezas' },
  { value: '03', text: 'Peixes e crustáceos' },
  { value: '04', text: 'Leite e laticínios; ovos' },
  { value: '05', text: 'Outros produtos de origem animal' },
  { value: '06', text: 'Plantas vivas e floricultura' },
  { value: '07', text: 'Produtos hortícolas comestíveis' },
  { value: '08', text: 'Frutas e cascas de melões' },
  { value: '09', text: 'Café, chá, mate e especiarias' },
  { value: '10', text: 'Cereais' },
  { value: '11', text: 'Produtos de moagem; amidos' },
  { value: '12', text: 'Sementes e frutos oleaginosos' },
  { value: '13', text: 'Gomas e resinas vegetais' },
  { value: '14', text: 'Matérias para entrançar e produtos vegetais' },
  { value: '15', text: 'Gorduras e óleos; ceras' },
  { value: '16', text: 'Preparações de carne, peixes e crustáceos' },
  { value: '17', text: 'Açúcares e confeitaria' },
  { value: '18', text: 'Cacau e preparações' },
  { value: '19', text: 'Preparações à base de cereais e leite' },
  { value: '20', text: 'Preparações de hortícolas e frutas' },
  { value: '21', text: 'Preparações alimentícias diversas' },
  { value: '22', text: 'Bebidas e vinagres' },
  { value: '23', text: 'Resíduos e alimentos para animais' },
  { value: '24', text: 'Fumo e sucedâneos' },
  { value: '25', text: 'Sal, enxofre, terras e pedras' },
  { value: '26', text: 'Minérios e escórias' },
  { value: '27', text: 'Combustíveis minerais e ceras minerais' },
  { value: '28', text: 'Produtos químicos inorgânicos' },
  { value: '29', text: 'Produtos químicos orgânicos' },
  { value: '30', text: 'Produtos farmacêuticos' },
  { value: '31', text: 'Adubos e fertilizantes' },
  { value: '32', text: 'Extratos e pigmentos' },
  { value: '33', text: 'Óleos essenciais e produtos de perfumaria' },
  { value: '34', text: 'Sabões e produtos de limpeza' },
  { value: '35', text: 'Matérias albuminóides e colas' },
  { value: '36', text: 'Pólvoras e explosivos' },
  { value: '37', text: 'Produtos para fotografia e cinematografia' },
  { value: '38', text: 'Produtos das indústrias químicas' },
  { value: '39', text: 'Plásticos e suas obras' },
  { value: '40', text: 'Borracha e suas obras' },
  { value: '41', text: 'Peles e couros' },
  { value: '42', text: 'Obras de couro e artigos de viagem' },
  { value: '43', text: 'Peleteria e suas obras' },
  { value: '44', text: 'Madeira e carvão vegetal' },
  { value: '45', text: 'Cortiça e suas obras' },
  { value: '46', text: 'Espartaria e cestaria' },
  { value: '47', text: 'Pastas de madeira e papel reciclado' },
  { value: '48', text: 'Papel e cartão' },
  { value: '49', text: 'Livros, jornais e produtos gráficos' },
  { value: '50', text: 'Seda' },
  { value: '51', text: 'Lã e fios de crina' },
  { value: '52', text: 'Algodão' },
  { value: '53', text: 'Outras fibras têxteis' },
  { value: '54', text: 'Filamentos sintéticos' },
  { value: '55', text: 'Fibras sintéticas descontínuas' },
  { value: '56', text: 'Pastas, feltros e cordéis' },
  { value: '57', text: 'Tapetes e revestimentos têxteis' },
  { value: '58', text: 'Tecidos especiais e bordados' },
  { value: '59', text: 'Tecidos impregnados e técnicos' },
  { value: '60', text: 'Tecidos de malha' },
  { value: '61', text: 'Vestuário de malha' },
  { value: '62', text: 'Vestuário exceto de malha' },
  { value: '63', text: 'Outros artefatos têxteis e calçados' },
  { value: '64', text: 'Calçados e suas partes' },
  { value: '65', text: 'Chapéus e suas partes' },
  { value: '66', text: 'Guarda-chuvas e bengalas' },
  { value: '67', text: 'Penas, flores artificiais e obras de cabelo' },
  { value: '68', text: 'Obras de pedra e cimento' },
  { value: '69', text: 'Produtos cerâmicos' },
  { value: '70', text: 'Vidro e suas obras' },
  { value: '71', text: 'Pérolas, pedras preciosas e bijuterias' },
  { value: '72', text: 'Ferro fundido e aço' },
  { value: '73', text: 'Obras de ferro fundido e aço' },
  { value: '74', text: 'Cobre e suas obras' },
  { value: '75', text: 'Níquel e suas obras' },
  { value: '76', text: 'Alumínio e suas obras' },
  { value: '77', text: '(Reservado para futura utilização)' },
  { value: '78', text: 'Chumbo e suas obras' },
  { value: '79', text: 'Zinco e suas obras' },
  { value: '80', text: 'Estanho e suas obras' },
  { value: '81', text: 'Outros metais comuns' },
  { value: '82', text: 'Ferramentas e cutelaria' },
  { value: '83', text: 'Obras de metais comuns' },
  { value: '84', text: 'Máquinas e aparelhos mecânicos' },
  { value: '85', text: 'Máquinas e aparelhos elétricos' },
  { value: '86', text: 'Veículos para vias férreas' },
  { value: '87', text: 'Veículos automóveis e partes' },
  { value: '88', text: 'Aeronaves e partes' },
  { value: '89', text: 'Embarcações e estruturas flutuantes' },
  { value: '90', text: 'Instrumentos de óptica e precisão' },
  { value: '91', text: 'Aparelhos de relojoaria' },
  { value: '92', text: 'Instrumentos musicais' },
  { value: '93', text: 'Armas e munições' },
  { value: '94', text: 'Móveis e iluminação' },
  { value: '95', text: 'Brinquedos e artigos de esporte' },
  { value: '96', text: 'Obras diversas' },
  { value: '97', text: 'Objetos de arte e antiguidades' },
  { value: '98', text: '(Reservado para usos especiais)' },
  { value: '99', text: 'Operações especiais (Brasil)' }
];

export { pagamento, formaPagamento, tipoArquivo, defaultUserPermission, defaultAdmPermission, defaultWorkingSchedule, cfop, ufs, nfseSituationsNfse, typesInstallment, departaments, resumeTypes, typesKeyPix, decimalPlacesOptions, unidade, genero }