<template>
  <b-card>
    <div style="font-size: 12px">
      <div class="title">
        <span>Cadastro de Fornecedor</span>
        <span class="requiredFields">* Campos Obrigatórios</span>
      </div>
      <div class="rowContainer">
        <span>
          <b-icon-person-lines-fill></b-icon-person-lines-fill> Informações
          Gerais</span
        >
        <div class="rowContent">
          <b-form-input
            hidden
            class="col-sm-1"
            v-model="dadosSupplier.idSupplier"
          ></b-form-input>

          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosSupplier.nomeFantasia"
            />
            <label class="user-label">
              Nome Fantasia <span class="validRed">*</span></label
            >
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosSupplier.razaoSocial"
            />
            <label class="user-label">
              Razão Social <span class="validRed">*</span></label
            >
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosSupplier.cpfCnpj"
              v-mask="maskCpfCnpj"
            />
            <label class="user-label">
              CNPJ/CPF <span class="validRed">*</span></label
            >
            <button class="bcsCep" @click="fetchCnpj(dadosSupplier.cpfCnpj)">
              <b-spinner
                v-if="spinGeneralCnpj"
                small
                label="Small Spinner"
              ></b-spinner>
              buscar
            </button>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosSupplier.ie"
            />
            <label class="user-label"> Inscrição Estadual</label>
          </div>
        </div>
      </div>

      <div class="rowContainer">
        <span> <b-icon-geo-alt-fill></b-icon-geo-alt-fill> Localização</span>
        <div class="rowContent">
          <div class="input-group">
            <input
              required
              class="input borderInput"
              maxlength="9"
              :value="maskCep(dadosSupplier.cep)"
              @input="handleValueCep"
            />
            <label class="user-label"> CEP </label>
            <button class="bcsCep" @click="fetchCep(dadosSupplier.cep)">
              <b-spinner
                v-if="spinGeneral"
                small
                label="Small Spinner"
              ></b-spinner>
              buscar
            </button>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosSupplier.endereco"
            />
            <label class="user-label"> Endereço </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosSupplier.cidade"
            />
            <label class="user-label"> Cidade </label>
          </div>
        </div>
        <div class="rowContent">
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosSupplier.bairro"
            />
            <label class="user-label"> Bairro </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosSupplier.numero"
            />
            <label class="user-label"> Número </label>
          </div>
          <div class="selectPlus">
            <label class="fixedSelect2">UF</label>
            <b-form-select
              class="borderInput shadow-none"
              value-field="value"
              text-field="text"
              :options="ufs"
              v-model="dadosSupplier.uf"
            >
            </b-form-select>
          </div>
        </div>
      </div>

      <div class="rowContainer">
        <span> <b-icon-telephone-fill></b-icon-telephone-fill> Contatos</span>
        <div class="rowContent">
          <div class="input-group">
            <input
              required
              type="email"
              class="input borderInput"
              v-model="dadosSupplier.email"
            />
            <label class="user-label"> Email </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosSupplier.telefone"
              v-mask="maskTelefone"
            />
            <label class="user-label"> Telefone </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosSupplier.celular"
              v-mask="maskCelular"
            />
            <label class="user-label"> Celular </label>
          </div>
        </div>
      </div>
      <div class="rowContainer">
        <span> <b-icon-info-circle></b-icon-info-circle> Outros</span>
        <div class="rowContentOther">
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosSupplier.chavePix"
            />
            <label class="user-label"> Chave Pix </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput lareInput"
              v-model="dadosSupplier.observacoes"
            />
            <label class="user-label"> Observações </label>
          </div>
        </div>
        <div class="btnsContainer">
          <b-button class="btnsSaveNeutral" @click="clear"
            >Novo <b-icon-arrow-clockwise class="ml-1"></b-icon-arrow-clockwise
          ></b-button>
          <b-button class="btnsSave" @click="saveAndUpdateSupplier"
            >{{ dadosSupplier.id ? "Atualizar" : "Salvar" }}
            <b-icon-check2-circle class="ml-1"></b-icon-check2-circle
          ></b-button>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import api from "../../services/axios";
import toastAlertErros from "../../utils/toastAlertErros";
import ServiceSupplier from "../../services/serviceSupplier";
import { mapMutations, mapState } from "vuex";
import { HandleErrors } from "../../utils/handleErrors";
import { handleCpfOrCnpj } from "../../utils/validateCpfCnpj";
import { ufs } from "../../common/index";

export default {
  props: {
    dataSupplier: {
      type: Object,
    },
    idSupplierFromSearch: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      dadosSupplier: {
        id: "",
        nomeFantasia: "",
        razaoSocial: "",
        cpfCnpj: "",
        ie: "",
        endereco: "",
        numero: "",
        bairro: "",
        cidade: "",
        uf: "",
        email: "",
        telefone: "",
        celular: "",
        chavePix: "",
        observacoes: "",
        cep: "",
      },
      spinGeneral: false,
      spinGeneralCnpj: false,
      ufs: ufs,
    };
  },
  methods: {
    ...mapMutations([
      "SET_IS_SEARCH_REGISTERS",
      "ALTER_SIZE_MODAL_REGISTER",
      "ALTER_TYPE_REGISTER",
      "SET_COLAPSE_REGISTER",
      "ALTER_MODAL_CONTAINER_REGISTER",
      "SET_DATA_SEARCH_FROM_SUPPLIER",
    ]),
    async saveSupllier() {
      try {
        const result = handleCpfOrCnpj(this.dadosSupplier.cpfCnpj, this.$toast);
        if (result && !result.isValid) {
          return result.alert();
        }

        const id = await ServiceSupplier.saveSupllier(this.dadosSupplier);
        this.dadosSupplier.id = id;

        this.$emit("getAllSupplier", this.getSupplier);
        this.getSupplier = !this.getSupplier;
        this.SET_IS_SEARCH_REGISTERS();
        this.clear();
        return this.$toast.open({
          message: "Fornecedor salvo com sucesso",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;

        const typeError = HandleErrors(message);
        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else toastAlertErros.validateErroDoesNotContainFor(error, this.$toast);
      }
    },

    async updateSupplier() {
      try {
        const result = handleCpfOrCnpj(this.dadosSupplier.cpfCnpj, this.$toast);
        if (result && !result.isValid) {
          return result.alert();
        }

        await api.put(
          `/Providers/${this.dadosSupplier.id}`,
          this.dadosSupplier
        );

        this.$emit("getAllSupplier", this.getSupplier);
        this.getSupplier = !this.getSupplier;
        this.SET_IS_SEARCH_REGISTERS();
        this.clear();
        return this.$toast.open({
          message: "Fornecedor atualizado com sucesso",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;

        const typeError = HandleErrors(message);
        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else toastAlertErros.validateErroDoesNotContainFor(error, this.$toast);
      }
    },

    saveAndUpdateSupplier() {
      this.dadosSupplier.id !== ""
        ? this.updateSupplier()
        : this.saveSupllier();
    },

    assingEditSupplier() {
      this.dadosSupplier.id = this.dataSearchSupplier.id;
      this.dadosSupplier.nomeFantasia = this.dataSearchSupplier.nomeFantasia;
      this.dadosSupplier.razaoSocial = this.dataSearchSupplier.razaoSocial;
      this.dadosSupplier.cpfCnpj = this.dataSearchSupplier.cpfCnpj;
      this.dadosSupplier.ie = this.dataSearchSupplier.ie;
      this.dadosSupplier.endereco = this.dataSearchSupplier.endereco;
      this.dadosSupplier.numero = this.dataSearchSupplier.numero;
      this.dadosSupplier.bairro = this.dataSearchSupplier.bairro;
      this.dadosSupplier.cidade = this.dataSearchSupplier.cidade;
      this.dadosSupplier.uf = this.dataSearchSupplier.uf;
      this.dadosSupplier.email = this.dataSearchSupplier.email;
      this.dadosSupplier.telefone = this.dataSearchSupplier.telefone;
      this.dadosSupplier.celular = this.dataSearchSupplier.celular;
      this.dadosSupplier.observacoes = this.dataSearchSupplier.observacoes;
      this.dadosSupplier.chavePix = this.dataSearchSupplier.chavePix;
      this.dadosSupplier.cep = this.dataSearchSupplier.cep;
    },

    clear() {
      this.dadosSupplier.id = "";
      this.dadosSupplier.nomeFantasia = "";
      this.dadosSupplier.razaoSocial = "";
      this.dadosSupplier.cpfCnpj = "";
      this.dadosSupplier.ie = "";
      this.dadosSupplier.endereco = "";
      this.dadosSupplier.numero = "";
      this.dadosSupplier.bairro = "";
      this.dadosSupplier.cidade = "";
      this.dadosSupplier.uf = "";
      this.dadosSupplier.email = "";
      this.dadosSupplier.telefone = "";
      this.dadosSupplier.celular = "";
      this.dadosSupplier.observacoes = "";
      this.dadosSupplier.chavePix = "";
      this.dadosSupplier.cep = "";

      this.SET_DATA_SEARCH_FROM_SUPPLIER({});
    },
    async fetchCep(cep) {
      try {
        this.spinGeneral = true;
        const formatedCep = cep.replace(/\D/g, "");
        const data = await api.get(
          `https://brasilapi.com.br/api/cep/v1/${formatedCep}`
        );
        this.dadosSupplier.endereco = data.data.street;
        this.dadosSupplier.bairro = data.data.neighborhood;
        this.dadosSupplier.cidade = data.data.city;
        this.dadosSupplier.uf = data.data.state;
        this.dadosSupplier.cep = data.data.cep;
      } catch (error) {
        this.$toast.open({
          message:
            "Ocorreu um erro ao buscar as informações , verifique o CEP e tente novamente",
          type: "info",
        });
      } finally {
        this.spinGeneral = false;
      }
    },
    async fetchCnpj(cnpj) {
      try {
        this.spinGeneralCnpj = true;
        const formatedCnpj = cnpj.replace(/\D/g, "");
        const data = await api.get(
          `https://brasilapi.com.br/api/cnpj/v1/${formatedCnpj}`
        );

        if (data.data.nome_fantasia) {
          this.dadosSupplier.nomeFantasia = data.data.nome_fantasia;
        } else {
          this.dadosSupplier.nomeFantasia = data.data.razao_social;
        }
        this.dadosSupplier.razaoSocial = data.data.razao_social;
        this.dadosSupplier.endereco = data.data.logradouro;
        this.dadosSupplier.numero = data.data.numero;
        this.dadosSupplier.bairro = data.data.bairro;
        this.dadosSupplier.cidade = data.data.municipio;
        this.dadosSupplier.uf = data.data.uf;
        this.dadosSupplier.telefone = data.data.ddd_telefone_1;
        this.dadosSupplier.cep = data.data.cep;
      } catch (error) {
        this.$toast.open({
          message:
            "Ocorreu um erro ao buscar as informações , verifique o CNPJ e tente novamente",
          type: "info",
        });
      } finally {
        this.spinGeneralCnpj = false;
      }
    },
    async handleModalCreateSupplier() {
      const supplier = await ServiceSupplier.findById(
        this.idSupplierFromSearch
      );
      this.SET_DATA_SEARCH_FROM_SUPPLIER(supplier);
      this.assingEditSupplier();
    },

    checkInputLength() {
      if (this.dadosSupplier.cpfCnpj.length === 18) this.$forceUpdate();
    },

    handleValueCep(event) {
      this.dadosSupplier.cep = (event.target.value || "").replace(/\D/g, "");
    },

    maskCep(value) {
      if (!value) return "";
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{5})(\d)/, "$1-$2");
      return value;
    },
  },
  watch: {
    dataSupplier() {
      Object.assign(this.dadosSupplier, this.dataSupplier);
    },
  },
  mounted() {
    if (this.dataSearchSupplier.id) {
      this.assingEditSupplier();
    } else if (this.idSupplierFromSearch) {
      this.handleModalCreateSupplier();
    }
  },

  computed: {
    ...mapState({
      dataSearchSupplier: (state) => state.dataSearchSupplier,
    }),
    maskCpfCnpj() {
      if (this.dadosSupplier.cpfCnpj === null) {
        return "";
      }
      if (this.dadosSupplier.cpfCnpj.length <= 14) {
        return "###.###.###-##";
      }
      if (this.dadosSupplier.cpfCnpj.length > 14) {
        return "##.###.###/####-##";
      } else {
        return "";
      }
    },

    maskCelular() {
      return "(##) #####-####";
    },
    maskTelefone() {
      return "(##) ####-####";
    },
  },

  beforeDestroy() {
    this.clear();
  },
};
</script>
<style scoped>
.form-control-sm {
  height: 28px;
  padding: 0.25rem 0.25rem;
  font-size: 12px;
  line-height: 1.3;
  border-radius: 0.2rem;
}
span {
  font-weight: bold;
  cursor: context-menu;
}

.user-label {
  position: absolute;
  left: 10px;
  font-size: 11px;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.inputDisabled {
  background-color: #fafafa;
  pointer-events: none;
  color: #949290 !important;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
  font-weight: 600;
}

.selectPlus {
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 8.9px;
  bottom: 22px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
  font-weight: 600;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 11px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: none;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.borderInput {
  font-size: 14px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050;
}

.validRed {
  font-size: 13px;
  color: red;
}

.bcsCep {
  display: flex;
  height: 30px;
  position: absolute;
  right: 0px;
  font-size: 11px;
  z-index: 2;
  cursor: pointer;
  color: black;
  border-radius: 5px;
  border: none;
  background: #c3c3c3;
  align-items: center;
}

.title {
  font-size: 16px;
  color: rgb(95, 95, 95);
  display: flex;
  margin-bottom: 20px;
  margin-right: 20px;
  border-bottom: 1px solid rgb(230, 230, 230);
  justify-content: space-between;
}

.title span {
  font-weight: 600;
}

.requiredFields {
  font-size: 11px;
  color: red;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 5px;
}

.rowContainer span {
  font-weight: 600;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.rowContentOther {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: flex-start;
  gap: 60px;
}

.btnsContainer {
  gap: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.btnsSave {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;

  font-weight: 600;
}

.btnsSaveNeutral {
  border-radius: 5px;
  background-color: #d7d7d7;
  width: 165px;
  border: none;
  margin-top: 30px;
  color: rgb(0, 0, 0);
  font-size: 12px;

  font-weight: 600;
}

.input-group {
  width: 30%;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 1rem 0 1rem;
}

.card {
  border: none !important;
}

@media screen and (max-width: 700px) {
  .rowContent {
    display: flex;
    width: 100%;
    padding: 5px 0 5px 0;
    justify-content: space-between;
    gap: 10px;
    flex-direction: column;
  }

  .input-group {
    width: 100%;
  }

  .selectPlus {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }
}
</style>
