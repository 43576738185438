import api from "./axios";

class ServiceMachine {
  async createOrder(idSale, typeIntegration = 'stone') {
    const { data } = await api.get(`/machine/${idSale}?type=${typeIntegration}`);

    return data;
  }

  async cancelOrder(idSale, typeIntegration = 'stone') {
    const { data } = await api.get(`/machine/cancel-order/${idSale}?type=${typeIntegration}`);

    return data;
  }

  async checkIsPaidAndGenerateDocument(idSale, typeIntegration = 'stone') {
    const { data } = await api.get(`/machine/check-is-paid/generate-document/${idSale}?type=${typeIntegration}`);

    return data;
  }

  async checkOrder(idSale) {
    const { data } = await api.get(`/machine/check-order/${idSale}`);
    
    return data;
  }

  async printDocument(idSale, base64Document, typeIntegration = 'stone') {
    await api.post(`/machine/print/${idSale}?type=${typeIntegration}`, { base64Document })
  }
}

export default new ServiceMachine()