import api from "../services/axios";
class ServicePurchase {
  async savePurchase(dataPurchase) {
    const { data } = await api.post('/purchase', dataPurchase)
    return data
  }

  async updatePurchase(id, dataPurchase) {
    const { data } = await api.put(`/purchase/update/${id}`, dataPurchase)
    return data
  }

  async searchPurchase(textPesquisa, type, page, startDate, endDate) {
    const { data } = await api.get(
      `/purchase/search?q=${encodeURIComponent(textPesquisa)}&type=${type}&page=${page}&startDate=${startDate}&endDate=${endDate}`
    );
    return data
  }

  async findPurchaseById(id){
    const {data:{data}} = await api.get(`purchase/edit/purchase/${id}`)
    return data
  }
}

export default new ServicePurchase()