<template>
  <div>
    <div class="ContainerSearchInput">
      <div class="textRegister">
        <span>Produtos Registrados</span>
      </div>
      <div class="searchInput">
        <b-form-select
          class="searchFilter"
          size="sm"
          value-field="value"
          text-field="text"
          v-model="type"
          :options="filterCombobox"
        ></b-form-select>
        <b-input
          class="inputSearch"
          size="sm"
          placeholder="Pesquisar"
          v-model="textPesquisa"
          @keyup.enter="readProducts(page)"
        ></b-input>
        <b-button size="sm" @click="readProducts(page)" class="searchBtn">
          <b-icon-search></b-icon-search>
        </b-button>
        <div class="searchInput" style="justify-content: flex-end">
          <button class="searchAll" @click="readProducts(page)">
            ver tudo
          </button>
        </div>
      </div>
    </div>
    <div class="ContentTable">
      <table style="width: 100%">
        <thead class="headTable">
          <tr style="width: 100%">
            <th style="width: 5%">Status</th>
            <th style="width: 5%">Cód</th>
            <th style="width: 35%">Nome</th>
            <th style="width: 15%">Cod Referência</th>
            <th style="width: 10%">Estoque Atual</th>
            <th style="width: 10%">Preço de Venda</th>
            <th style="width: 10%">Preço de Custo</th>
            <th style="width: 10%">Ações</th>
          </tr>
        </thead>
        <tbody v-if="this.dataLength > 0" style="color: #3a4750; width: 100%">
          <tr
            v-for="products in dataProducts"
            class="trTable"
            :key="products.id"
          >
            <td
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
              "
            >
              <div class="ballState" v-if="products.status === 'Ativo'"></div>
              <div class="ballStateRed" v-else></div>
            </td>
            <td style="width: 5%">{{ products.codigo }}</td>
            <td style="width: 30%">{{ products.nome }}</td>
            <td style="width: 20%">{{ products.codReferencia || "-" }}</td>
            <td style="width: 10%">{{ products.estoque}}</td>
            <td style="width: 10%">
              {{
                products.valorVenda.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </td>
            <td style="width: 10%">
              {{
                products.valor.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </td>
            <td style="width: 10%">
              <div class="btnActions">
                <b-dropdown
                  id="dropdown-1"
                  text="Ações"
                  menu-class="w-1"
                  class="btnActions"
                  size="sm"
                  variant="none"
                >
                  <template #button-content>
                    <b-icon-gear-fill class="contentActions"></b-icon-gear-fill>
                  </template>
                  <b-dropdown-item @click="findById(products)">
                    Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="destroyproducts(products.id)">
                    Excluir
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-b-modal.modal-provider
                    @click="findByIdAndProvider(products)"
                  >
                    Ver Fornecedores
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <td colspan="7">
            <div class="defaultSearch">
              <span>Não há dados para exibir ...</span>
            </div>
          </td>
        </tbody>
        <div v-if="spinGeneral" class="loadingLazy">
          <div class="loader"></div>
        </div>
      </table>
    </div>
    <div class="setBtn">
      <button @click="previousPage" :disabled="page === 1" class="itemBtn">
        <b-icon-chevron-left></b-icon-chevron-left>
      </button>
      <button class="centerItemBtn">{{ page }}</button>
      <button @click="nextPage" :disabled="this.dataLength < 9" class="itemBtn">
        <b-icon-chevron-right></b-icon-chevron-right>
      </button>
    </div>
    <b-modal
      id="modal-provider"
      hide-footer
      hide-header
      centered
      size="lg"
      title="Excluir"
    >
      <div>
        <div class="titleModalBill">
          <h6 class="textModalBill">Fornecedores do Produto</h6>
          <div class="closeModalBill" @click="$bvModal.hide('modal-provider')">
            X
          </div>
        </div>
        <div class="ContentTableBill">
          <table style="border-spacing: 1px 0">
            <thead
              style="
                color: whitesmoke;
                background: #3a4750;
                padding: 10px;
                height: 35px;
                text-align: center;
                border-spacing: 0;
              "
            >
              <tr style="width: 100%">
                <th style="width: 50%">Fornecedor</th>
                <th style="width: 10%">quantidade</th>
                <th style="width: 15%">Vl Unitário</th>
                <th style="width: 15%">Vl Total</th>
              </tr>
            </thead>
            <tbody v-if="this.providerProduct.length > 0">
              <tr
                style="
                  text-align: center;
                  height: 60px;
                  border-bottom: 1px solid rgb(218, 218, 218);
                "
                v-for="provider in providerProduct"
                :key="provider.id"
              >
                <td>{{ provider.razaoSocial }}</td>
                <td>{{ provider.estoque }}</td>
                <td>
                  {{
                    provider.valorUnitario.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </td>
                <td>
                  {{
                    provider.valorTotal.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <td colspan="5">
                <div class="defaultSearch">
                  <span>Não há fornecedores para o produto ...</span>
                </div>
              </td>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import mixinSearchProducts from "../../mixins/Products/Search";
import { mapMutations, mapState } from "vuex";
import serviceProducts from "../../services/serviceProducts";
import api from "../../services/axios";

export default {
  props: {
    getAllProducts: {
      type: Boolean,
    },
  },
  mixins: [mixinSearchProducts],

  data() {
    return {
      textPesquisa: "",
      page: 1,
      type: "",
      filterCombobox: [
        { value: "nome", text: "Nome" },
        { value: "valorVenda", text: "Valor de Venda" },
        { value: "codReferencia", text: "Código Referência" },
        { value: "codInterno", text: "Código Interno" },
        { value: "estoque", text: "Estoque" },
        { value: "localizacao", text: "Localização" },
        { value: "status", text: "Status" },
        { value: "", text: "Todos" },
      ],
      providerProduct: {},
    };
  },
  methods: {
    ...mapMutations([
      "ALTER_SIZE_MODAL_REGISTER",
      "ALTER_TYPE_REGISTER",
      "SET_COLAPSE_REGISTER",
      "ALTER_MODAL_CONTAINER_REGISTER",
      "SET_DATA_SEARCH_FROM_PRODUCT",
    ]),
    async findById(product) {
      try {
        const result = await serviceProducts.getProductById(product.id);
        this.openModalToReceive(result);
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ocorreu um eror ao buscar o produto!",
            type: "info",
          });
      }
    },

    async findByIdAndProvider(product) {
      try {
        const result = await serviceProducts.getProductById(product.id);
        const data = await api.get(`/products-by-provider/${result.id}`);
        this.providerProduct = data.data;
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ocorreu um eror ao buscar o produto!",
            type: "info",
          });
      }
    },

    openModalToReceive(product) {
      this.SET_DATA_SEARCH_FROM_PRODUCT(product);

      const name = "products";
      this.ALTER_TYPE_REGISTER(name);
      this.ALTER_SIZE_MODAL_REGISTER("ModalGeneral");
      this.SET_COLAPSE_REGISTER(true);
      this.ALTER_MODAL_CONTAINER_REGISTER("modalContainer");
    },
  },
  computed: {
    ...mapState({
      isSearchRegisters: (state) => state.isSearchRegisters,
    }),
  },
  watch: {
    async getAllProducts() {
      await this.readProducts(1);
    },
    isSearchRegisters() {
      this.readProducts();
    },
  },
};
</script>

<style scoped>
.ContentTable {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 10px;
  width: 100%;
  max-width: 100%;
}

.headTable {
  color: whitesmoke;
  background: #3a4750;
  text-align: center;
  height: 29px;
}

.ballState {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: green;
}

.ballStateRed {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: red;
}

.trTable {
  text-align: center;
  height: 29px;
  cursor: context-menu;
  font-size: 12px;
}

tr:nth-child(even) {
  background: #f2f3f3;
}

th {
  font-weight: 500;
  font-size: 12px;
}

td {
  text-transform: capitalize;
}

.btnActions {
  height: 25px;
  display: flex;
  justify-content: center;
}

.titleModalBill {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 5px 0px;
  border-bottom: 1px solid #e6e7e8;
}

.textModalBill {
  font-weight: bold;
  width: 95%;
  text-align: center;
}

.closeModalBill {
  cursor: pointer;
}

.ContentTableBill {
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-size: 13px;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  padding: 0 0 10px 0;
  background: #fbfbfb;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 10px;
}

.closeModalBill:hover {
  color: red;
}

.dropdown-menu {
  font-size: 12px;
  min-width: 5rem !important;
  width: 5rem !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  color: red;
  background: red;
  text-align: center;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
}

.searchFilter {
  width: 150px;
  border-right: none;
  height: 30px;
  font-size: 11px;
  text-align: center;
}

.searchFilter:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

.custom-select {
  left: 4px;
  position: relative;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid rgb(195, 193, 193);
  -moz-appearance: none;
  appearance: none;
}

.ContainerSearchInput {
  display: flex;
  align-items: center;
  height: 37px;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
}

.textRegister span {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.searchInput {
  width: 50%;
  display: flex;
}

.searchAll {
  justify-content: center;
  color: #3a4750;
  font-size: 13px;
  padding: 5px 20px;
  font-weight: bold;
  border: solid 1px #e3e0e0;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchAll:hover {
  background: rgb(205, 205, 205);
}

.form-control-sm {
  padding: 0.25rem 1.5rem;
  height: 30px;
}

input {
  border: 1px solid rgb(195, 193, 193);
  border-radius: 5px;
  height: 30px;
}

.searchBtn {
  right: 10px;
  height: 30px;
  position: relative;
  background: whitesmoke;
  color: black;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  border-color: #c3c1c1;
}

input:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

button:focus {
  box-shadow: none;
}

.pagMonth {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.FooterPagination {
  display: flex;
  align-items: center;
  height: 70px;
  padding: 20px;
  width: 100%;
  justify-content: flex-end;
}

.defaultSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20vh;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.defaultSearch span {
  font-size: 1rem;
  font-weight: bold;
  color: gray;
}

.loadingLazy {
  position: absolute;
  bottom: 20px;
  height: 50vh;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(140, 140, 140);
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  opacity: 0.5;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #403b74;
  box-shadow: 30px 0 0 #f2a83e;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #3e66ae;
  box-shadow: 30px 0 0 #48b865;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f2a83e;
  }

  50% {
    box-shadow: 0 0 0 #f2a83e;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f2a83e;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #48b865;
  }

  50% {
    box-shadow: 0 0 0 #48b865;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #48b865;
    margin-top: 0;
  }
}

.setBtn {
  display: flex;
  flex-direction: row;
  margin: 0 1rem 1rem 0;
  gap: 10px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.itemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #3a4750;
}

.itemBtn:hover {
  background: #3a4750;
  color: whitesmoke;
}

.itemBtn:disabled {
  opacity: 0;
}

.centerItemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #1b3159;
  cursor: auto;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .ContainerSearchInput {
    padding: 3px;
  }
  .textRegister {
    font-size: 12px;
  }
  .searchInput {
    width: 60%;
  }
  .searchFilter {
    height: 25px;
    font-size: 11px;
  }
  .form-control-sm {
    padding: 0.25rem 1.2rem;
    height: 25px;
    font-size: 12px;
  }
  .searchBtn {
    right: 10px;
    height: 25px;
    display: flex;
    align-items: center;
  }
  .searchAll {
    font-size: 11px;
    padding: 2px 10px;
  }
  .ContentTable {
    height: 50vh;
    max-height: 50vh;
    padding: 5px;
  }
  .headTable {
    height: 25px;
  }
  .trTable {
    height: 25px;
    font-size: 11px;
  }
  .setBtn {
    margin: 0 1rem 10px 0;
  }

  .itemBtn {
    width: 18px;
    height: 18px;
  }

  .centerItemBtn {
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 700px) {
  .ContainerSearchInput {
    display: flex;
    align-items: center;
    height: 70px;
    gap: 5px;
    width: 100%;
    justify-content: center;
    padding: 0;
    flex-direction: column;
  }

  .searchInput {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 3px 0 0;
  }

  .ContentTable {
    padding: 5px;
  }
}
</style>