<template>
  <div class="Financial">
    <div class="ContainerBtnFinancial">
      <b-button
        class="BtnFinancial"
        style="background: #004727"
        @click="handleModals('toReceive')"
      >
        <b-icon-plus scale="1.5"></b-icon-plus>
        Nova conta a receber</b-button
      >
      <b-button
        class="BtnFinancial"
        style="background: #690000"
        @click="handleModals('toPay')"
      >
        <b-icon-plus scale="1.5"></b-icon-plus>
        Nova conta a pagar</b-button
      >
    </div>

    <div class="lineSearch">
      <div class="searchByMonth">
        <div class="summaryMonth">Resumo Do Mês</div>
        <div class="pagMonth">
          <b-icon-chevron-left
            class="Arrows"
            id="btn-month-prev"
            @click="updateMonth('prev')"
          ></b-icon-chevron-left>
          <b-form-select
            class="searchMonth custom-select3"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="monthSelected"
            :options="filterMonth"
            @change="monthHasChanged"
          ></b-form-select>
          <b-form-select
            class="searchYear custom-select3"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="yearSelected"
            :options="filterYear"
            @change="monthHasChanged"
          ></b-form-select>
          <b-icon-chevron-right
            class="Arrows"
            style="margin: 0 0 0 7px"
            id="btn-month-next"
            @click="updateMonth('next')"
          ></b-icon-chevron-right>
        </div>
      </div>
    </div>

    <div class="SumFinancial">
      <div class="radio-input">
        <input
          value="toReceive"
          @click.stop="activeTable"
          name="financial-option"
          id="toReceive"
          type="radio"
          :checked="kind === 'entrada'"
          class="radio-receive"
        />
        <label
          style="border: 3px ridge #004727"
          class="ItemSumFinancial"
          :class="{ 'green-checked': kind === 'entrada' }"
          for="toReceive"
        >
          Total a receber ({{ monthName }}/{{ year }})
          <p>
            {{
              this.receivedSummary.totalReceive.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </p>
        </label>

        <input
          value="toPay"
          @click.stop="activeTable"
          name="financial-option"
          id="toPay"
          type="radio"
          :checked="kind === 'saida'"
          class="radio-pay"
        />
        <label
          style="border: 3px ridge #690000"
          class="ItemSumFinancial"
          :class="{ 'red-checked': kind === 'saida' }"
          for="toPay"
        >
          Total a pagar ({{ monthName }}/{{ year }})
          <p>
            {{
              this.payableSummary.totalPay.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </p>
        </label>
      </div>
    </div>
    <div class="ContainerSearchInput">
      <div v-if="this.kind === 'entrada'" class="textReceive">
        <img class="cash" :src="receiveImg" />
        <span style="padding: 7px 0px 0px 4px">Contas a Receber</span>
      </div>
      <div v-if="this.kind === 'saida'" class="textPay">
        <img class="cashR" :src="payImg" />
        <span style="padding-top: 7px">Contas a Pagar</span>
      </div>
      <div class="searchContainer">
        <div class="searchAvancedContent">
          <button
            v-if="!isAdvancedSearch"
            class="searchAvanced"
            @click="enableAdvancedSearch"
          >
            Realizar Pesquisa Avançada
          </button>
          <button
            v-if="isAdvancedSearch"
            class="searchAvanced"
            @click="enableAdvancedSearch"
          >
            Pesquisa por mês
          </button>
        </div>
        <div
          class="searchInput"
          style="padding: 0px !important; min-width: 40% !important"
          v-if="isAdvancedSearch"
        >
          <button class="btnPeriod" size="sm">Período</button>
          <div class="dateInput">
            <input
              type="date"
              id="date"
              v-model="startDate"
              style="
                padding: 0.25rem 0.25rem 0.25rem 0.5rem;
                cursor: pointer;
                border-left: none;
                border-radius: 0px 5px 5px 0px;
              "
            />
            <input
              type="date"
              id="date"
              v-model="endDate"
              style="padding: 0.25rem 0.25rem 0.25rem 0.25rem; cursor: pointer"
            />
          </div>
        </div>
        <div class="searchInput">
          <b-form-select
            v-if="this.kind === 'entrada'"
            class="searchFilter"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="type"
            :options="filterComboboxCustomer"
          ></b-form-select>
          <b-form-select
            v-if="this.kind === 'saida'"
            class="searchFilter"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="type"
            :options="filterComboboxSupplier"
          ></b-form-select>
          <div v-if="type === 'situacao'">
            <b-form-select
              class="searchFilterSituation custom-select2"
              value-field="value"
              text-field="text"
              v-model="textPesquisa"
              :options="filterSituation"
            ></b-form-select>
          </div>
          <div v-if="type === 'categoria'">
            <b-form-select
              value-field="id"
              text-field="nome"
              style="width: 150px"
              class="searchFilterSituation custom-select2"
              :options="allCategoryBills"
              v-model="idCategoryBill"
            >
            </b-form-select>
          </div>
          <div v-else>
            <b-input
              placeholder="Pesquisar por..."
              v-model="textPesquisa"
              @keyup.enter="filterSalePerMonth()"
            ></b-input>
          </div>
          <b-button
            size="sm"
            @click="filterSalePerMonth(true)"
            class="searchBtn"
          >
            <b-icon-search></b-icon-search>
          </b-button>
          <button class="searchAll" @click="seeAll">ver tudo</button>
        </div>
      </div>
    </div>
    <div v-if="this.kind === 'entrada'" class="SumStateFinancial">
      <button
        @click="filterPerType"
        name="vencidas"
        class="ItemSumStateFinancial"
        style="
          border: 1px solid #d9d9d9;
          border-radius: 5px 0 0 5px;
          color: #c31d1d;
        "
      >
        Vencidas
        <b-spinner v-if="spinSummary" small></b-spinner>
        <p v-else>
          {{
            this.receivedSummary.sumMatury.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </p>
      </button>
      <button
        @click="filterPerType"
        class="ItemSumStateFinancial"
        style="color: #403b74"
        name="vencem"
      >
        Vencem Hoje
        <b-spinner v-if="spinSummary" small></b-spinner>
        <p v-else>
          {{
            this.receivedSummary.expireToday.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </p>
      </button>
      <button
        @click="filterPerType"
        name="vencer"
        class="ItemSumStateFinancial"
        style="color: #3e66ae"
      >
        A Vencer
        <b-spinner v-if="spinSummary" small></b-spinner>
        <p v-else>
          {{
            this.receivedSummary.toWin.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </p>
      </button>
      <button
        @click="filterPerType"
        name="pago"
        class="ItemSumStateFinancial"
        style="color: #00c26b"
      >
        Recebidos
        <b-spinner v-if="spinSummary" small></b-spinner>
        <p v-else>
          {{
            this.receivedSummary.totalPaid.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </p>
      </button>
      <button
        @click="seeAll"
        class="ItemSumStateFinancial"
        style="border-radius: 0 5px 5px 0; color: #4c4c4c"
      >
        Total do Período
        <b-spinner v-if="spinSummary" small></b-spinner>
        <p v-else>
          {{
            this.receivedSummary.monthTotal.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </p>
      </button>
    </div>
    <div v-if="this.kind === 'saida'" class="SumStateFinancial">
      <button
        @click="filterPerType"
        name="vencidas"
        class="ItemSumStateFinancial"
        style="
          border: 1px solid #d9d9d9;
          border-radius: 5px 0 0 5px;
          color: #c31d1d;
        "
      >
        Vencidas
        <b-spinner v-if="spinSummary" small></b-spinner>
        <p v-else>
          {{
            this.payableSummary.sumMatury.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </p>
      </button>
      <button
        @click="filterPerType"
        class="ItemSumStateFinancial"
        style="color: #403b74"
        name="vencem"
      >
        Vencem Hoje
        <b-spinner v-if="spinSummary" small></b-spinner>
        <p v-else>
          {{
            this.payableSummary.expireToday.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </p>
      </button>
      <button
        @click="filterPerType"
        name="vencer"
        class="ItemSumStateFinancial"
        style="color: #3e66ae"
      >
        A Vencer
        <b-spinner v-if="spinSummary" small></b-spinner>
        <p v-else>
          {{
            this.payableSummary.toWin.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </p>
      </button>
      <button
        @click="filterPerType"
        name="pago"
        class="ItemSumStateFinancial"
        style="color: #00c26b"
      >
        Pagos
        <b-spinner v-if="spinSummary" small></b-spinner>
        <p v-else>
          {{
            this.payableSummary.totalPaid.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </p>
      </button>
      <button
        @click="seeAll"
        class="ItemSumStateFinancial"
        style="border-radius: 0 5px 5px 0; color: #4c4c4c"
      >
        Total do Período
        <b-spinner v-if="spinSummary" small></b-spinner>
        <p v-else>
          {{
            this.payableSummary.monthTotal.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </p>
      </button>
    </div>
    <ToPayTable
      :payFilter="payFilter"
      :dataLength="dataLength"
      v-if="tableActive.toPay"
    />
    <ToReceiveTable
      :dataLength="dataLength"
      :receiveFilter="receiveFilter"
      v-if="tableActive.toReceive"
    />

    <div class="setBtn">
      <button class="itemBtn" :disabled="page == 1" @click="previousPage(page)">
        <b-icon-chevron-left></b-icon-chevron-left>
      </button>
      <button class="centerItemBtn">{{ page }}</button>
      <button
        class="itemBtn"
        :disabled="this.dataLength < 9"
        @click="nextPage(page)"
      >
        <b-icon-chevron-right></b-icon-chevron-right>
      </button>
    </div>

    <div :class="sizeModalFinancial">
      <div :class="modalContainerFinancial" v-if="colapseFinancial">
        <b-button class="closeModal" @click="modalHeight"> X </b-button>
        <Bill v-if="enableModalsFinancial.toReceive" />
        <Bill
          v-if="enableModalsFinancial.toPay"
          title="Nova Despesa"
          type="saida"
          label="Conta de Pagamento"
          :isProvider="true"
        />
        <BillDetails v-if="enableModalsFinancial.billDetails" />
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../services/axios";
import { DateTime } from "luxon";
import ToPayTable from "./ToPayTable.vue";
import ToReceiveTable from "./ToReceiveTable.vue";
import Bill from "../Bill/Index-Bill.vue";
import BillDetails from "../BillDetails/Index-Bill-Details.vue";
import receiveImg from "../../assets/cashReceive.svg";
import payImg from "../../assets/cashPay.svg";

import { mapMutations, mapState } from "vuex";
import serviceCategoryBill from "../../services/serviceCategoryBill";
export default {
  components: {
    ToPayTable,
    ToReceiveTable,
    Bill,
    BillDetails,
  },
  data() {
    return {
      receiveImg: receiveImg,
      payImg: payImg,
      textPesquisa: "",
      type: "nomeCliente",
      status: "",
      kind: "entrada",
      page: 1,
      startDate: "",
      endDate: "",
      userLevel: "",
      spinSummary: false,
      isAdvancedSearch: false,
      year: DateTime.local().year,
      month: DateTime.local(),
      filterComboboxSupplier: [
        { value: "nomeFantasia", text: "Fornecedor" },
        { value: "situacao", text: "Situação" },
        { value: "valorTotal", text: "Valor da Parcela" },
        { value: "categoria", text: "Categoria" },
      ],
      filterComboboxCustomer: [
        { value: "nomeCliente", text: "Cliente" },
        { value: "situacao", text: "Situação" },
        { value: "valorTotal", text: "Valor da Parcela" },
      ],
      filterSituation: [
        { value: "Em aberto", text: "Em aberto" },
        { value: "Pago", text: "Pago" },
        { value: "Pago Parcial", text: "Pago Parcial" },
        { value: "Perda", text: "Perda" },
        { value: "Vencido", text: "Vencido" },
        { value: "", text: "Selecionar situação..." },
      ],
      filterMonth: [
        { value: "1", text: "Janeiro" },
        { value: "2", text: "Fevereiro" },
        { value: "3", text: "Março" },
        { value: "4", text: "Abril" },
        { value: "5", text: "Maio" },
        { value: "6", text: "Junho" },
        { value: "7", text: "Julho" },
        { value: "8", text: "Agosto" },
        { value: "9", text: "Setembro" },
        { value: "10", text: "Outubro" },
        { value: "11", text: "Novembro" },
        { value: "12", text: "Dezembro" },
      ],
      filterYear: [
        { value: "2021", text: "2021" },
        { value: "2022", text: "2022" },
        { value: "2023", text: "2023" },
        { value: "2024", text: "2024" },
        { value: "2025", text: "2025" },
        { value: "2026", text: "2026" },
        { value: "2027", text: "2027" },
        { value: "2028", text: "2028" },
        { value: "2029", text: "2029" },
        { value: "2030", text: "2030" },
        { value: "2031", text: "2031" },
        { value: "2032", text: "2032" },
      ],
      monthSelected: "",
      yearSelected: "",
      receiveFilter: {},
      payFilter: {},
      allCategoryBills: [],
      idCategoryBill: "",
      dataLength: 0,
      tableActive: {
        toPay: false,
        toReceive: true,
      },
      receivedSummary: {
        totalReceive: 0,
        sumMatury: 0,
        monthTotal: 0,
        totalPaid: 0,
        expireToday: 0,
        toWin: 0,
      },
      payableSummary: {
        totalPay: 0,
        sumMatury: 0,
        monthTotal: 0,
        totalPaid: 0,
        expireToday: 0,
        toWin: 0,
      },
    };
  },
  methods: {
    ...mapMutations([
      "ALTER_SIZE_MODAL_FINANCIAL",
      "SET_COLAPSE_FINANCIAL",
      "ALTER_TYPE_FINANCIAL",
      "ALTER_MODAL_CONTAINER_FINANCIAL",
      "SET_DATA_SEARCH_FROM_BILL",
      "SET_DEFAULT_TYPE_FINANCIAL",
      "SET_IS_SEARCH_BILLS",
    ]),

    activeTable(event) {
      const radioButton = event.currentTarget;
      const value = radioButton.value;

      if (value === "toPay") {
        this.kind = "saida";
        this.type = "nomeFantasia";
        this.filterSalePerMonth();
      } else {
        this.kind = "entrada";
        this.type = "nomeCliente";
        this.filterSalePerMonth();
      }

      this.tableActive = {
        toPay: false,
        toReceive: false,
        [value]: true,
      };
    },

    filterPerType(event) {
      const button = event.currentTarget;
      let name = button.name;
      this.page = 1;
      if (name === "vencidas") {
        this.status = name;
        this.textPesquisa = "";
        this.filterSalePerMonth();
      } else if (name === "vencer") {
        this.textPesquisa = "";
        this.status = name;
        this.filterSalePerMonth();
      } else if (name === "vencem") {
        this.textPesquisa = "";
        this.status = name;
        this.filterSalePerMonth();
      } else if (name === "pago") {
        this.status = "";
        this.type = "situacao";
        this.textPesquisa = name;
        this.filterSalePerMonth(
          this.page,
          this.status,
          this.type,
          this.textPesquisa
        );
      }
    },

    async modalHeight() {
      this.SET_COLAPSE_FINANCIAL(!this.colapseFinancial);

      this.colapseFinancial
        ? this.ALTER_SIZE_MODAL_FINANCIAL("ModalGeneral")
        : this.ALTER_SIZE_MODAL_FINANCIAL("disable");

      this.modalContainerFinancial
        ? this.ALTER_MODAL_CONTAINER_FINANCIAL("modalContainer")
        : this.ALTER_MODAL_CONTAINER_FINANCIAL("contentModalDisable");

      if (!this.colapseFinancial && this.sizeModalFinancial === "disable") {
        this.SET_DATA_SEARCH_FROM_BILL({});
      }

      await this.filterSalePerMonth();
    },

    async handleModals(name) {
      this.ALTER_TYPE_FINANCIAL(name);

      this.modalHeight();
    },

    monthHasChanged() {
      this.month = this.month.set({ month: parseInt(this.monthSelected) });
      this.year = this.yearSelected;
      this.filterSalePerMonth();
    },

    async filterSalePerMonth(restartPage = false) {
      try {
        if (restartPage) this.page = 1;

        this.spinSummary = true;
        const { data } = await api.get(
          `/bills/search/for/accounts/by/month/to/display/in/the/general/table/paginated/thirteen/parameters/?month=${
            this.month.month
          }&year=${this.year}&q=${encodeURIComponent(this.textPesquisa)}&type=${
            this.type
          }&kind=${this.kind}&status=${this.status}&page=${
            this.page
          }&isAdvancedSearch=${this.isAdvancedSearch}&startDate=${
            this.startDate
          }&endDate=${this.endDate}&idCategoryBill=${this.idCategoryBill}`
        );
        const financer = data?.data ?? [];

        if (this.kind === "entrada") {
          this.receiveFilter = financer.bills;
          this.dataLength = financer.bills.length;
        }
        if (this.kind === "saida") {
          this.payFilter = financer.bills;
          this.dataLength = financer.bills.length;
          if (this.dataLength < 9 && this.page === 1) {
            this.textPesquisa = "";
          }
        }
        if (this.userLevel === "adm") {
          this.payableSummary.totalPay = this.getTotalFinancer(
            financer.toPayable
          );
          this.receivedSummary.totalReceive = this.getTotalFinancer(
            financer.toReceive
          );
          this.receivedSummary.monthTotal = this.getMonthTotal(
            financer.toReceive
          );
          this.receivedSummary.totalPaid = financer.totalReceived;
          this.receivedSummary.sumMatury = financer.sumReceiveTotals.vencidas;
          this.receivedSummary.expireToday = financer.sumReceiveTotals.vencem;
          this.receivedSummary.toWin = financer.sumReceiveTotals.vencer;

          this.payableSummary.totalPay = this.getTotalFinancer(
            financer.toPayable
          );
          this.payableSummary.monthTotal = this.getMonthTotal(
            financer.toPayable
          );
          this.payableSummary.totalPaid = financer.totalReceived;
          this.payableSummary.sumMatury = financer.sumPayableTotals.vencidas;
          this.payableSummary.expireToday = financer.sumPayableTotals.vencem;
          this.payableSummary.toWin = financer.sumPayableTotals.vencer;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.spinSummary = false;
      }
    },

    checkUserLevel() {
      this.userLevel = localStorage.getItem("userLevel");
    },

    async enableAdvancedSearch() {
      this.isAdvancedSearch = !this.isAdvancedSearch;
      if (!this.isAdvancedSearch) {
        this.startDate = "";
        this.endDate = "";
      }
    },

    getTotalFinancer(financer) {
      return financer.reduce((total, fin) => total + fin.valorRestante, 0);
    },

    getMonthTotal(financer) {
      return financer.reduce((total, fin) => total + fin.valorTotal, 0);
    },

    getTotalPaid(financer) {
      return financer.reduce(
        (total, fin) => total + fin.valorTotalRegistros,
        0
      );
    },

    async getAllCategoryBill() {
      const result = await serviceCategoryBill.fillComobobox();
      this.allCategoryBills = result;
    },

    async updateMonth(direction) {
      if (direction === "prev") {
        this.month = this.month.minus({ months: 1 });
        this.year = this.yearSelected;
        this.monthSelected = this.month.month;
      } else if (direction === "next") {
        this.month = this.month.plus({ months: 1 });
        this.year = this.yearSelected;
        this.monthSelected = this.month.month;
      }

      await this.filterSalePerMonth();
    },
    nextPage() {
      this.page += 1;
      this.filterSalePerMonth();
    },

    previousPage() {
      if (this.page === 1) {
        return;
      } else {
        this.page -= 1;
        this.filterSalePerMonth();
      }
    },
    clearSearch() {
      this.textPesquisa = "";
      this.kind === "entrada"
        ? (this.type = "nomeCliente")
        : (this.type = "nomeFantasia");
      this.status = "";
      this.startDate = "";
      this.endDate = "";
      this.page = 1;
      this.idCategoryBill = "";
    },

    seeAll() {
      this.clearSearch();
      this.filterSalePerMonth();
    },
  },
  computed: {
    ...mapState({
      sizeModalFinancial: (state) => state.sizeModalFinancial,
      enableModalsFinancial: (state) => state.enableModalsFinancial,
      colapseFinancial: (state) => state.colapseFinancial,
      modalContainerFinancial: (state) => state.modalContainerFinancial,
      isSearchBills: (state) => state.isSearchBills,
      dataSearchFromBill: (state) => state.dataSearchFromBill,
    }),
    monthName() {
      return this.month.toLocaleString({ month: "long", locale: "pt-BR" });
    },
  },
  async mounted() {
    this.monthSelected = this.month.month;
    this.yearSelected = this.year;
    await this.filterSalePerMonth();
    this.getAllCategoryBill();
  },
  async created() {
    this.checkUserLevel();
    if (
      this.dataSearchFromBill.idFornecedor &&
      this.dataSearchFromBill.valorTotal
    ) {
      this.handleModals("toPay");
    } else if (
      this.dataSearchFromBill.idCliente &&
      this.dataSearchFromBill.valorTotal
    ) {
      this.handleModals("toReceive");
    }
  },

  beforeDestroy() {
    this.SET_DEFAULT_TYPE_FINANCIAL();
    this.SET_COLAPSE_FINANCIAL(false);
    this.ALTER_SIZE_MODAL_FINANCIAL("disable");
    this.ALTER_MODAL_CONTAINER_FINANCIAL("contentModalDisable");
    this.SET_DATA_SEARCH_FROM_BILL({});
  },

  watch: {
    isSearchBills() {
      this.filterSalePerMonth();
    },
    type() {
      if (this.type === "nomeCliente" || this.type === " nomeFantasia")
        this.textPesquisa = "";
    },
  },
};
</script>
<style scoped>
.Financial {
  display: flex;
  flex-direction: column;
  margin-left: 90px;
  width: calc(100% - 100px);
  height: 100vh;
  font-size: 13px;
}

.ContainerBtnFinancial {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 10px;
  margin-top: 70px;
  gap: 30px;
}

.BtnFinancial {
  width: 27%;
  border: none;
  height: 35px;
  border-radius: 3px;
  font-size: 13px;
  box-shadow: 0px 11px 4px -10px rgba(142, 142, 142, 0.78);
}
.BtnFinancial:hover {
  box-shadow: 0px 11px 4px -10px rgba(142, 142, 142, 0.78);
}

.SumFinancial {
  display: flex;
  width: 100%;
  gap: 30px;
  padding: 0 0 10px 0;
  border-bottom: 1px solid rgb(227, 227, 227);
  margin: 5px 0 0 10px;
  justify-content: flex-start;
  align-items: center;
}

.ItemSumFinancial {
  width: 27%;
  height: 55px;
  border-radius: 5px;
  font-size: 12px !important;
  color: rgb(88, 88, 88);
  gap: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  box-shadow: 0px 11px 4px -10px rgba(142, 142, 142, 0.78);
}

.ItemSumFinancial span {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.ItemSumFinancial:hover {
  background: rgb(252, 252, 252);
}

.ItemSumFinancial:focus {
  background: rgb(249, 249, 249);
  border: none;
}

.lineSearch {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.searchByMonth {
  width: 27%;
  display: flex;
  align-items: center;
  margin: 10px 0px 3px 0px;
  border-radius: 5px;
  font-size: 11px;
  justify-content: space-between;
  border: 1px solid #e3e0e0;
}

.pagMonth {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.monthBtn {
  font-size: 11px;
  text-transform: capitalize;
  font-weight: bold;
  padding: 5px;
  cursor: context-menu;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.Arrows {
  border-radius: 5px;
  width: 25px;
  height: 20px;
}

.Arrows:hover {
  background: whitesmoke;
}

.radio-input {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  font-size: 12px;
  color: rgb(88, 88, 88);
  display: flex;
  width: 100%;
  gap: 30px;
  border-bottom: 1px solid rgb(227, 227, 227);
  justify-content: flex-start;
  align-items: center;
}

.radio-input input[type="radio"] {
  display: none;
}

.radio-input label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 3px;
  height: 55px;
  width: 27%;
  box-shadow: 0px 11px 4px -10px rgba(142, 142, 142, 0.78);
  background-color: whitesmoke;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.radio-input label:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #ccc;
  transition: all 0.3s ease-in-out;
}

.radio-input input[type="radio"]:checked + label {
  animation: radio-translate 0.5s ease-in-out;
}

.radio-input input[type="radio"]:checked + label:before {
  background-color: transparent;
  top: 0;
}

.radio-input input[type="radio"].radio-receive:checked + label:before {
  background-color: #004727;
  border-color: #ccc;
}

.radio-input input[type="radio"].radio-pay:checked + label:before {
  background-color: #690000;
  border-color: #ccc;
}

@keyframes radio-translate {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateX(0);
  }
}

.ContainerSearchInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgb(255 0 0 / 3%) 1%,
    rgb(255 255 255 / 99%) 19%
  );
  border-radius: 5px;
  padding-left: 2%;
}

.cash {
  width: 30px;
  height: 30px;
}

.cashR {
  width: 35px;
  height: 35px;
}

.textPay {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #690000;
  font-size: 15px;
  font-family: Poppins-Regular, sans-serif !important;
}

.textReceive {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #004727;
  font-size: 15px;
  font-family: Poppins-Regular, sans-serif !important;
}

.searchContainer {
  display: flex;
  gap: 10px;
  width: 80%;
  justify-content: flex-end;
  align-items: center;
}

.searchAdvancedContainer {
  gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid rgb(206, 206, 206);
  background: #ebebeb;
  border-radius: 2px;
  padding: 10px;
}

.searchInput {
  min-width: 45%;
  display: flex;
  padding: 3px 3px 3px 0;
  align-items: center;
  justify-content: flex-end;
}

.searchAvancedContent {
  min-width: 40%;
  display: flex;
  padding: 3px 0px 3px 0;
  align-items: center;
  justify-content: flex-end;
}

.searchFilter {
  width: 150px;
  border-right: none;
  height: 30px;
  font-size: 11px;
  border-radius: 5px 0px 0px 5px;
  text-align: center;
  font-family: Poppins-Regular, sans-serif !important;
}

.searchMonth {
  width: 100px;
  height: 30px;
  border-radius: 5px 0px 0px 5px;
  font-size: 11px;
  text-align: center;
  font-family: Poppins-Regular, sans-serif !important;
}

.searchMonth:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

.searchYear {
  width: 50px;
  height: 30px;
  border-radius: 0px 5px 5px 0px;
  font-size: 11px;
  text-align: center;
  font-family: Poppins-Regular, sans-serif !important;
}

.searchYear:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

.custom-select3 {
  left: 4px;
  border: 1px solid rgb(195, 193, 193);
  -moz-appearance: none;
  appearance: none;
  background: none;
  font-weight: bold;
  padding: 0 !important;
}

.searchFilter:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

.custom-select {
  left: 4px;
  position: relative;
  font-weight: 600;
  border: 1px solid rgb(195, 193, 193);
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}
.searchFilterSituation {
  width: 100%;
  border-left: none !important;
  height: 30px;
  border-radius: 0px !important;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  position: relative !important;
  font-family: Poppins-Regular, sans-serif !important;
}

.searchFilterSituation:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

.form-control-sm {
  padding: 0.25rem 1.5rem;
  height: 30px;
}

.custom-select2 {
  left: 4px;
  position: relative;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid rgb(195, 193, 193);
  -moz-appearance: none;
  appearance: none;
  background: none;
  font-weight: bold;
}

.btnPeriod {
  border-radius: 5px 0px 0px 5px;
  border: 1px solid rgb(195, 193, 193);
  width: 100px;
  height: 30px;
  font-size: 11px;
  cursor: context-menu;
}

.dateInput {
  display: flex;
  gap: 10px;
  width: 250px;
}

input {
  border: 1px solid rgb(195, 193, 193);
  border-radius: 5px;
  height: 30px;
  font-size: 11px;
}

.searchBtn {
  right: 10px;
  height: 30px;
  position: relative;
  background: whitesmoke;
  color: black;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  border-color: #c3c1c1;
}

.searchAll {
  justify-content: center;
  color: #3a4750;
  font-size: 13px;
  width: 80px;
  height: 30px;
  font-weight: bold;
  border: solid 1px #e3e0e0;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchAll:hover {
  background: rgb(205, 205, 205);
}

.searchAvanced {
  justify-content: center;
  color: #3a4750;
  font-size: 12px;
  height: 30px;
  gap: 10px;
  padding: 0px 20px;
  border: solid 1px #e3e0e0;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchAvanced:hover {
  background: rgb(205, 205, 205);
}

input:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

input::placeholder {
  font-size: 11px;
}

button:focus {
  box-shadow: none;
}

.setBtn {
  display: flex;
  flex-direction: row;
  margin: 0 1rem 1rem 0;
  gap: 10px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.itemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #3a4750;
}

.itemBtn:hover {
  background: #3a4750;
  color: whitesmoke;
}

.itemBtn:disabled {
  opacity: 0;
}

.centerItemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  cursor: auto;
}

.SumStateFinancial {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 3px 0 0 0;
  padding: 0 0 0 10px;
}

.contentDivider {
  padding: 8px 0px;
}

.divider {
  width: 100%;
  border-bottom: solid 1px #6663605a;
}

.ItemSumStateFinancial {
  width: 19.8%;
  height: 47px;
  border: 1px solid #d9d9d9;
  border-left: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ItemSumStateFinancial p {
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.ItemSumStateFinancial:hover {
  background: rgb(249, 249, 249);
  border-bottom: 3px solid #c2c5c7 !important;
}

.ItemSumStateFinancial:focus {
  background: rgb(249, 249, 249);
  border-bottom: 3px solid #818485 !important;
}
.ModalGeneral {
  position: fixed !important;
  z-index: 900;
  top: 53px;
  left: 61px;
  height: calc(100% - 53px);
  max-height: calc(100% - 53px);
  overflow: auto;
  width: calc(100% - 62px);
  background: #ffff;
  transition: all 0.7s ease-in-out;
}

.disable {
  position: absolute;
  width: calc(100% - 62px);
  height: 0;
  left: 61px;
  top: 100vh;
  background: #b0b0b000;
  visibility: hidden;
  transition: all 0.7s ease-in-out;
}

.closeModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  z-index: 900;
  font-size: 18px;
  height: 30px;
  background: transparent;
  border: none;
  color: rgb(6, 6, 6);
}

.closeModal:hover {
  background: whitesmoke;
  color: red;
}

.closeModal:focus {
  background: red;
  border: none;
  box-shadow: none;
}

.contentModalDisable {
  display: none;
  background: white;
  opacity: 0;
}

.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.summaryMonth {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  cursor: context-menu;
  padding: 0 0 0 7px;
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .ContainerSearchInput {
    padding-left: 0px;
  }
  .textReceive {
    font-size: 12px;
  }
  .searchContainer {
    gap: 2px;
    width: 83%;
  }
  .searchAll {
    display: none;
  }
  .searchFilter {
    width: 91px;
    height: 28px;
  }
  .searchInput {
    margin: 0px;
    padding: 3px 3px 3px 0;
  }
  .dateInput {
    gap: 3px;
  }
  input {
    height: 28px;
    font-size: 11px;
  }
  .btnPeriod {
    width: 70px;
    height: 28px;
    font-size: 11px;
  }
  .searchBtn {
    height: 28px;
  }
  .SumFinancial {
    margin: 0px;
    padding: 0px;
  }
  .ItemSumFinancial {
    height: 40px;
    align-items: center;
    justify-content: center;
  }
  .setBtn {
    margin: 0 1rem 10px 0;
  }
  .itemBtn {
    width: 18px;
    height: 18px;
  }
  .centerItemBtn {
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 700px) {
  .ContainerSearchInput {
    padding-left: 1%;
    flex-direction: column;
    align-items: flex-start;
  }

  .searchContainer {
    display: flex;
    gap: 5px;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .btnPeriod {
    width: 100px;
    height: 30px;
    font-size: 11px;
  }

  .searchInput {
    min-width: 40%;
    width: 100%;
  }

  .dateInput {
    gap: 20px;
  }

  .searchAll {
    font-size: 13px;
    width: 150px;
    height: 30px;
  }

  .ItemSumFinancial {
    width: 19.8%;
    height: 60px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .ModalGeneral {
    width: calc(100% - 50px);
    left: 50px;
    top: 50px;
    overflow: none !important;
  }
  .closeModal {
    right: 0px;
  }
  .Financial {
    margin-left: 55px;
    padding: 0 0.5rem;
    width: calc(100% - 60px);
    font-size: 11px;
  }
  .ContainerBtnFinancial {
    margin-left: 5px;
    margin-top: 65px;
  }
  .BtnFinancial {
    width: 34%;
    height: 30px;
    font-size: 11px;
  }
  .SumFinancial {
    margin: 5px 0 0 5px;
    padding: 0 0 5px 0;
  }
  .lineSearch {
    margin-left: 5px;
  }
  .searchByMonth {
    width: 34%;
    font-size: 10px;
  }
  .ItemSumFinancial {
    width: 34%;
    height: 50px;
    gap: 0px;
    font-size: 11px !important;
  }
}

@media screen and (max-width: 700px) {
  .Financial {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    width: 100%;
    height: 100vh;
    font-size: 12px;
  }

  .ModalGeneral {
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(100vh + 5vh);
    max-height: calc(100vh + 5vh);
  }

  .closeModal {
    top: 1rem;
    right: 5px;
    width: 30px;
    background: whitesmoke;
    color: red;
  }

  .ContainerBtnFinancial {
    margin-top: 55px;
  }

  .lineSearch {
    margin-left: 0px;
    justify-content: center;
  }

  .searchByMonth {
    width: 80%;
    margin: 10px 0px 0px 0px;
  }

  .BtnFinancial {
    width: 44%;
    height: 33px;
    font-size: 12px;
  }

  .ItemSumFinancial {
    width: 44%;
    height: 50px;
    font-size: 11px !important;
    gap: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .radio-input {
    font-family: Poppins-Regular, sans-serif !important;
    font-weight: 600;
    font-size: 12px;
    color: rgb(88, 88, 88);
    display: flex;
    width: 100%;
    gap: 30px;
    border-bottom: 1px solid rgb(227, 227, 227);
    justify-content: flex-start;
    align-items: center;
    justify-content: space-around;
  }

  .ItemSumStateFinancial {
    width: 19.8%;
    height: 47px;
    border: 1px solid #d9d9d9;
    border-left: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
  }
}
</style>