<template>
  <div class="containerGeralBudget">
    <div>
      <b-row
        class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="align-items: center; justify-content: space-between"
      >
        <div class="titleHeader">
          <p class="title">
            NOVO ORÇAMENTO Nº
            {{ numberBudget || dataBudgetOfTheBudgetModal.number }}
          </p>
        </div>

        <div
          style="
            margin-right: 16px;
            display: flex;
            align-items: center;
            gap: 10px;
          "
        >
          <label
            :style="`color: ${alterColorSituation}`"
            class="title m-0 p-0"
            >{{ getNameSituantionById(dataBudget.idSituacao) }}</label
          >
        </div>
      </b-row>

      <b-row class="mt-3 col-sm-12 col-md-12 col-lg-12 col-xl-12 lineDates">
        <Input
          style="width: 21%; padding-right: 10px"
          ref="data"
          label="Data"
          :labelFixed="true"
          type="date"
          :dataInput.sync="dataBudget.data"
        />

        <Input
          style="width: 21%; padding-right: 10px"
          ref="dataVencimento"
          label="Data Vencimento"
          :labelFixed="true"
          type="date"
          :dataInput.sync="dataBudget.dataVencimento"
        />

        <Input
          style="width: 21%; padding-right: 10px"
          ref="dataEntrega"
          label="Data De Entrega"
          :labelFixed="true"
          type="date"
          :dataInput.sync="dataBudget.dataEntrega"
        />
      </b-row>
      <b-row class="mt-3 col-sm-12 col-md-12 col-lg-12 col-xl-12 lineClient">
        <InputInfinit
          style="width: 60%"
          label="Cliente"
          :isEdit="dataBudget.idCliente"
          :dataInput.sync="dataBudget.idCliente"
          :onSearch="fillCustomerComboBoxWithPaginate"
          :openCreateModal="() => openModals('modalCreateCustomer')"
          :findAll="createdNewCustomer"
          @selectItem="handleDataCustomers($event)"
        />

        <InputSelect
          style="width: 35%"
          label="Funcionário"
          :items="dataEmployee"
          valueField="id"
          textField="nomeFuncionario"
          :dataSelect.sync="dataBudget.idFuncionario"
          :openCreateModal="() => openModals('modalCreateEmployee')"
          ref="refResponsavel"
        />
      </b-row>
    </div>

    <hr />

    <div class="contentItens">
      <div class="responsiveItems">
        <div class="containerItens">
          <b-row
            class="col-sm-12 col-md-12 col-lg-12 col-xl-12 contentTitleItens"
          >
            <div class="titleBudgetProducts">
              <p class="title">Informações dos Itens</p>
            </div>
          </b-row>

          <div
            style="
              width: 100%;
              display: flex;
              justify-content: space-between;
              gap: 1rem;
              margin-top: 2.5rem;
            "
          >
            <div style="width: 100%">
              <b-row class="itemsBudget" style="align-items: normal">
                <div
                  v-if="lineProduct.tipoProduto === 'normal'"
                  class="containerInputItem"
                  size="sm"
                >
                  <div
                    style="
                      position: absolute;
                      top: -23px;
                      right: 0;
                      z-index: 0;
                      display: flex;
                      gap: 0.5rem;
                    "
                  >
                    <div
                      v-if="lineProduct.idProduto"
                      style="display: flex; align-items: center; gap: 0.5rem"
                    >
                      <div
                        style="
                          position: relative;
                          position: relative;
                          width: 130px;
                          height: 100%;
                        "
                      >
                        <div class="radio-input">
                          <label>
                            <input
                              type="radio"
                              name="value-radio"
                              value="P1"
                              v-model="lineProduct.tipoPreco"
                              @change="updateProductValue('P1')"
                            />
                            <span>VL . 1</span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="value-radio"
                              value="P2"
                              v-model="lineProduct.tipoPreco"
                              @change="updateProductValue('P2')"
                            />
                            <span>VL . 2</span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="value-radio"
                              value="P3"
                              v-model="lineProduct.tipoPreco"
                              @change="updateProductValue('P3')"
                            />
                            <span>VL . 3</span>
                          </label>
                          <span class="selection"></span>
                        </div>
                      </div>

                      <b-icon-bookmarks-fill
                        v-if="!selectedItemIdToReplace"
                        tag="span"
                        class="btnReplaceAll"
                        @click="handleItemSelected(lineProduct.idProduto)"
                        v-b-tooltip.hover.top="'Substituir todos!'"
                        ref="replaceAll"
                      />

                      <div
                        v-else-if="selectedItemIdToReplace"
                        style="display: flex; gap: 0.5rem"
                      >
                        <b-icon-check-square-fill
                          @click="applyReplaceAll"
                          scale="1.2"
                          style="background-color: green"
                          class="btnReplaceAll"
                          v-b-tooltip.hover.top="'Confirmar Substituição!'"
                        />

                        <b-icon-x-square-fill
                          @click="cancelReplaceAll"
                          scale="1.2"
                          style="background-color: red"
                          class="btnReplaceAll"
                          v-b-tooltip.hover.top="'Cancelar Substituição!'"
                        />
                      </div>
                    </div>

                    <button
                      class="btnsComposition"
                      @click="
                        handleShowCompositionItem(
                          lineProduct.idProduto,
                          lineProduct.composicao
                        )
                      "
                    >
                      Composições
                    </button>
                  </div>

                  <div class="inputItem" size="sm">
                    <div class="infinite" style="padding-right: 18px">
                      <InputInfinit
                        label="Itens"
                        :isEdit="lineProduct.id"
                        :dataInput.sync="lineProduct.idProduto"
                        :onSearch="fillProductsComboBoxWithPaginate"
                        :openCreateModal="
                          () =>
                            handleModalCreateProduct(
                              'modalCreateProduct',
                              lineProduct.idProduto
                            )
                        "
                        :findAll="createdNewProducts"
                        :clearSelected="toggleClearSelect"
                        :searchFields="['nome', 'codRef']"
                        @selectItem="selectProductSetDefaultValue"
                      />
                    </div>
                  </div>
                </div>

                <div class="inputItemQnt">
                  <InputQuant
                    label="Qtd."
                    :labelFixed="true"
                    ref="refQtd"
                    type="text"
                    :dataInput.sync="lineProduct.quantidade"
                    @calculateTotal="handleCalculateTotal"
                    :decimalPlaces="decimalPlaces"
                    isSelectAll
                  />
                </div>

                <div class="inputItemVlUnit">
                  <InputMoney
                    label="Vl. Unit"
                    labelFixed
                    ref="refVlUnit"
                    type="text"
                    :dataInput.sync="lineProduct.valorUnitario"
                    @calculateTotal="handleCalculateTotal"
                    isSelectAll
                  />
                </div>

                <div class="inputItemVlTot">
                  <InputMoney
                    label="Vl. Total"
                    labelFixed
                    ref="valorTotal"
                    type="text"
                    isDisabled
                    :dataInput.sync="lineProduct.valorTotal"
                    @calculateTotal="handleCalculateTotal"
                  />
                </div>

                <div class="inputItemDesc">
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      position: relative;
                    "
                  >
                    <InputMoney
                      v-if="!discountIsCheked"
                      label="Desc"
                      :labelFixed="true"
                      type="text"
                      :dataInput.sync="lineProduct.desconto"
                      ref="discount"
                      @calculateTotal="handleCalculateTotal"
                      isSelectAll
                    />

                    <InputPercentage
                      v-else
                      label="Desc"
                      :labelFixed="true"
                      type="text"
                      :dataInput.sync="lineProduct.desconto"
                      ref="discount"
                      :isDisabled="discountToAllItems"
                      @calculateTotal="handleCalculateTotal"
                      isSelectAll
                    />
                  </div>
                </div>

                <div class="itemToggleDesc" style="width: 52px; height: 30px">
                  <label class="switch col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <input
                      type="checkbox"
                      ref="typeDiscount"
                      @click="alterDiscountIsCheked"
                      v-model="discountIsCheked"
                      :disabled="validatorDesc"
                    />
                    <div class="slider">
                      <span>R$</span>
                      <span>%</span>
                    </div>
                  </label>

                  <div
                    style="display: none"
                    ref="enableLabelApplyAll"
                    class="tooltipDiscount"
                  >
                    <div class="tooltipItems">
                      <div>
                        <label for="apllyAll">Aplicar em todos</label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="apllyAll"
                          v-model="discountToAllItems"
                          @change="alterAllChecksDiscount"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="inputItemObs">
                  <Input
                    label="Observação"
                    :labelFixed="true"
                    type="text"
                    :dataInput.sync="lineProduct.dadosAdicionais"
                    ref="dadosAdicionais"
                  />
                </div>

                <div
                  class="btnAddMoreItems"
                  :style="productLoading && 'align-items: flex-end'"
                >
                  <button
                    v-if="!productLoading"
                    style="width: 100px; margin-top: 0.5rem"
                    class="btnsBudget btnAddMoreItems"
                    @click="handleAddMoreItens"
                  >
                    {{ lineProduct.id ? "Atualizar" : "Adicionar" }}
                  </button>

                  <div
                    v-else
                    style="
                      width: 30px;
                      height: 30px;
                      background-color: #00395d;
                      border-radius: 50%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <b-spinner class="spinProduct" />
                  </div>
                </div>
              </b-row>
            </div>
          </div>

          <section class="containerTableItens">
            <div class="contentTableItens">
              <table
                class="tableProducts"
                style="width: 100%"
                v-if="tableProducts.length"
              >
                <thead>
                  <tr>
                    <th style="width: 200px">Nome</th>
                    <th style="width: 60px">Qnt</th>
                    <th style="width: 120px">Vl. Unit</th>
                    <th style="width: 99px">Desc</th>
                    <th style="width: 120px">Vl. Total</th>
                    <th style="width: 40px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="tableItem"
                    v-for="(product, idx) in tableProducts"
                    :key="product.id"
                    @click="editProductsOfSale(product)"
                  >
                    <td style="width: 200px">
                      <div
                        style="display: flex; gap: 0.5rem; align-items: center"
                      >
                        <span class="contentNumberItem">{{ idx + 1 }}</span>

                        <div style="width: calc(100% - 20px)">
                          <p class="textEllipsis" style="margin: 0">
                            {{ product.nome }}
                          </p>

                          <p
                            class="textEllipsis"
                            style="margin: 0; font-size: 9px; color: gray"
                          >
                            <span
                              v-b-tooltip.hover.top="product.dadosAdicionais"
                            >
                              {{ product.dadosAdicionais }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </td>
                    <td style="width: 60px">{{ product.quantidade }}</td>
                    <td style="width: 120px">
                      {{ maskCurrency(product.valorUnitario) }}
                    </td>
                    <td style="width: 100px">
                      {{
                        dataBudget.tipoDesconto === "Porcentagem"
                          ? maskPercentage(product.desconto)
                          : maskCurrency(product.desconto)
                      }}
                    </td>
                    <td style="width: 115px">
                      <p class="textEllipsis" style="margin: 0">
                        {{ handleCalculateTotalInTable(product) }}
                      </p>
                    </td>
                    <td style="width: 40px; position: relative; padding: 0">
                      <span
                        :class="`contentRemoveItem ${
                          lineProduct.id === product.id && 'blockRemove'
                        }`"
                        @click.stop="removeItens(product.id)"
                        v-b-tooltip.hover.top="'Excluir'"
                        ><b-icon-trash style="color: white" scale="1.5"
                      /></span>
                      <div class="contentCloneItem">
                        <span
                          v-b-tooltip.hover.top="'Clonar'"
                          @click.stop="handleDuplicate(product)"
                          style="
                            width: 25px;
                            height: 25px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <b-icon-back style="color: #a8a8a8" scale="1.5" />
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div v-else class="contentNotItems">
                <p>Nenhum produto adicionado...</p>
              </div>
            </div>
          </section>
        </div>
      </div>

      <hr />

      <b-row class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="contentDataTotals col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="contentTotals">
            <span class="inputTotals">
              <p>Total de Itens (R$)</p>
              <input disabled type="text" v-model="totalOfItens" />
            </span>

            <span class="inputTotals">
              <p>Desconto (R$)</p>
              <input disabled type="text" v-model="totalOfDiscount" />
            </span>

            <span class="dashed"></span>

            <span class="inputTotals contentFreteTotals">
              <p>Frete (R$)</p>
              <input
                style="color: #5c5855"
                type="text"
                :value="maskCurrency(dataBudget.frete)"
                @input="handleFreteMonetaryValue($event)"
              />
            </span>

            <span class="inputTotals contentFreteTotals">
              <p>Outros (R$)</p>
              <input
                style="color: #5c5855"
                type="text"
                :value="maskCurrency(dataBudget.outrosValores)"
                @input="handleOtherValuesMonetaryValue($event)"
              />
            </span>

            <span class="inputTotals">
              <p>Total do Orçamento (R$)</p>
              <input disabled type="text" v-model="totalValue" />
            </span>
          </div>
        </div>
      </b-row>

      <hr />

      <b-row class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div style="display: flex; gap: 3px; align-items: center">
            <p class="obsGeneral">Observações Gerais</p>

            <p class="observacoes">
              (Insira aqui, observações que serão importantes para você)
            </p>
          </div>

          <textarea
            class="inputTextArea"
            rows="3"
            max-rows="6"
            v-model="dataBudget.dadosAdicionais"
          />
        </div>

        <div class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div style="display: flex; gap: 3px; align-items: center">
            <p class="obsGeneral">Observações de Nota Fiscal</p>

            <p class="observacoes">
              (Insira aqui, observações que sairão na Nota Fiscal do cliente)
            </p>
          </div>

          <textarea
            class="inputTextArea"
            rows="3"
            max-rows="6"
            v-model="dataBudget.observacoesNf"
          />
        </div>
      </b-row>

      <b-row class="mb-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div
          class="col-sm-12 col-md-12 col-lg-12 col-xl-12 contentButtonsActions"
        >
          <div>
            <button
              v-if="
                dataBudget.id &&
                dataBudget.idSituacao !==
                  getSpecificSituantionId(saleTypeSituation.cancelado)
              "
              style="width: 175px"
              class="btnsBudget btnCancelBudget"
              @click="cancelBudget"
            >
              Cancelar Orçamento
            </button>

            <button
              class="btnsBudget btnReopenBudget"
              v-if="
                dataBudget.id &&
                dataBudget.idSituacao ===
                  getSpecificSituantionId(saleTypeSituation.cancelado)
              "
              @click="reOpenBudget"
            >
              Reabrir Orçamento
            </button>
          </div>

          <div style="display: flex; gap: 10px">
            <div>
              <button
                style="width: 175px"
                class="btnsBudget btnConfirm"
                @click="handleDataToSendModalBudget"
              >
                Finalizar
              </button>
            </div>
          </div>
        </div>
      </b-row>
    </div>
    <ModalBudget
      :dataBudget="dataBudget"
      :dataBudgetForModalBudget="dataBudgetForModalBudget"
      :totals="objectTotal"
      :numberBudget="numberBudget"
      @budgetSaved="dataBudgetOfTheBudgetModal = $event"
      @clearAllBudget="clearAllBudget = $event"
    />
    <ModalCreateCustomer
      @getAllNewCustomer="handleCreatedNewCustomer"
      :idCustomerFromSearch="dataBudget.idCliente"
    />
    <ModalCreateEmployees
      @getAllNewEmployee="createdNewEmployee = $event"
      :idEmployeeFromSearch="dataBudget.idFuncionario"
    />
    <ModalCreateProducts
      @getAllNewProduct="handleCreatedNewProduct"
      :idProductFromSearch="idProductFromSearch"
    />
    <ModalComposition
      :compositions="compositionItems"
      :idProduct="lineProduct.idProduto"
      :forceUpdateSelect="toggleToUpdate"
      @findCompositions="handleItemFromModalComposition($event)"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import api from "../../services/axios";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import ModalCreateCustomer from "../Modals/ModalCreateCustomer.vue";
import ModalCreateEmployees from "../Modals/ModalCreateEmployees.vue";
import ModalCreateProducts from "../Modals/ModalCreateProduct.vue";
import ModalBudget from "./Modal-Budget.vue";
import ModalComposition from "../Sale/Modal-Composition.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import Input from "../UI/CustomInputs/Input.vue";
import InputQuant from "../UI/CustomInputs/InputQuant.vue";
import InputMoney from "../UI/CustomInputs/InputMoney.vue";
import InputPercentage from "../UI/CustomInputs/InputPercentage.vue";
import InputInfinit from "../../components/UI/CustomInputs/InputInfinit.vue";

import ServiceCustomer from "../../services/serviceCustomer";
import ServiceProducts from "../../services/serviceProducts";
import ServiceProductSale from "../../services/serviceProductSale";
import ServiceSituation from "../../services/serviceSituation";
import ServiceSale from "../../services/serviceSale";

import { ValidadeProductOfSale } from "../../validators/productOfSale";

import { INITIAL_STATE_PRODUCTS } from "../../initialStates/sale";
import { SaleTypeSituation } from "../../utils/enums/SalesSituationType";
import {
  mascaraMoeda,
  maskCurrency,
  maskPercentage,
} from "../../utils/maskMoney";
import toastAlertErros from "../../utils/toastAlertErros";
import { removeReference } from "../../utils/removeReference";

export default {
  components: {
    ModalBudget,
    InputSelect,
    Input,
    InputMoney,
    InputPercentage,
    InputInfinit,
    InputQuant,
    ModalCreateCustomer,
    ModalCreateEmployees,
    ModalCreateProducts,
    ModalComposition,
  },
  data() {
    return {
      dataBudget: {
        id: "",
        idCliente: "",
        idFuncionario: "",
        idSituacao: "",
        data: moment().format("YYYY-MM-DD"),
        dataVencimento: null,
        dataEntrega: null,
        dadosAdicionais: "",
        observacoesNf: "",
        status: "Orçamento",
        tipo: "Orçamento", //Venda, Frente de caixa, Venda MTQ
        frete: 0,
        outrosValores: 0,
        tipoDesconto: "Valor Monetário", // ou Porcentagem
      },
      lineProduct: {
        id: "",
        idVenda: "",
        idFornecedor: "",
        idProduto: "",
        quantidade: 1,
        valorUnitario: 0,
        valorTotal: 0,
        desconto: 0,
        dadosAdicionais: "",
        idProdutoComposicao: "",
        tipoProduto: "normal", // ou composicao
        composicao: [],
        tipoPreco: "P1",
      },
      dataCustomers: [],
      dataEmployee: [],
      products: [],
      providers: [],
      comissao: "",
      productUnitaryValue: "",
      totalParcelas: 1,
      intervaloDias: "",
      allPaymentsTypes: [],
      allSituations: [],
      total: "",
      totalOfItens: "R$ 0,00",
      totalOfDiscount: "R$ 0,00",
      additionalValues: "R$ 0,00",
      totalValue: "R$ 0,00",
      discountToAllItems: false,
      discountToIndividuallyItem: false,
      discountIsCheked: false,
      dataBudgetForModalBudget: {
        customerSelected: {
          nome: "",
        },
        employeeSelected: {
          nomeFuncionario: "",
        },
        formattedProductsBudget: [],
        data: "",
      },
      contaRecebimento: "",
      paymentSelected: "",
      dataBudgetOfTheBudgetModal: {},
      numberBudget: "",
      saleTypeSituation: SaleTypeSituation,
      createdNewCustomer: false,
      createdNewEmployee: null,
      createdNewProducts: null,
      clearAllBudget: false,
      maskCurrency: maskCurrency,
      maskPercentage: maskPercentage,
      compositionItems: [],
      idProductFromSearch: "",
      toggleToUpdate: false,
      selectedItemIdToReplace: null,
      productLoading: false,
      toggleClearSelect: false,
      tableProducts: [],
      newOrder: false,
      productAfterSelectItem: null,
    };
  },
  methods: {
    ...mapMutations(["SET_DATA_SEARCH_FROM_SALE", "SET_SALE_ID"]),
    async handleAddMoreItens() {
      if (!this.dataBudget.idCliente) {
        return this.$toast.open({
          message: "(Cliente) deve ser selecionado para prosseguir",
          type: "info",
        });
      }

      this.productLoading = true;
      await this.saveBudget();

      this.lineProduct.id
        ? await this.updateProductOfBudget()
        : await this.saveProductOfBudget();

      this.handleCalculateTotalsValues();
    },

    async saveProductOfBudget() {
      try {
        this.lineProduct.idVenda = this.dataBudget.id;

        if (!this.validadeProductOfSale(this.lineProduct)) return;

        await ServiceProductSale.save(this.lineProduct);
        await this.findTableProductsOfSale();
        this.clearLineProduct();

        return this.$toast.open({
          message: "Produto adicionado",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao salvar o produto",
          type: "info",
        });
      } finally {
        this.productLoading = false;
      }
    },

    async updateProductOfBudget() {
      try {
        this.lineProduct.idVenda = this.dataBudget.id;

        if (!this.validadeProductOfSale(this.lineProduct)) return;

        await ServiceProductSale.update(this.lineProduct.id, this.lineProduct);
        await this.findTableProductsOfSale();
        this.clearLineProduct();

        return this.$toast.open({
          message: "Produto atualizado",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao salvar o produto",
          type: "info",
        });
      } finally {
        this.productLoading = false;
      }
    },

    async editProductsOfSale(prod) {
      try {
        const newProd = removeReference(prod);
        this.assignProduct(newProd);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao editar o produto",
          type: "info",
        });
      }
    },

    async findTableProductsOfSale() {
      const result = await ServiceProductSale.findByIdSale(this.dataBudget.id);

      this.tableProducts = result || [];
    },

    assignProduct(prod) {
      this.lineProduct = prod;

      this.$refs.refQtd.valueInput = prod.quantidade;
      this.$refs.refVlUnit.valueInput = prod.valorUnitario;
      this.$refs.valorTotal.valueInput = prod.valorTotal;
      this.$refs.discount.valueInput = prod.desconto;
      this.$refs.dadosAdicionais.valueInput = prod.dadosAdicionais;

      this.handleCalculateTotal();
    },

    clearLineProduct() {
      this.lineProduct = {
        id: "",
        idVenda: "",
        idFornecedor: "",
        idProduto: "",
        quantidade: 1,
        valorUnitario: 0,
        valorTotal: 0,
        desconto: 0,
        dadosAdicionais: "",
        idProdutoComposicao: "",
        tipoProduto: "normal",
        composicao: [],
        tipoPreco: "P1",
      };

      if (!this.discountToAllItems) {
        this.lineProduct.desconto = 0;
        this.$refs.discount.valueInput = 0;
      }

      this.$refs.refQtd.valueInput = 1;
      this.$refs.refVlUnit.valueInput = 0;
      this.$refs.valorTotal.valueInput = 0;
      this.$refs.dadosAdicionais.valueInput = "";
      this.toggleClearSelect = !this.toggleClearSelect;
      this.productAfterSelectItem = null;
    },

    assignTotalItensValues() {
      const valorTotal = this.tableProducts.reduce(
        (oldValue, product) => oldValue + product.valorTotal,
        0
      );

      this.totalOfItens = parseFloat(valorTotal).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    assignTotalDiscount() {
      if (this.discountIsCheked) {
        this.totalOfDiscount = this.calculateTotalDiscount();
        return;
      }

      this.totalOfDiscount = this.calculateTotalDiscountWithMonetaryValue();
    },

    assignTotalValue() {
      const formattedTotal = mascaraMoeda(this.totalOfItens);
      const formattedTotalDiscount = mascaraMoeda(this.totalOfDiscount);
      const frete = this.dataBudget.frete;
      const outrosValores = this.dataBudget.outrosValores;

      const newTotal =
        formattedTotal -
        formattedTotalDiscount +
        (parseFloat(frete) || 0) +
        (parseFloat(outrosValores) || 0);

      this.totalValue = newTotal.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateTotalDiscountWithApllyToAll() {
      for (let i = 0; i < this.dataBudget.products.length; i++) {
        this.dataBudget.products[i].desconto =
          this.dataBudget.products[0].desconto;
      }

      const formattedTotalItens = mascaraMoeda(this.totalOfItens);

      const discountTotal =
        (formattedTotalItens * this.dataBudget.products[0].desconto) / 100;

      return discountTotal.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateTotalDiscount() {
      const discounts = this.tableProducts.map((prod) => {
        const desc = prod.desconto;
        const totalValue = prod.valorTotal;

        return (totalValue * desc) / 100;
      });

      const totalValueWithDiscount = discounts.reduce(
        (oldValue, desc) => oldValue + desc,
        0
      );

      return totalValueWithDiscount.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateTotalDiscountWithMonetaryValue() {
      const monetaryDiscounts = this.tableProducts.map((prod) => {
        if (prod.desconto) {
          const desc = prod.desconto;

          return desc;
        }

        return 0;
      });

      const totalMonetaryWithDiscount = monetaryDiscounts.reduce(
        (oldValue, desc) => oldValue + desc,
        0
      );

      return totalMonetaryWithDiscount.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    async removeItens(id) {
      try {
        await ServiceProductSale.delete(id);
        await this.findTableProductsOfSale();

        this.handleCalculateTotalsValues();
        return this.$toast.open({
          message: "Produto removido",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message:
            `${error.response.data.message}` ||
            "Ocorreu um erro ao remover o produto!",
          type: "info",
        });
      }
    },

    async cancelBudget() {
      try {
        await ServiceSale.cancel(this.dataBudget.id);

        this.dataBudget.idSituacao = this.getSpecificSituantionId(
          SaleTypeSituation.cancelado
        );

        return this.$toast.open({
          message: "Orçamento cancelado!",
          type: "success",
        });
      } catch (error) {
        toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async reOpenBudget() {
      try {
        await ServiceSale.reOpen(this.dataBudget.id);

        this.dataBudget.idSituacao = this.getSpecificSituantionId(
          SaleTypeSituation.emAberto
        );

        return this.$toast.open({
          message: "Orçamento reaberto!",
          type: "success",
        });
      } catch (error) {
        toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    handleCalculateTotal() {
      const amount = Number(this.lineProduct.quantidade);
      const unitaryValue = this.lineProduct.valorUnitario;
      const totalValue = amount * unitaryValue;

      if (!this.$refs.valorTotal) return;

      this.lineProduct.valorTotal = totalValue;

      let discountValue = 0;

      if (this.dataBudget.tipoDesconto === "Porcentagem") {
        const result = (
          this.lineProduct.valorTotal *
          (this.lineProduct.desconto / 100)
        ).toFixed(2);

        discountValue = parseFloat(result);
      } else {
        discountValue = this.lineProduct.desconto;
      }

      this.$refs.valorTotal.valueInput = totalValue - discountValue;
    },

    async handleSituation() {
      const allSituation = await this.getAllSituation();
      this.toAssignDefaultSituation(allSituation);
    },

    async getAllSituation() {
      try {
        const result = await ServiceSituation.getAllSituation();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    async listEmployeesSelectBox() {
      try {
        const { data } = await api.get("/employees/combobox/fill");
        this.dataEmployee = data.data;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os funcionários",
          type: "info",
        });
      }
    },

    async getProdutos() {
      const { data } = await api.get("/products");
      this.products = data.data;
    },

    async getProviders() {
      const { data } = await api.get("/providers/fill/combobox");
      this.providers = data;
    },

    async getProductById(idProduct) {
      const data = await ServiceProducts.getProductById(idProduct);
      return data;
    },

    async updateProductValue(selected) {
      const valueMapping = {
        P1: this.productAfterSelectItem.valorVenda,
        P2: this.productAfterSelectItem.valorVenda2,
        P3: this.productAfterSelectItem.valorVenda3,
      };

      const currencyValueProduct = valueMapping[selected];

      this.lineProduct.valorUnitario = currencyValueProduct;

      if (this.$refs.refVlUnit) {
        this.$refs.refVlUnit.valueInput = currencyValueProduct;
      }

      this.handleCalculateTotal();
    },

    async selectProductSetDefaultValue(product) {
      this.productAfterSelectItem = product;

      if (product && !this.lineProduct.id) {
        this.lineProduct.valorUnitario = product.valorVenda;
        this.$refs.refVlUnit.valueInput = product.valorVenda;

        const amount = Number(this.lineProduct.quantidade);
        const unitaryValue = this.lineProduct.valorUnitario;
        const total = amount * unitaryValue;

        this.lineProduct.valorTotal = total;
        this.$refs.valorTotal.valueInput = total;

        if (product?.composicao) {
          this.lineProduct.composicao = product?.composicao?.map((item) => {
            const newInitialState = {
              ...INITIAL_STATE_PRODUCTS,
              idList: uuidv4(),
              dadosAdicionais: item.dadosAdicionais,
              idProduto: item.idProduto,
              tipoProduto: "composicao",
              tipo: "Orçamento",
              quantidade: item.quantidade,
            };

            Reflect.deleteProperty(item, "idRefProduto");

            return {
              ...item,
              ...newInitialState,
            };
          });
        }

        this.updateProductValue(this.lineProduct.tipoPreco || "P1");
      }
    },

    async saveBudget() {
      try {
        if (this.dataBudget.id) return;

        const result = await ServiceSale.createWithoutProduct({
          sale: this.dataBudget,
        });

        this.dataBudget.id = result.id;
        this.numberBudget = result.number;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um problema ao salvar",
          type: "info",
        });

        this.productLoading = false;
      }
    },

    async updateBudget() {
      await ServiceSale.updateWithoutProduct({
        sale: this.dataBudget,
      });
    },

    async fillProductsComboBoxWithPaginate(id, q) {
      try {
        return await ServiceProducts.fillComboBoxWithPaginate(id, q);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os produtos",
          type: "info",
        });
      }
    },

    async fillCustomerComboBoxWithPaginate(id, q) {
      try {
        return await ServiceCustomer.fillComboBoxWithPaginate(id, q);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os clientes",
          type: "info",
        });
      }
    },

    validadeProductOfSale(prod) {
      const isNotValid = ValidadeProductOfSale(prod);

      if (isNotValid && Object.keys(isNotValid).length) {
        this.$toast.open({
          message: isNotValid.message,
          type: "info",
        });

        return false;
      }

      return true;
    },

    cancelReplaceAll() {
      this.selectedItemIdToReplace = null;

      if (this.$refs.replaceAll) {
        this.$refs.replaceAll.style.display = "block";
      }
    },

    async applyReplaceAll() {
      try {
        await ServiceProductSale.replaceAll(
          this.lineProduct.idProduto,
          this.selectedItemIdToReplace,
          this.dataBudget.id,
          this.lineProduct.tipoPreco
        );

        this.cancelReplaceAll();
        this.clearLineProduct();
        await this.findTableProductsOfSale();

        this.handleCalculateTotalsValues();
      } catch (error) {
        this.$toast.open({
          message:
            error?.responde?.data?.message ||
            "Ocorreu um problema ao substituir os itens",
          type: "info",
        });
      }
    },

    handleItemSelected(prevId) {
      this.selectedItemIdToReplace = prevId;

      if (this.$refs.replaceAll) {
        this.$refs.replaceAll.style.display = "none";
      }
    },

    async handleDuplicate(productOfSale) {
      try {
        const newProd = removeReference(productOfSale);
        newProd.id = "";

        await ServiceProductSale.save(newProd);
        await this.findTableProductsOfSale();

        this.$toast.open({
          message: "Item Clonado",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao clonar o item",
          type: "info",
        });
      }
    },

    handleCalculateTotalInTable(prod) {
      let newTotalValue = 0;

      if (this.dataBudget.tipoDesconto === "Porcentagem") {
        const result = (prod.valorTotal * (prod.desconto / 100)).toFixed(2);

        newTotalValue = parseFloat(prod.valorTotal - result);
      } else {
        newTotalValue = prod.valorTotal - prod.desconto;
      }

      return maskCurrency(newTotalValue);
    },

    handleDataToSendModalBudget() {
      this.assingValuesBudgetForModalBudget();
      this.openModals("modalBudget");
    },

    handleModalCreateProduct(nameModal, idProduct) {
      this.idProductFromSearch = idProduct;
      this.openModals(nameModal);
    },

    assingValuesBudgetForModalBudget() {
      this.dataBudgetForModalBudget.formattedProductsBudget =
        this.tableProducts;

      this.dataBudgetForModalBudget.customerSelected.nome =
        this.dataCustomers.filter(
          (customer) => customer.id === this.dataBudget.idCliente
        )[0]?.nome;

      this.dataBudgetForModalBudget.employeeSelected.nomeFuncionario =
        this.dataEmployee.filter(
          (employee) => employee.id === this.dataBudget.idFuncionario
        )[0]?.nomeFuncionario;

      this.dataBudgetForModalBudget.data = this.dataBudget.data;
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    async alterDiscountIsCheked() {
      this.discountIsCheked = !this.discountIsCheked;

      if (this.discountIsCheked) {
        this.dataBudget.tipoDesconto = "Porcentagem";
        this.$refs.enableLabelApplyAll.style.display = "block";
      } else {
        this.dataBudget.tipoDesconto = "Valor Monetário";
        this.$refs.enableLabelApplyAll.style.display = "none";
        this.discountToAllItems = false;
      }

      if (this.dataBudget.id) await this.updateBudget();

      this.handleCalculateTotalsValues();
      this.handleCalculateTotal();
    },

    alterAllChecksDiscount() {
      if (this.discountToAllItems) {
        this.$refs.typeDiscount.checked = true;

        this.handleCalculateTotalsValues();
      }
    },

    handleCreatedNewProduct() {
      this.createdNewProducts = !this.createdNewProducts;
    },

    async handleValuesAfterEditBudget() {
      this.handleBudgetValues();
    },

    handleBudgetValues() {
      this.handleBillsIfAny();
      this.handleBudgetProductsIfAny();
      this.assignDataSearchFromBudget();
    },

    handleBillsIfAny() {
      return Reflect.deleteProperty(this.dataSearchFromSale, "bills");
    },

    handleBudgetProductsIfAny() {
      if (!this.dataSearchFromSale?.products?.length) {
        Reflect.deleteProperty(this.dataSearchFromSale, "products");
      }
    },

    handleDataCustomers(event) {
      this.dataCustomers = [event];
    },

    handleCreatedNewCustomer() {
      this.createdNewCustomer = !this.createdNewCustomer;
    },

    assignDataSearchFromBudget() {
      Object.assign(this.dataBudget, this.dataSearchFromSale);

      this.tableProducts = this.dataSearchFromSale.products || [];
      Reflect.deleteProperty(this.dataBudget, "products");

      if (this.dataBudget.tipoDesconto === "Porcentagem") {
        this.discountIsCheked = true;
        this.$nextTick(() => {
          this.$refs.enableLabelApplyAll.style.display = "block";
        });
        this.assignTotalDiscount();
      }

      this.numberBudget = this.dataSearchFromSale.numero;

      this.$refs.refResponsavel.valueItems =
        this.dataSearchFromSale.idFuncionario;
      this.$refs.data.valueInput = this.dataSearchFromSale.data;
      this.$refs.dataVencimento.valueInput =
        this.dataSearchFromSale.dataVencimento;
      this.$refs.dataEntrega.valueInput = this.dataSearchFromSale.dataEntrega;
    },

    toAssignDefaultSituation(situations) {
      this.allSituations = situations
        .filter((situation) => {
          if (
            [SaleTypeSituation.emAberto, SaleTypeSituation.cancelado].includes(
              situation.nome
            )
          ) {
            return situation;
          }
        })
        .reverse();

      if (!this.dataBudget.id) {
        this.dataBudget.idSituacao = this.getSpecificSituantionId(
          SaleTypeSituation.emAberto
        );
      }
    },

    getSpecificSituantionId(saleTypeSituation) {
      const situation = this.allSituations.find(
        (situation) => situation.nome === saleTypeSituation
      );

      return situation?.id;
    },

    getNameSituantionById(idSituation) {
      const situation = this.allSituations.find(
        (situation) => situation.id === idSituation
      );

      return situation?.nome?.toUpperCase();
    },

    handleFreteMonetaryValue(event) {
      this.dataBudget.frete = mascaraMoeda(event.target.value);
    },

    handleOtherValuesMonetaryValue(event) {
      this.dataBudget.outrosValores = mascaraMoeda(event.target.value);
    },

    handleCalculateTotalsValues() {
      this.assignTotalItensValues();
      this.assignTotalDiscount();
      this.assignTotalValue();
    },

    clearAllRefs() {
      this.$refs.refResponsavel.valueItems = "";
      this.$refs.dataVencimento.valueItems = null;
      this.$refs.dataEntrega.valueItems = null;
      this.$refs.valorTotal.valueItems = "0,00";
      this.$refs.refVlUnit.valueItems = "0,00";
    },

    handleShowCompositionItem(idProduct, composition) {
      if (idProduct) {
        this.compositionItems = composition;
        this.toggleToUpdate = !this.toggleToUpdate;
        this.$bvModal.show("modalProductSaleComposition");
      }
    },

    handleItemFromModalComposition(payload) {
      this.lineProduct.composicao = payload.composition;
    },
  },
  mounted() {
    this.listEmployeesSelectBox();
    this.getProdutos();
    this.getProviders();
    this.handleSituation();

    if (
      !this.dataBudget.dadosAdicionais &&
      this.dataConfig &&
      Object.keys(this.dataConfig.orcamento).length &&
      this.dataConfig.orcamento.dados_adicionais_padrao
    ) {
      this.dataBudget.dadosAdicionais =
        this.dataConfig.orcamento.dados_adicionais_padrao;
    }

    if (this.dataSearchFromSale.idCliente) {
      this.handleValuesAfterEditBudget();
      this.SET_DATA_SEARCH_FROM_SALE({});
    }

    this.assignTotalItensValues();
    this.assignTotalDiscount();
    this.assignTotalValue();
  },
  filters: {
    moment(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
  watch: {
    freteToAdditionalValues() {
      this.additionalValues = "R$ 0,00";
      const formattedFrete = this.dataBudget.frete;

      if (formattedFrete) {
        this.additionalValues = parseFloat(formattedFrete).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        );
      }

      this.assignTotalValue();
    },

    otherValuesToAdditionalValues() {
      this.otherValues = "R$ 0,00";
      const formattedOtherVales = this.dataBudget.outrosValores;

      if (formattedOtherVales) {
        this.otherValues = parseFloat(formattedOtherVales).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        );
      }

      this.assignTotalValue();
    },

    dataBudgetOfTheBudgetModal() {
      if (this.dataBudgetOfTheBudgetModal.id) {
        this.dataBudget.id = this.dataBudgetOfTheBudgetModal.id;
      }

      if (this.dataBudgetOfTheBudgetModal.productsOfSale) {
        const newProductsFromTable =
          this.dataBudgetOfTheBudgetModal.productsOfSale.map((prod, index) => {
            return {
              ...prod,
              idList: this.dataBudget.products[index].idList,
            };
          });

        this.dataBudget.products = newProductsFromTable;
      }
    },

    createdNewEmployee() {
      this.listEmployeesSelectBox();
    },

    clearAllBudget() {
      this.clearAllRefs();
      this.comissao = "";
      this.dataBudget.id = "";
      this.clearAllBudget = false;
      this.dataBudget.idCliente = "";
      this.dataBudget.dataVencimento = "DD/MM/YYYY";
      this.dataBudget.dataEntrega = "DD/MM/YYYY";
      this.dataBudget.idFuncionario = "";
      this.numberBudget = "";
      this.dataBudgetOfTheBudgetModal.number = "";
      this.dataBudget.dadosAdicionais = "";
      this.dataBudget.products = [
        {
          id: "",
          idVenda: "",
          idFornecedor: "",
          idProduto: "",
          quantidade: "1",
          altura: "",
          largura: "",
          metragem: "",
          porcentagemPerda: "",
          valorUnitario: 0,
          valorTotal: 0,
          desconto: 0,
          dadosAdicionais: "",
        },
      ];
      this.dataBudget.frete = 0;
      this.totalOfItens = "R$ 0,00";
      this.totalOfDiscount = "R$ 0,00";
      this.additionalValues = "R$ 0,00";
      this.totalValue = "R$ 0,00";
    },
  },
  computed: {
    ...mapState({
      dataSearchFromSale: (state) => state.dataSearchFromSale,
      dataConfig: (state) => state.dataConfig,
    }),
    decimalPlaces() {
      if (Object.keys(this.dataConfig).length) {
        return Number(this.dataConfig?.venda_mtq?.casas_decimais);
      }

      return 2;
    },
    freteToAdditionalValues() {
      return this.dataBudget.frete;
    },
    otherValuesToAdditionalValues() {
      return this.dataBudget.outrosValores;
    },
    objectTotal() {
      return {
        totalItems: this.totalOfItens,
        additionalValues: this.additionalValues,
        otherValues: this.otherValues,
        totalDiscount: this.totalOfDiscount,
        total: this.totalValue,
      };
    },
    alterColorSituation() {
      const situation = this.allSituations.find(
        (situation) => situation.id === this.dataBudget.idSituacao
      );

      if (situation && situation.nome === SaleTypeSituation.emAberto) {
        return "";
      }

      return "rgb(198, 32, 32)";
    },
    validatorDesc() {
      let existsDiscount = false;

      if (this.tableProducts.length) {
        for (const product of this.tableProducts) {
          if (product.desconto) {
            existsDiscount = true;
            break;
          }
        }
      }

      return existsDiscount;
    },
  },
  beforeDestroy() {
    this.SET_SALE_ID("");
  },
};
</script>

<style scoped>
.containerGeralBudget {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 2rem;
  margin-left: 2rem;
}

p {
  margin: 0;
}

.titleHeader {
  margin-left: 16px;
}

.containerInputItem {
  width: 40%;
  position: relative;
  min-width: 320px;
}

.inputItem {
  width: 100%;
  min-width: 320px;
  position: relative;
}

.inputItemObs {
  width: 40%;
  margin-top: 0.5rem;
}

.inputItemQnt {
  width: 8%;
}

.inputItemVlUnit {
  width: 13%;
}

.inputItemVlTot {
  width: 13%;
}

.inputItemDesc {
  width: 10%;
}

.itemToggleDesc {
  width: 52px;
  position: relative;
}

.containerTableItens {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap-reverse;
}

.contentTableItens {
  width: 100%;
  height: 242px;
  border: dashed 1px #c1c1c1;
  border-radius: 5px;
  padding: 0.3rem 0.5rem;
}

.tableProducts {
  display: table;
  width: 100%;
  border-spacing: 0;
  margin-top: 0.5rem;
}

.tableProducts thead tr th {
  padding: 0 0.5rem;
  font-weight: normal;
}

.tableProducts thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.lineClient {
  display: flex;
  justify-content: space-between;
}

.lineDates {
  display: flex;
}

.tableItem {
  height: 50px;
  background-color: whitesmoke;
  box-shadow: 0 1px 3px rgb(0 0 0 / 24%);
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 0.5rem;
  position: relative;
  transition: ease 0.2s;
}

.tableItem:hover {
  background-color: rgb(218, 218, 218);
  transition: ease 0.2s;
}

.tableItem td {
  text-align: start !important;
  padding: 0 0.5rem;
}

.textEllipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.spinProduct {
  color: white;
  width: 20px;
  height: 20px;
}

.contentNumberItem {
  height: 18px;
  width: 18px;
  min-width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: gray;
  color: white;
}

.contentRemoveItem {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 5px 5px 0;
  background-color: brown;
  box-shadow: -1px 0 2px rgb(0 0 0 / 24%);
}

.blockRemove {
  background-color: rgb(144, 85, 85);
  pointer-events: none !important;
  cursor: default !important;
}

.contentCloneItem {
  position: absolute;
  right: 30px;
  top: 0;
  height: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentNotItems {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentNotItems p {
  font-size: 16px;
  color: #c1c1c1;
}

.infinite {
  padding-right: 30px;
  position: relative;
}

.btnsBudget {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  height: 30px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
}

.btnAddMoreItems {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnCancelBudget {
  background-color: #ff3131;
}

.btnReopenBudget {
  background-color: #cac64e;
}

.btnsComposition {
  border-radius: 5px;
  width: auto;
  height: 20px;
  border: none;
  color: white;
  font-size: 9px;
  background-color: #a8a8a8;
  transition: ease-in all 0.1s;
}

.btnsComposition:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.353);
  background-color: rgb(116, 116, 116);
  transition: ease-in all 0.1s;
}

.btnReplaceAll {
  border-radius: 5px;
  background-color: #a8a8a8;
  border: none;
  color: white;
  font-size: 12px;
  width: 20px;
  height: 20px;
  padding: 0.2rem;
  cursor: pointer;
  transition: ease-in all 0.1s;
}

.btnReplaceAll:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.353);
  background-color: rgb(116, 116, 116);
  transition: ease-in all 0.1s;
}

.isDisabled {
  background-color: #e1e1e1;
}

.btnConfirm {
  background-color: whitesmoke;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.inputDesc {
  width: 8.3% !important;
}

.labelInputs p {
  font-weight: 100 !important;
  color: #00395d;
  padding: 0;
}

.iconPlus {
  position: absolute;
  right: 20px;
  top: 0;
}

.switch {
  height: 100%;
  margin: 0;
  font-size: 14px;
  position: relative;
  display: inline-block;
}

.containerItens {
  margin-top: 1.5rem;
  padding: 0 15px;
}

.contentTitleItens {
  align-items: center !important;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #d9d9d9;
  color: #ffffff;
  border-radius: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  width: 50px !important;
  height: 90%;
  font-size: 8px;
}

.slider:before {
  position: absolute;
  content: "R$";
  font-size: 10px;
  height: 82%;
  width: 44%;
  left: 6%;
  border-radius: 50px;
  background-color: #00aa62;
  color: white;
  display: grid;
  align-content: center;
  justify-content: center;
  transition: 0.4s;
  font-weight: bold;
}

.slider:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.switch input:checked + .slider {
  background-color: #d9d9d9;
  color: white;
}

.switch input:checked + .slider:before {
  content: "%";
  background-color: #00aa62;
  transform: translateX(100%);
  color: white;
}

.switch input {
  display: none;
}

.contentDataTotals {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.inputTotals {
  display: flex;
  align-items: center;
  gap: 30px;
  color: #666360;
}

.dashed {
  border-bottom: 1px dashed #b1aaa5;
  width: 100%;
  margin: 10px 0;
}

.infinite {
  padding-right: 30px;
}

.inputTotals input {
  border: none;
  outline: none;
  text-align: end;
  padding-right: 5px;
  background-color: #d9d9d9;
  border-radius: 5px;
  height: 30px;

  border: solid 1px #d9d9d9;
}

.inputTotals p {
  width: 200px;
}

.contentTotals {
  width: 370px;
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  font-size: 14px;
  gap: 5px;
}

.itemsBudget {
  position: relative;
  display: flex;
  gap: 15px;
  margin-left: 0;
}

.contentFreteTotals input {
  background-color: white !important;
  border: solid 1px #d9d9d9;
}

.infoValue {
  gap: 5px;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.radio-input input {
  display: none;
}

.radio-input {
  position: absolute;
  right: 0;
  top: -2px;
  display: flex;
  --container_width: 130px;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  color: #000000;
  width: var(--container_width);
  overflow: hidden;
  border: 1px solid rgba(53, 52, 52, 0.226);
}

.radio-input label {
  width: 100%;
  padding: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: 11px;
  margin-bottom: 0px !important;
}

.selection {
  display: none;
  position: absolute;
  height: 100%;
  width: calc(var(--container_width) / 3);
  z-index: 0;
  left: 0;
  top: 0;
  transition: 0.15s ease;
}

.radio-input label:has(input:checked) {
  color: #fff;
}

.radio-input label:has(input:checked) ~ .selection {
  background-color: #3a4750;
  display: inline-block;
}

.radio-input label:nth-child(1):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 0 / 3));
}

.radio-input label:nth-child(2):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 1 / 3));
}

.radio-input label:nth-child(3):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 2 / 3));
}

.observacoes {
  font-size: 12px;
  color: #7d7d7d;
  font-style: italic;
}

.title {
  font-size: 14px;
  color: #666360;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.contentIconRemoveItems {
  position: absolute;
  right: 60px;
  color: rgb(255, 51, 51);
}

.tooltipDiscount {
  z-index: 3;
  width: 150px;
  padding: 5px;
  font-size: 12px;
  position: absolute;
  color: black;
  border-radius: 5px;
  top: -25px;
  right: -10px;
}

.tooltipItems {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 886px) {
  .contentTableItens {
    width: 100%;
  }
}

@media screen and (max-width: 1300px) {
  .responsiveItems {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .inputItemObs {
    width: 270px;
  }

  .inputItemQnt {
    width: 80px;
  }

  .inputItemVlUnit {
    width: 100px;
  }

  .inputItemVlTot {
    width: 100px;
  }

  .inputItemDesc {
    width: 105px;
  }
}

@media screen and (min-width: 769px) {
  .tableProducts tbody {
    display: block;
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 0.5rem;
    padding: 5px !important;
  }
}

@media screen and (max-width: 800px) {
  .containerInputItem {
    width: 72%;
  }

  .inputItemObs {
    width: 60%;
  }

  .inputItemQnt {
    width: 25%;
  }

  .inputItemVlUnit {
    width: 28%;
    margin-top: 0.5rem;
  }

  .inputItemVlTot {
    width: 28%;
    margin-top: 0.5rem;
  }

  .inputItemDesc {
    width: 28%;
    margin-top: 0.5rem;
  }

  .itemToggleDesc {
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .paymentInterval {
    margin-top: 1rem !important;
  }

  .tooltipDiscount {
    right: -85px;
    top: -28px;
  }

  .tableProducts tbody {
    display: block;
    height: 200px;
    margin-top: 0.5rem;
    padding: 5px !important;
  }

  .contentTableItens {
    overflow-x: auto;
  }
}

@media screen and (max-width: 575px) {
  .receiptAccount {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 475px) {
  .containerInputItem,
  .inputItem {
    width: 100%;
    min-width: 100% !important;
  }
  .inputItemQnt {
    width: 31.6%;
    margin-top: 0.5rem;
  }

  .inputItemVlUnit {
    width: 31.6%;
    margin-top: 0.5rem;
  }

  .inputItemVlTot {
    width: 31.6%;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 400px) {
  .itemsBudget {
    margin: 0 !important;
  }

  .btnAddMoreItems {
    width: 100% !important;
  }

  .inputItem {
    width: 100%;
    min-width: 100% !important;
  }

  .inputItemObs {
    width: 100%;
  }

  .inputItemQnt {
    width: 25%;
    margin-top: 0.5rem;
  }

  .inputItemVlUnit {
    width: 33%;
    margin-top: 0.5rem;
  }

  .inputItemVlTot {
    width: 33%;
    margin-top: 0.5rem;
  }

  .inputItemDesc {
    width: 62%;
    margin-top: 0.5rem;
  }

  .itemToggleDesc {
    margin-top: 0.5rem;
  }
}

.contentItens {
  margin-top: 1.5rem !important;
}

.contentButtonsActions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.titleBudgetProducts {
  font-size: 20px;
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 60px;
  padding-left: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
}

.inputSelectSituation {
  border: solid 1px #d5d3d3;
  padding: 3px;
  border-radius: 0.25rem;
  outline: none;
  color: #5c5855;
  font-size: 12px;
}

.obsGeneral {
  font-size: 14px;
  color: #666360;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .containerGeralBudget {
    margin-left: 0rem;
  }

  .contentIconRemoveItems {
    right: 10px;
  }

  .containerItens {
    padding: 0 30px;
  }

  .contentTitleItens {
    padding: 0 !important;
  }

  .responsiveItems {
    padding-top: 30px;
    padding-bottom: 10px;
    margin-top: 0rem !important;
  }

  .contentItens {
    margin-top: 0rem !important;
  }

  .itemsBudget {
    margin-left: 0px;
    margin-right: 0px;
  }

  .btnsBudget {
    width: 140px;
    margin-top: 15px;
    font-size: 10px;
  }

  .contentTotals {
    font-size: 11px;
  }

  .col-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12,
  .col-xl-12 {
    padding-right: 0px;
    padding-left: 10px;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 23%;
    max-width: 23%;
  }
  .col-md-5 {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 576px) {
  .col-sm-6 {
    flex: 0 0 23%;
    max-width: 23%;
  }
}

@media screen and (max-width: 700px) {
  .containerGeralBudget {
    margin-left: 0rem;
  }

  .containerGeralPedidoVenda {
    margin-left: 0rem;
  }

  .containerTableItens {
    padding-left: 15px;
  }

  .containerItens {
    padding: 0;
    margin: 0 15px 0 0;
  }

  .contentTitleItens {
    margin-left: 0 !important;
    padding-left: 16px !important;
  }

  .tooltipDiscount {
    right: -15px;
    top: -23px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 0px;
    padding-left: 15px;
  }

  .infinite {
    padding-right: 15px;
  }

  .row {
    gap: 20px;
  }

  .responsiveItems {
    padding-top: 25px;
    padding-bottom: 5px;
    margin-top: 0rem !important;
  }

  .inputTotals {
    gap: 10px;
  }

  .obsGeneral {
    width: 80%;
  }

  .itemsBudget {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px !important;
    padding-left: 16px;
    margin-right: 16px;
    width: 100%;
  }

  .contentIconRemoveItems {
    right: -20px;
  }

  .ajustMobile {
    width: 13% !important;
  }

  @media screen and (max-width: 547px) {
    .tooltipDiscount {
      right: -85px;
      top: -30px;
    }

    .itemToggleDesc,
    .inputItemObs {
      margin-top: 1rem;
    }
  }
}
</style>
