<template>
  <div class="BoxFront">
    <div class="contentFront">
      <p style="font-size: 1rem; font-weight: bold">NOTA DE COMPRA</p>
      <div class="formFront">
        <InputSelect
          label="Natureza operação"
          :items="allNatures"
          valueField="id"
          textField="descricao"
          :dataSelect.sync="dataPurchase.idNatureOp"
          :openCreateModal="() => openModals('modalNatures')"
          ref="refNop"
        />
        <div class="selectPlus">
          <label class="fixedSelect"
            >Selecione CFOP <span style="color: rgb(115, 0, 0)">*</span></label
          >
          <b-form-select
            class="borderInput shadow-none"
            :options="cfop"
            v-model="dataPurchase.cfop"
            size="sm"
          >
          </b-form-select>
        </div>
        <span class="alertModal"
          >*É necessário selecionar o CFOP antes de importar o XML</span
        >
        <div class="rowContent">
          <b-form-input
            hidden
            v-model="dataPurchase.id"
            class="col-sm-1"
          ></b-form-input>

          <div class="input-group">
            <input
              type="date"
              class="input borderInput"
              v-model="dataPurchase.dataCompra"
            />
            <label class="user-label"> Data Compra </label>
          </div>

          <div class="input-group">
            <input
              required
              class="input borderInput"
              type="number"
              v-model="dataPurchase.numeroNfe"
            />
            <label class="user-label"> Nº NF-e </label>
          </div>

          <div class="input-group">
            <input
              required
              class="input borderInput"
              type="number"
              v-model="dataPurchase.modeloNfe"
            />
            <label class="user-label"> Modelo </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              type="number"
              v-model="dataPurchase.serieNfe"
            />
            <label class="user-label"> Serie </label>
          </div>
        </div>
        <div class="selectPlus">
          <label class="fixedSelect">Fornecedor</label>
          <b-form-select
            class="borderInput shadow-none"
            text-field="razaoSocial"
            value-field="id"
            v-model="dataPurchase.idFornecedor"
            size="sm"
            :options="allSupplier"
          ></b-form-select>
          <div class="iconSelect" @click="openModals('modalCreateSupplier')">
            <div role="button">
              <b-icon-plus scale="2"></b-icon-plus>
            </div>
          </div>
        </div>

        <div class="selectPlus">
          <label class="fixedSelect">Produto</label>
          <b-form-select
            class="borderInput shadow-none"
            v-model="productsSelected"
            size="sm"
            @change="selectProductSetDefaultValue"
          >
            <b-form-select-option
              v-for="produtos in products"
              :key="produtos.id"
              :value="produtos"
              >{{ produtos.nome }}</b-form-select-option
            >
          </b-form-select>
          <div class="iconSelect" v-b-modal.modal-1>
            <div role="button">
              <b-icon-plus scale="2"></b-icon-plus>
            </div>
          </div>
        </div>
        <div class="rowContent">
          <div class="input-group">
            <input
              required
              class="input borderInput"
              type="number"
              v-model="dataPurchase.quantidade"
            />
            <label class="user-label"> Quantidade </label>
          </div>

          <Input
            label="Valor"
            type="text"
            :mask="dataPurchase.valorUnitario"
            :dataInput.sync="dataPurchase.valorUnitario"
            ref="valor"
          />

          <Input
            label="Estoque"
            type="text"
            :dataInput.sync="estoque"
            ref="estoque"
            :class="`inputDisabled`"
          />
        </div>

        <div class="rowObservation">
          <div class="input-group-ob">
            <input
              required
              class="input borderInput"
              v-model="dataPurchase.dadosAdicionais"
            />
            <label class="user-label"> Descrição </label>
          </div>
        </div>
      </div>
      <div class="content-form-btn">
        <b-button @click="addProduct" class="form-btn">
          Adicionar Produto <b-icon-arrow-right-short></b-icon-arrow-right-short
        ></b-button>
      </div>
    </div>
    <div class="contentSketch">
      <p style="font-size: 1rem; font-weight: bold; padding: 0 25px">
        PRODUTOS ADICIONADOS
      </p>
      <div class="tableProducts">
        <table style="width: 100%">
          <thead style="border-bottom: 2px solid #d5d3d3; font-size: 12px">
            <tr>
              <td style="width: 40%">Nome</td>
              <td style="width: 10%">Quant</td>
              <td style="width: 15%">Vl. Unitário</td>
              <td style="width: 15%">Vl. Total</td>
              <td style="width: 10%">Ações</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in productsListGrid"
              :key="item.id"
              class="rowProduct"
            >
              <td style="width: 40%">{{ item.nome }}</td>
              <td style="width: 10%">{{ item.quantidade }}</td>
              <td style="width: 15%">
                {{
                  item.valorUnitario.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </td>
              <td style="width: 15%">
                {{
                  (item.valorUnitario * item.quantidade).toLocaleString(
                    "pt-br",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  )
                }}
              </td>
              <td style="width: 10%">
                <b-icon-x-circle
                  scale="1.3"
                  class="trashDelete"
                  @click="deleteProductOfPurchase(item)"
                ></b-icon-x-circle>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="styleTotal">
        <span>VALOR TOTAL</span>
        <span> {{ this.amount }} </span>
      </div>
      <div class="content-sketch-btn">
        <button
          @click="clearInputs"
          class="sketch-btn"
          style="background: #fbfbfb; color: black; border: 1px solid gray"
        >
          Novo <b-icon-arrow-clockwise class="ml-1"></b-icon-arrow-clockwise>
        </button>

        <button
          style="background: #3e66ae; color: white; border: none"
          @click="openModalImportXml"
          class="sketch-btn"
          :disabled="dataPurchase.cfop === ''"
        >
          <span>Importar XML</span>
        </button>
        <button @click="saveAndUpdate" class="sketch-btn">
          <span>Salvar</span>
        </button>
      </div>
    </div>
    <b-modal id="modal-1" hide-footer hide-header size="lg">
      <button class="closeModal" @click="$bvModal.hide('modal-1')">
        <b-icon-x scale="1.3"></b-icon-x>
      </button>
      <Products
        :readOrEditProducts="dataProducts"
        @getAllProducts="getProdutos"
      />
    </b-modal>
    <ModalCreateSupplier @getAllNewSupplier="getAllSuppliers" />
    <ModalImportXml
      @idSupplierForSelectBox="idSupplier = $event"
      @dataProduct="dataProduct = $event"
      @modalIdForPurchase="modalIdForPurchase = $event"
      @idMovimentPurchase="idMovimentPurchase = $event"
      :cfopSelect="cfopSelect"
    />
    <ModalCreateNatureOps @getNewNature="getAllNatures" />
  </div>
</template>

<script>
import ModalImportXml from "./Modal-Import-Xml.vue";
import Products from "../Products/Products.vue";
import Input from "../UI/CustomInputs/Input.vue";
import InputSelect from "../../components/UI/CustomInputs/InputSelect.vue";
import ModalCreateSupplier from "../Modals/ModalCreateSupplier.vue";
import ServiceSupplier from "../../services/serviceSupplier";
import ServicePurchase from "../../services/servicePurchase";
import ServiceProductsPurchase from "../../services/serviceProductsPurchase";
import { cfop } from "../../common/index";
import api from "../../services/axios";
import { mascaraMoeda } from "../../utils/maskMoney";
import { mapState, mapMutations } from "vuex";
import serviceNatureOps from "../../services/serviceNatureOps";
import ModalCreateNatureOps from "../../components/Modals/ModalCreateNatureOps.vue";
export default {
  components: {
    Products,
    ModalCreateSupplier,
    ModalCreateNatureOps,
    ModalImportXml,
    Input,
    InputSelect,
  },
  props: {
    dataEditPurchase: {
      type: Object,
    },
  },
  data() {
    return {
      dataProducts: {},
      getAllProducts: null,
      products: [],
      allSupplier: [],
      amount: 0,
      cfop: cfop,
      cfopSelect: "",
      dataPurchase: {
        id: "",
        idFornecedor: "",
        dataCompra: "",
        numeroNfe: "",
        modeloNfe: "",
        serieNfe: "",
        dadosAdicionais: null,
        quantidade: 1,
        valorUnitario: 0,
        cfop: "",
        baseIcms: "0.00",
        vIcms: "0.00",
        vIpi: "0.00",
        vOutros: "0.00",
        Status: "Ativo",
        idNatureOp: "",
      },
      idSupplier: "",
      idSupplierForSelectBox: "",
      productsSelected: [],
      dataProduct: [],
      modalIdForPurchase: "",
      idMovimentPurchase: "",
      estoque: 0,
      addProdutos: {
        id: "",
        idEmpresa: "",
        idProduto: "",
        nomeProduto: "",
        idCompra: "",
        quantidade: "",
        valorUnitario: "",
      },
      productsListGrid: [],
      returnData: [],
      allNatures: [],
    };
  },

  methods: {
    ...mapMutations(["SET_DATA_SEARCH_FROM_PURCHASE"]),
    async getProdutos() {
      const { data } = await api.get("/products");
      this.products = data.data;
    },

    async saveAndUpdate() {
      this.dataPurchase.id !== ""
        ? await this.updatePurchase()
        : await this.savePurchase();
    },

    async savePurchase() {
      try {
        const { id } = await ServicePurchase.savePurchase(this.dataPurchase);
        this.dataPurchase.id = id;
        return this.$toast.open({
          message: "Salvo com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else console.log(error);
      }
    },

    async updatePurchase() {
      try {
        const { data } = await ServicePurchase.updatePurchase(
          this.dataPurchase.id,
          this.dataPurchase
        );

        this.$toast.open({
          message: "Atualizado com sucesso!",
          type: "success",
        });
        return data;
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: `${error.response.data.message}`,
            type: "warning",
          });
      }
    },

    sumValueTotal() {
      const totalofValor = this.productsListGrid.reduce(
        (oldValue, newValue) =>
          oldValue + newValue.valorUnitario * newValue.quantidade,
        0
      );
      this.amount = parseFloat(totalofValor).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    async getAllSuppliers() {
      const result = await ServiceSupplier.fillComobobox();
      this.allSupplier = result;
    },
    openModalImportXml() {
      this.$bvModal.show("modalImportXml");
      this.cfopSelect = this.dataPurchase.cfop;
    },

    clearInputs() {
      this.dataPurchase.id = "";
      this.dataPurchase.idFornecedor = "";
      this.dataPurchase.dataCompra = "";
      this.dataPurchase.numeroNfe = "";
      this.dataPurchase.modeloNfe = "";
      this.dataPurchase.serieNfe = "";
      this.dataPurchase.cfop = "";

      this.idProductForSelectBox = "";
      this.dataPurchase.quantidade = "";
      this.dataPurchase.valorUnitario = "";

      this.dataPurchase.dadosAdicionais = "";

      this.productsListGrid = [];
      this.idSupplier = "";
      this.SET_DATA_SEARCH_FROM_PURCHASE({});
    },

    clearProducts() {
      this.idProductForSelectBox = "";
      this.productsSelected = "";
      this.dataPurchase.valorUnitario = "";
      this.dataPurchase.quantidade = 1;
      this.dataPurchase.dadosAdicionais = "";
      this.estoque = "";
      this.$refs.valor.valueInput = "";
      this.$refs.estoque.valueInput = "";
    },

    async addProduct() {
      await this.saveAndUpdate();
      await this.assignSavePurchaseAndProducts();
    },

    assingEdit() {
      this.productsListGrid = this.dataSearchFromPurchase.products;
      this.dataPurchase.id = this.dataSearchFromPurchase.id;
      this.dataPurchase.idFornecedor = this.dataSearchFromPurchase.idFornecedor;
      this.dataPurchase.dataCompra = this.dataSearchFromPurchase.dataCompra;
      this.dataPurchase.numeroNfe = this.dataSearchFromPurchase.numeroNfe;
      this.dataPurchase.modeloNfe = this.dataSearchFromPurchase.modeloNfe;
      this.dataPurchase.serieNfe = this.dataSearchFromPurchase.serieNfe;
      this.dataPurchase.dadosAdicionais =
        this.dataSearchFromPurchase.dadosAdicionais;
      this.dataPurchase.cfop = this.dataSearchFromPurchase.cfop;
      this.dataPurchase.baseIcms = this.dataSearchFromPurchase.baseIcms;
      this.dataPurchase.vIcms = this.dataSearchFromPurchase.vIcms;
      this.dataPurchase.vIpi = this.dataSearchFromPurchase.vIpi;
      this.dataPurchase.vOutros = this.dataSearchFromPurchase.vOutros;
      this.dataPurchase.idNatureOp = this.dataSearchFromPurchase.idNatureOp;
      this.$refs.refNop.valueItems = this.dataSearchFromPurchase.idNatureOp;
    },

    async assignSavePurchaseAndProducts() {
      this.addProdutos.idCompra = this.dataPurchase.id;
      this.addProdutos.idProduto = this.productsSelected.id;
      this.addProdutos.valorUnitario = mascaraMoeda(
        this.dataPurchase.valorUnitario
      );
      this.addProdutos.quantidade = this.dataPurchase.quantidade;
      this.addProdutos.nomeProduto = this.dataPurchase.nomeProduto;
      await ServiceProductsPurchase.save(this.addProdutos);
      await this.getProductsForGrid();
      this.clearProducts();
    },

    async getProductsForGrid() {
      this.returnData = await ServiceProductsPurchase.getProductsForGrid(
        this.dataPurchase.id
      );

      // if (this.dataProduct.length !== 0) {

      //   this.productsListGrid = this.dataProduct.map((item) => ({
      //     valorUnitario: item.valor,
      //     quantidade: item.quantidade,
      //     nome: item.nome,
      //     id: item.id
      //   }));
      // } else {
      this.productsListGrid = this.returnData;
      // }

      this.sumValueTotal();
    },

    async deleteProductOfPurchase(item) {
      try {
        await ServiceProductsPurchase.delete(item.idProduto, item.id);
        this.getProductsForGrid();
        return this.$toast.open({
          message: "Produto Excluido",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message: "Opa, deu erro!",
          type: "error",
        });
      }
    },

    async searchPurchase(id) {
      return await ServicePurchase.findPurchaseById(id);
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    selectProductSetDefaultValue() {
      const currencyValueProduct =
        this.productsSelected.valorVenda.toLocaleString("pt-br", {
          minimumFractionDigits: 2,
        });
      this.dataPurchase.valorUnitario = currencyValueProduct;
      this.$refs.valor.valueInput = currencyValueProduct;

      this.estoque = this.productsSelected.estoque;
      this.$refs.estoque.valueInput = this.productsSelected.estoque;
    },

    async getAllNatures() {
      const result = await serviceNatureOps.fillComobobox();
      this.allNatures = result;
    },
  },
  computed: {
    ...mapState({
      dataSearchFromPurchase: (state) => state.dataSearchFromPurchase,
    }),
  },

  mounted() {
    this.getProdutos();
    this.getAllSuppliers();
    this.getAllNatures();
    if (this.dataSearchFromPurchase.id) {
      this.assingEdit();
    }
  },

  watch: {
    idSupplier() {
      this.getAllSuppliers();
      this.dataPurchase.idFornecedor = this.idSupplier;
    },
    async modalIdForPurchase() {
      this.dataPurchase.id = this.modalIdForPurchase;
      await this.getProductsForGrid();

      const result = await this.searchPurchase(this.modalIdForPurchase);
      this.dataPurchase.dataCompra = result.dataCompra;
      this.dataPurchase.numeroNfe = result.numeroNfe;
      this.dataPurchase.modeloNfe = result.modeloNfe;
      this.dataPurchase.serieNfe = result.serieNfe;
    },
    async dataEditPurchase() {
      Object.assign(this.dataPurchase, this.dataEditPurchase);
      await this.getProductsForGrid();
    },
  },
};
</script>
<style scoped>
.BoxFront {
  display: flex;
  margin-left: 30px;
  width: calc(100% - 30px);
  height: 100vh;
  font-size: 12px;
}

.contentFront {
  width: 45%;
  height: auto;
  margin-left: 40px;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.borderInput {
  border-radius: 3px;
  height: 28px;
  border: 1px solid #d5d3d3;
}

.screenProducts {
  align-items: center;
  background: white;
  cursor: pointer;
  display: flex;
  width: 2em;
  height: 2em;
  justify-content: center;
}

.fixedSelect {
  position: absolute;
  font-size: 10px;
  bottom: 15px;
  left: 10px;
  background-color: #ffffff;
  padding: 0 0.4em;
}

.borderInput {
  font-size: 14px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.input-group {
  width: 30%;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.alertModal {
  font-size: 11px;
  color: rgb(115, 0, 0);
  padding-left: 40px;
  margin: -15px;
}

.iconSelect {
  position: absolute;
  right: 0px !important;
  z-index: 10;
  background-color: #c1c1c1;
  color: whitesmoke;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}

.rowObservation {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  height: 40px;
  position: relative;
}

.input-group-ob {
  width: 100%;
}

.input-group {
  width: 30%;
}

.user-label-ob {
  position: absolute;
  margin-bottom: 0px;
  font-size: 11px;
  bottom: 22px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
}

.user-label {
  position: absolute;
  left: 10px;
  font-size: 11px;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

/* ----------------------------  */

.infoValue {
  display: flex;
  align-items: center;
}

.formFront {
  width: 95%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  margin-top: 10px;
  gap: 20px;
}

.content-form-btn {
  display: flex;
  width: 94%;
  justify-content: flex-end;
}

.form-btn {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background: #fbfbfb;
  border: 1px solid green;
  color: green;
  border-radius: 3px;
  width: 35%;
  height: 28px;
  margin-top: 40px;
  box-shadow: 0px 1px 1px 0px black;
}

.form-btn:hover {
  border: 1px solid green;
  color: green;
  background: whitesmoke;
}

.contentSketch {
  width: 50%;
  border-left: 2px solid #d5d3d3;
  height: auto;
  position: relative;
}

.tableProducts {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 30px;
  width: 100%;
  height: 55vh;
  max-height: 60vh;
  overflow: scroll;
  text-align: center;
  background: #fffcfc;
}

.styleTotal {
  display: flex;
  width: 100%;
  padding: 0 100px;
  background: #e3e9e6;
  font-size: 12px;
  height: 30px;
  gap: 2vw;
  font-weight: bold;
  justify-content: flex-end;
  align-items: center;
}

tbody {
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow: scroll;
}

tr:nth-child(even) {
  background: white;
}

tr {
  background: #fffcfc;
}

.rowProduct {
  width: 100%;
  height: 30px;
  border-bottom: 1px solid #f1efef;
}

.trashDelete {
  color: red;
  cursor: pointer;
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgb(238, 238, 238);
  padding: 2px 3px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.sketch-btn {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background: rgb(255, 255, 255);
  border: 1px solid green;
  color: green;
  border-radius: 3px;
  width: 180px;
  height: 28px;
  margin-top: 90px;
  gap: 10px;
  box-shadow: 0px 1px 1px 0px black;
}

.sketch-btn:hover {
  background: whitesmoke;
  border: 1px solid green;
  color: green;
}

.content-sketch-btn {
  bottom: 50px;
  position: relative;
  display: flex;
  justify-content: space-evenly;
}

@media screen and (max-width: 700px) {
  .BoxFront {
    display: flex;
    margin-left: 10px;
    width: 100%;
    height: 100vh;
    font-size: 12px;
    flex-direction: column;
  }

  .contentFront {
    width: 100%;
    height: auto;
    margin-left: 0px;
  }

  .contentSketch {
    width: 100%;
    border: none;
  }

  .form-btn {
    width: 50%;
    margin-top: 30px;
  }

  .tableProducts {
    padding: 10px;
  }
}
</style>