import api from './axios'

class ServiceMdfe {
  async saveMdfe(dataMdfe) {
    const { data } = await api.post('/mdfe', dataMdfe)
    return data
  }

  async updateMdfe(dataMdfe) {
    const { data } = await api.put(`/mdfe/${dataMdfe.id}`, dataMdfe)
    return data
  }

  async closeMdfe(dataMdfe, id) {
    const { data } = await api.put(`/mdfe/close/${id}`, dataMdfe)
    return data
  }

  async cancelMdfe(id, motivo) {
    const { data } = await api.put(`/mdfe/cancel/reason/${id}`, { motivo })
    return data
  }

  async delete(idMdfe) {
    await api.delete(`/mdfe/${idMdfe}`)
  }

  async findById(id) {
    const { data } = await api.get(`/mdfe/${id}`)
    return data;
  }

  async fetchingCep(cep) {
    const { data } = await api.get(`/mdfe/busca/cep/?cep=${cep}`)
    return data;
  }

  async sendMdfe(idVeiculo, idMdfe) {
    const { data } = await api.get(`/mdfe/send/veiculo/?idVeiculo=${idVeiculo}&idMdfe=${idMdfe}`)
    return data
  }

  async consultaMdfe(idMdfe) {
    const { data } = await api.get(`/mdfe/consult/mdfe/by/headers/?idMdfe=${idMdfe}`,
      {
        responseType: 'arraybuffer',
      }
    );
    return data
  }

  async searchMdfe(text, type, page = 1, startDate, endDate) {
    const { data } = await api.get(`/mdfe/find/per/month?q=${encodeURIComponent(text)}&type=${type}&page=${page}&startDate=${startDate}&endDate=${endDate}`)
    return data;
  }

}

export default new ServiceMdfe()