<template>
  <b-modal
    id="ModalAwaitPayment"
    class="containerModalPayment"
    hide-footer
    hide-header
    centered
    no-close-on-backdrop
    no-close-on-esc
  >
    <!-- <slot name="contentLoading"></slot> -->

    <div class="contentImg">
      <img
        style="width: 150px; height: 150px; margin-top: 1rem"
        :src="awaitingPayment"
        alt=""
      />

      <b-spinner class="spinner" label="Spinning" />

      <p>Aguardando pagamento . . .</p>

      <div>
        <button
          size="sm"
          class="btnCancelPayment"
          variant="none"
          @click="cancelPayment"
          :disabled="isLoading"
        >
          Cancelar Pagamento

          <b-spinner v-if="isLoading" class="spinnerCancel" label="Spinning" />
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ServiceMachine from "../../services/serviceMachine";
import awaitingPayment from "../../assets/awaitingPayment.png";
import { TypeSale } from "../../utils/enums/SalesType";

export default {
  props: {
    idSale: {
      type: String,
      default: "",
    },
    typeSale: {
      type: String,
      default: TypeSale.frenteDeCaixa,
    },
    typeIntegration: {
      type: String,
      default: "stone",
    },
  },
  data() {
    return {
      awaitingPayment: awaitingPayment,
      isLoading: false,
    };
  },
  methods: {
    ...mapMutations([
      "SET_INTERVAL_MACHINE_ID",
      "SET_SALE_INTERVAL_MACHINE_ID",
    ]),
    async cancelPayment() {
      try {
        this.isLoading = true;
        await ServiceMachine.cancelOrder(this.idSale, this.typeIntegration);

        this.handleClearIntervalIds();
        this.$emit("cancelPayment");

        this.$toast.open({
          message: "Operação Cancelada!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error.response.data.message ||
            "Ocorreu um problema ao cancelar a operação",
          type: "info",
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleClearIntervalIds() {
      if (this.typeSale === TypeSale.frenteDeCaixa) {
        clearInterval(this.intervalMachineId);
        this.SET_INTERVAL_MACHINE_ID("");
      } else {
        clearInterval(this.saleIntervalMachineId);
        this.SET_SALE_INTERVAL_MACHINE_ID("");
      }
    },
  },
  computed: {
    ...mapState({
      intervalMachineId: (state) => state.intervalMachineId,
      saleIntervalMachineId: (state) => state.saleIntervalMachineId,
    }),
  },
};
</script>

<style scoped>
.containerModalPayment {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.493);
}

.spinner {
  color: rgb(62, 102, 174);
  width: 12rem;
  height: 12rem;
  position: absolute;
  top: 7%;
}

.spinnerCancel {
  color: white;
  position: absolute;
  right: 5px;
  width: 20px;
  height: 20px;
}

.contentImg {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  position: relative;
}

.contentImg p {
  margin: 0;
  font-size: 17px;
  font-weight: 600;
  color: rgb(72, 184, 101);
}

.btnCancelPayment {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 12px;
  font-weight: normal;
  border: none;
  outline: none;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.179) !important;
  border-radius: 5px;
  transition: ease-in all 0.2s;
  cursor: pointer;
  padding: 0 2rem;
  background-color: brown;
  color: white;
  position: relative;
}

.btnCancelPayment:hover {
  background-color: rgb(136, 35, 35);
}
</style>