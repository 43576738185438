<template>
  <b-card>
    <div style="font-size: 12px">
      <div class="title">
        <span>Cadastro de Produto</span>
      </div>
      <div class="rowContainer">
        <span>
          <b-icon-box-seam />
          Informações Gerais
        </span>

        <b-button class="btnsAddComposition" @click="toggleEnableComposition">
          <b-icon-dash scale="1.5" class="mr-1" v-if="compositionIsEnaled" />
          <b-icon-plus scale="1.5" class="mr-1" v-else />
          composição
        </b-button>

        <div class="rowContent">
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataProducts.nome"
            />
            <label class="user-label"> Nome </label>
          </div>
          <div class="selectPlus">
            <label class="fixedSelect2">Unidade</label>
            <b-form-select
              class="borderInput shadow-none"
              value-field="value"
              text-field="text"
              :options="unidade"
              v-model="dataProducts.unidade"
            >
            </b-form-select>
          </div>
          <InputSelect
            label="Categoria"
            :items="categories"
            valueField="id"
            textField="nome"
            :dataSelect.sync="dataProducts.idCategoria"
            :openCreateModal="() => openModals('modalCategories')"
            ref="refCategories"
          />
        </div>

        <div
          :class="`rowContainerComposition ${
            compositionIsEnaled ? 'enabledComposition' : 'closeComposition'
          } mt-3`"
        >
          <ProductsComposition
            title="Composições do item"
            @findCompositions="dataProducts.composicao = $event"
            :clear="clearComposition"
            :compositionAfterEditProduct="dataProducts.composicao"
          />
        </div>
      </div>
      <div class="rowContainer">
        <span>
          <b-icon-card-checklist></b-icon-card-checklist> Valores e Cálculo de
          Custo
        </span>
        <div class="rowContent">
          <div class="input-group">
            <b-form-input
              required
              class="input borderInput"
              :value="maskCurrency(dataProducts.custoInicial)"
              @input="formatMoney($event, 'custoInicial')"
            />
            <label class="user-label" style="color: #00395d">
              Valor de Custo Inicial
            </label>
          </div>
          <div class="input-group">
            <b-form-input
              required
              class="input borderInput"
              :value="maskPercentage(percentFrete)"
              @input="formatPercente($event, 'percentFrete')"
            />
            <label class="user-label"> % Frete</label>
          </div>
          <div class="input-group">
            <b-form-input
              required
              class="input borderInput"
              :value="maskCurrency(dataProducts.frete)"
              @input="formatMoney($event, 'frete')"
            />
            <label class="user-label"> Valor de Frete</label>
          </div>
          <div class="input-group">
            <b-form-input
              required
              class="input borderInput"
              :value="maskPercentage(percentEncargos)"
              @input="formatPercente($event, 'percentEncargos')"
            />
            <label class="user-label"> % Encargos</label>
          </div>
          <div class="input-group">
            <b-form-input
              required
              class="input borderInput"
              :value="maskCurrency(dataProducts.encargos)"
              @input="formatMoney($event, 'encargos')"
            />
            <label class="user-label"> Valor de Encargos</label>
          </div>
        </div>
        <div class="rowContent">
          <div class="input-group">
            <b-form-input
              required
              class="input borderInput"
              :value="maskCurrency(dataProducts.valor)"
              @input="formatMoney($event, 'valor')"
            />
            <label class="user-label"> Valor de Custo Final </label>
          </div>
          <div class="input-group">
            <b-form-input
              required
              class="input borderInput"
              :value="maskPercentage(dataProducts.margem1)"
              @input="formatMoney($event, 'margem1')"
            />
            <label class="user-label"> % Margem de Lucro</label>
          </div>
          <div class="input-group">
            <b-form-input
              required
              class="input borderInput"
              :value="maskCurrency(dataProducts.lucro)"
              @input="formatMoney($event, 'lucro')"
            />
            <label class="user-label"> Valor Margem de Lucro</label>
          </div>
        </div>
        <div class="rowContent">
          <div class="input-group">
            <b-form-input
              required
              class="input borderInput"
              :value="maskCurrency(dataProducts.valorVenda)"
              @input="formatMoney($event, 'valorVenda')"
            />
            <label class="user-label" style="color: #00395d">
              Valor de Venda 1 ( Padrão )
            </label>
          </div>
          <div class="input-group">
            <b-form-input
              required
              class="input borderInput"
              :value="maskPercentage(dataProducts.margem2)"
              @input="formatMoney($event, 'margem2')"
            />
            <label class="user-label"> % Margem de Lucro 2</label>
          </div>
          <div class="input-group">
            <b-form-input
              required
              class="input borderInput"
              :value="maskCurrency(dataProducts.valorVenda2)"
              @input="formatMoney($event, 'valorVenda2')"
            />
            <label class="user-label"> Valor de Venda 2 ( Opcional )</label>
          </div>
          <div class="input-group">
            <b-form-input
              required
              class="input borderInput"
              :value="maskPercentage(dataProducts.margem3)"
              @input="formatMoney($event, 'margem3')"
            />
            <label class="user-label"> % Margem de Lucro 3</label>
          </div>
          <div class="input-group">
            <b-form-input
              required
              class="input borderInput"
              :value="maskCurrency(dataProducts.valorVenda3)"
              @input="formatMoney($event, 'valorVenda3')"
            />
            <label class="user-label"> Valor de Venda 3 ( Opcional )</label>
          </div>
        </div>
      </div>

      <div class="rowContainer">
        <span>
          <b-icon-card-checklist></b-icon-card-checklist> Estoque e
          identificação
        </span>
        <div class="rowContent">
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataProducts.codBarras"
            />
            <label class="user-label"> Cód de Barras </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataProducts.codReferencia"
            />
            <label class="user-label"> Cód de Referência</label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataProducts.localizacao"
            />
            <label class="user-label"> Localização Do Produto </label>
          </div>
          <div class="input-group" style="width: 15%">
            <input
              disabled
              class="input borderInput"
              v-model="dataProducts.estoque"
              type="number"
            />
            <label class="fixedSelect2"> Estoque </label>
          </div>
          <div class="input-group" style="width: 15%">
            <input
              required
              class="input borderInput"
              v-model="dataProducts.estoqueMin"
            />
            <label class="user-label"> Estoque Minímo </label>
          </div>
        </div>
      </div>
      <div class="rowContainer">
        <span>
          <b-icon-journal-code></b-icon-journal-code> Informações Fiscais</span
        >
        <div class="rowContentNcm">
          <InputSelect
            label="Lista de NCM"
            :items="ncms"
            valueField="codigo_ncm"
            textField="nomeNcm"
            :dataSelect.sync="dataProducts.ncm"
            :openCreateModal="() => openModals('modalNcms')"
            ref="refNcm"
          />
          <b-button
            class="questionNcmBtn"
            v-b-popover.hover.top="
              'O NCM é uma classificação de oito dígitos usada para categorizar produtos em transações comerciais dentro do Mercosul. Essencial para determinar impostos e tarifas de importação. Exemplo : 9503.60.00'
            "
          >
            <b-icon-question-circle-fill scale="1.2" />
          </b-button>
        </div>
        <div class="rowContent">
          <div class="selectPlus">
            <label class="fixedSelect2">Lista de CEST</label>
            <b-form-select
              class="borderInput shadow-none"
              value-field="cest"
              text-field="nomeCest"
              :options="cests"
              v-model="dataProducts.cest"
            >
            </b-form-select>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataProducts.nomeCest"
            />
            <label class="user-label"> Descrição CEST </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataProducts.cest"
              @input="handleNcmOrCest(dataProducts.cest)"
            />
            <label class="user-label"> Novo CEST </label>
            <b-button
              class="questionBtn"
              v-b-popover.hover.top="
                'O CEST é um código numérico que identifica produtos sujeitos à substituição tributária, facilitando o cálculo de impostos sobre eles. Exemplo : 28.064.00'
              "
            >
              <b-icon-question-circle-fill scale="1.2" />
            </b-button>
          </div>
        </div>
        <div class="rowRefFiscal">
          <div class="input-group-ob">
            <div class="selectPlus" style="width: 100%">
              <label class="fixedSelect2">REF. Fiscal</label>
              <b-form-select
                class="borderInput shadow-none"
                value-field="ref"
                :options="refsFiscaisFromSelectBox"
                text-field="descricao"
                v-model="dataProducts.refFiscal"
              >
              </b-form-select>
            </div>
          </div>
          <div style="width: 30%" class="refFiscalMobile">
            <b-button
              class="btnsRefFiscal"
              @click="openModals('modalTaxInformation')"
            >
              <b-icon-plus-square></b-icon-plus-square> Nova Info.Fiscal
            </b-button>
          </div>
        </div>
      </div>

      <div class="rowContainer" style="margin-bottom: 50px">
        <span> <b-icon-info-circle></b-icon-info-circle> Observações </span>
        <div class="rowObservation">
          <div class="input-group-ob">
            <input
              required
              class="input borderInput"
              v-model="dataProducts.descricao"
            />
            <label class="user-label-ob"> Observações </label>
          </div>
          <div class="selectPlus">
            <label class="fixedSelect2">Status</label>
            <b-form-select
              class="borderInput shadow-none"
              value-field="value"
              text-field="text"
              :options="status"
              v-model="dataProducts.status"
              :disabled="!this.dataProducts.id"
            >
            </b-form-select>
          </div>
        </div>
        <div class="btnsContainer">
          <b-button class="btnsSaveNeutral" @click="clear"
            >Novo <b-icon-arrow-clockwise class="ml-1"></b-icon-arrow-clockwise
          ></b-button>
          <div class="d-flex justify-content-end">
            <div v-if="!stockAdjustmentIsEnable">
              <b-button
                v-b-modal.modal-ajustStock
                style="background: #00aa62"
                class="btnsSave"
                ><b-icon-plus scale="1.5"></b-icon-plus> Ajustar estoque
              </b-button>
            </div>
            <!-- <div class="ajustStock">
              <div style="width: 90px" v-if="stockAdjustmentIsEnable">
                <b-form-input
                  id="input-1"
                  required
                  v-model="stockAmount"
                  size="sm"
                  type="number"
                ></b-form-input>
              </div>

              <div v-if="stockAdjustmentIsEnable" class="contentIconsStock">
                <div class="contentIcons">
                  <b-icon-check-circle-fill
                    class="iconsStockConfirm"
                    role="button"
                    scale="2"
                    @click="handleStockAdjustment"
                  />
                </div>

                <div class="contentIcons">
                  <b-icon-x-circle-fill
                    class="iconsStockCancel"
                    role="button"
                    scale="2"
                    @click="closeStockAdjustment"
                  />
                </div>
              </div>
            </div> -->
          </div>
          <b-button class="btnsSave" @click="saveOrUpdateProducts"
            >{{ dataProducts.id ? "Atualizar" : "Salvar" }}
            <b-icon-check2-circle class="ml-1"></b-icon-check2-circle
          ></b-button>
        </div>
      </div>
      <ModalTaxInformation @createdNewRef="createdNewRef = $event" />
      <ModalCreateCategory @getNewCategory="findAllCategories" />
      <ModalCreateNcm @getNewNcm="ncmCombobox" />
      <b-modal
        id="modal-ajustStock"
        hide-footer
        hide-header
        centered
        title="Stock"
      >
        <div>
          <h6 class="titleModal">Digite a nova quantidade do estoque:</h6>
          <div class="contentModal">
            <InputQuant
            style="width: 45%"
              label="Novo Estoque."
              type="text"
              :dataInput.sync="stockAmount"
              ref="newStock"
            />

            <div class="input-group" style="width: 45%">
              <b-form-input
                required
                disabled
                class="input borderInput"
                v-model="dataProducts.estoque"
              />
              <label class="fixedSelect2"> Estoque Atual </label>
            </div>
          </div>
          <div class="btnModal">
            <button class="btnContent" @click="handleStockAdjustment">
              Atualizar Estoque
            </button>
            <button
              class="btnContentReceive"
              @click="$bvModal.hide('modal-ajustStock')"
            >
              Cancelar
            </button>
          </div>
        </div>
      </b-modal>
    </div>
  </b-card>
</template>

<script>
import mixinProducts from "../../mixins/Products/Products";
export default {
  mixins: [mixinProducts],
};
</script>
<style scoped>
.containerCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  padding: 0px 0px 20px 0px;
  border-bottom: 1px solid rgb(231, 231, 231);
}

.form-control-sm {
  height: 28px;
  padding: 0.25rem 0.25rem;
  font-size: 12px;
  line-height: 1.3;
  border-radius: 0.2rem;
  color: red !important;
  border: none !important;
}

.custom-select-sm {
  height: 28px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 12px;
}

.contentIconsStock {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  max-height: 29px;
  width: 71px;
}

.contentIcons {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconsStockCancel {
  color: red;
}
.iconsStockConfirm {
  color: #00aa62;
}

.form-group {
  margin-bottom: 0.2rem;
}

span {
  font-weight: bold;
  cursor: context-menu;
}

.user-label {
  position: absolute;
  left: 10px;
  font-size: 11px;
  z-index: 30;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.user-label-ob {
  position: absolute;
  left: 40px;
  font-size: 11px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.inputDisabled {
  background-color: #fafafa;
  pointer-events: none;
  color: #949290 !important;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
  font-weight: 600;
}

.form-control:focus {
  background-color: #ffffff00;
  box-shadow: none !important;
  border: 1px solid black !important;
}

.form-control {
  border-radius: 5px !important;
}

.selectPlus {
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.questionBtn {
  display: flex;
  justify-content: center;
  height: 30px;
  position: absolute;
  right: 0px;
  font-size: 11px;
  z-index: 2;
  cursor: pointer;
  color: black;
  border-radius: 5px;
  border: none;
  background: #c3c3c3;
  align-items: center;
}
.questionNcmBtn {
  display: flex;
  justify-content: center;
  height: 30px;
  font-size: 11px;
  z-index: 2;
  cursor: pointer;
  color: black;
  border-radius: 5px;
  border: none;
  background: #c3c3c3;
  align-items: center;
}

.fixedSelect {
  position: absolute;
  margin-bottom: 0px;
  font-size: 10px;
  bottom: 24px;
  left: 20px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: transparent;
}
.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 10px;
  bottom: 22px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
  font-weight: 600;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 11px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: none;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.borderInput {
  font-size: 14px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050;
}

.iconSelect {
  position: absolute;
  right: 0px !important;
  z-index: 10;
  background-color: #c1c1c1;
  color: whitesmoke;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}

.title {
  font-size: 16px;
  color: rgb(95, 95, 95);
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(230, 230, 230);
  font-weight: 600;
}

.rowContainer {
  position: relative;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
}

.rowContainer span {
  font-weight: 600;
}

.rowContainerComposition {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  padding: 10px 10px 5px 10px;
  margin-bottom: 10px;
  transition: ease-in all 0.3s;
  /* margin-left: 3rem; */
}

.rowContainerComposition span {
  font-weight: 600;
  font-size: 11px;
}

.contentModal {
  display: flex;
  padding: 15px 0px 5px 0px;
  justify-content: space-around;
}

.btnModal {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.btnContent {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.titleModal {
  font-size: 1rem;
  border-bottom: 1px solid #e6e7e8;
  font-weight: bold;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.rowContentNcm {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: flex-start;
  gap: 10px;
}

.rowObservation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 10px 0 5px 0;
  gap: 30px;
}

.rowRefFiscal {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  height: 40px;
  gap: 30px;
}

.btnsContainer {
  gap: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.btnContainerComposition {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.btnsAddComposition {
  display: flex;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 10px;
  border-radius: 5px;
  background-color: #00395d;
  border: none;
  color: white;
  font-size: 11px;
  padding: 0.1rem 0.8rem;
}

.btnsSave {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
  font-weight: 600;
}

.ajustStock {
  display: flex;
  margin-top: 30px;
}

.btnsSaveNeutral {
  border-radius: 5px;
  background-color: #f9f9f9;
  width: 165px;
  border: 1px solid gray;
  margin-top: 30px;
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-weight: 600;
}
.btnsRefFiscal {
  border-radius: 5px;
  background-color: #d7d7d7;
  width: 100%;
  border: none;
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-weight: 600;
}

.input-group {
  width: 30%;
}

.input-group-ob {
  width: 70%;
}

.input-group-stock {
  width: 70%;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 1rem 0 1rem;
}

.card {
  border: none !important;
}

.enabledComposition {
  display: block;
}

.closeComposition {
  display: none;
  overflow: hidden;
}

@media screen and (max-width: 700px) {
  .rowContent {
    display: flex;
    width: 100%;
    padding: 5px 0 5px 0;
    justify-content: space-between;
    gap: 10px;
    flex-direction: column;
  }

  .input-group {
    width: 100%;
  }

  .input-group-ob {
    width: 50%;
  }

  .selectPlus {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }

  .refFiscalMobile {
    width: 50% !important;
  }

  .btn {
    padding: 0.375rem 0.5rem;
  }
}
</style>
