import api from './axios'

class ServiceNotaItem {
  async save(noteItem) {
    const { data } = await api.post('/nota-item', noteItem)
    return data
  }

  async findByIdNota(idNota) {
    const { data } = await api.get(`/nota-item/${idNota}`)
    return data
  }

  async findById(id) {
    const { data } = await api.get(`/nota-item/find-item/${id}`)
    return data
  }

  async updateNotaItems(nota) {
    await api.put(`/nota-item/update-item/${nota.id}`, nota)
  }

  async deleteFromNote(idItem) {
    await api.delete(`/nota-item/${idItem}`)
  }

  async deleteNotaAndItem(idNota) {
    await api.delete(`/nota-item/delete-all/${idNota}`)
  }
}

export default new ServiceNotaItem()