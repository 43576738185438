<template>
  <div class="GeneralFiscal" :style="colapseFiscal && 'overflow: hidden'">
    <div class="ContainerBtnFiscal">
      <b-button
        class="BtnFiscal"
        style="background: #403b74"
        @click="handleModals('nfe')"
      >
        <b-icon-plus scale="1.5"></b-icon-plus>
        Nova NF-e</b-button
      >
      <b-button
        class="BtnFiscal"
        style="background: #3e66ae"
        @click="handleModals('nfce')"
      >
        <b-icon-plus scale="1.5"></b-icon-plus>
        Nova NFC-e</b-button
      >

      <b-button
        class="BtnFiscal"
        style="background: #48b865"
        @click="handleModals('nfse')"
      >
        <b-icon-plus scale="1.5"></b-icon-plus>
        Nova NFS-e</b-button
      >
      <b-button
        class="BtnFiscal"
        style="background: #f2a83e"
        @click="handleModals('mdfe')"
      >
        <b-icon-plus scale="1.5"></b-icon-plus>
        Nova MDF-e</b-button
      >
    </div>

    <div class="lineSearch">
      <div class="searchByMonth">
        <div>Resumo Do Mês</div>
        <div class="pagMonth">
          <b-icon-chevron-left
            class="Arrows"
            id="btn-month-prev"
            @click="updateMonth('prev')"
          ></b-icon-chevron-left>
          <b-form-select
            class="searchMonth custom-select3"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="monthSelected"
            :options="filterMonth"
            @change="monthHasChanged"
          ></b-form-select>
          <b-form-select
            class="searchYear custom-select3"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="yearSelected"
            :options="filterYear"
            @change="monthHasChanged"
          ></b-form-select>
          <b-icon-chevron-right
            class="Arrows"
            style="margin: 0 0 0 7px"
            id="btn-month-next"
            @click="updateMonth('next')"
          ></b-icon-chevron-right>
        </div>
      </div>
    </div>

    <div class="SummaryFiscal">
      <div
        class="ItemSummaryFiscal"
        style="border: 3px ridge #403b74"
        @click="handleActiveTable('nfe')"
      >
        <span>Valor total de NF-e Emitidas</span>
        <span>Em Produção</span>
        <span>
          {{
            this.totalNfe.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </span>
      </div>
      <div
        class="ItemSummaryFiscal"
        style="border: 3px ridge #3e66ae"
        @click="handleActiveTable('nfce')"
      >
        <span>Valor total de NFC-e Emitidas</span>
        <span>Em Produção</span>
        <span>
          {{
            this.totalNfce.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </span>
      </div>
      <div
        class="ItemSummaryFiscal"
        style="border: 3px ridge #48b865"
        @click="handleActiveTable('nfse')"
      >
        <span>Valor total de NFS-e Emitidas</span>
        <span>Em Produção</span>
        <span>
          {{
            this.TotalInvoiced.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </span>
      </div>
      <div
        class="ItemSummaryFiscal"
        style="border: 3px ridge #f2a83e"
        @click="handleActiveTable('mdfe')"
      >
        <span>MDF-e Emitidas (Em Produção)</span>
        <span>
          {{
            this.totalMdfe.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </span>
      </div>
    </div>

    <div
      class="ContainerSearchInput"
      v-if="tableActive.nfe || tableActive.nfce"
    >
      <div class="textRegister">
        <span>Notas Registradas</span>
      </div>
      <div class="searchContainer">
        <div class="searchAvancedContent">
          <button
            v-if="!isAdvancedSearch"
            class="searchAvanced"
            @click="enableAdvancedSearch"
          >
            Realizar Pesquisa Avançada
          </button>
          <button
            v-if="isAdvancedSearch"
            class="searchAvanced"
            @click="enableAdvancedSearch"
          >
            Pesquisa por mês
          </button>
        </div>
        <div class="searchInput" v-if="isAdvancedSearch">
          <button class="btnPeriod" size="sm">Período</button>
          <div class="dateInput">
            <input
              type="date"
              id="date"
              v-model="startDate"
              style="
                padding: 0.25rem 0.25rem 0.25rem 0.5rem;
                cursor: pointer;
                border-left: none;
                border-radius: 0px 5px 5px 0px;
              "
            />
            <input
              type="date"
              id="date"
              v-model="endDate"
              style="padding: 0.25rem 1.25rem 0.25rem 0.25rem; cursor: pointer"
            />
          </div>
        </div>
        <div class="searchInput">
          <b-form-select
            class="searchFilter"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="type"
            :options="filterCombobox"
          ></b-form-select>
          <div>
            <b-input
              placeholder="Pesquisar por..."
              v-model="textPesquisa"
              @keyup.enter="searchOrClear(page)"
            ></b-input>
          </div>
          <b-button size="sm" @click="searchOrClear()" class="searchBtn">
            <b-icon-search></b-icon-search>
          </b-button>
        </div>
        <button class="searchAll" @click="searchOrClear(page)">ver tudo</button>
      </div>
    </div>

    <div class="ContentTable" v-if="tableActive.nfe || tableActive.nfce">
      <table>
        <thead
          style="
            color: whitesmoke;
            background: #3a4750;
            text-align: center;
            height: 28px;
          "
        >
          <tr style="width: 100%">
            <th style="width: 5%">Data Emissão</th>
            <th style="width: 5%">N°</th>
            <th style="width: 5%">Série</th>
            <th style="width: 5%">Cliente</th>
            <th style="width: 5%">Tipo</th>
            <th style="width: 5%">Status</th>
            <th style="width: 5%">Valor</th>
            <th style="width: 5%">Ações</th>
          </tr>
        </thead>
        <tbody
          v-if="this.dataLengthNote > 0"
          style="color: #3a4750; width: 100%"
        >
          <tr
            v-for="dataSearchNota in dataNota"
            :key="dataSearchNota.id"
            style="text-align: center; height: 30px"
          >
            <td style="width: 15%">{{ dataSearchNota.data | moment }}</td>
            <td style="width: 13%" v-if="dataSearchNota.response">
              {{ dataSearchNota.response.nfe || "______" }}
            </td>
            <td style="width: 13%" v-else>--</td>
            <td style="width: 13%" v-if="dataSearchNota.response == null">
              --
            </td>
            <td style="width: 13%" v-else>
              {{ dataSearchNota.response.serie }}
            </td>
            <td style="width: 16%">
              {{ dataSearchNota.nomeCliente || "______" }}
            </td>
            <td style="width: 12%" v-if="dataSearchNota.modelo === '1'">
              NF-e
            </td>
            <td style="width: 12%" v-else>NFC-e</td>
            <td style="width: 15%">{{ dataSearchNota.status }}</td>
            <td style="width: 15%">
              {{
                dataSearchNota.total
                  ? dataSearchNota.total.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  : "R$ 0,00"
              }}
            </td>
            <td class="actiontd">
              <div class="btnActions">
                <b-dropdown
                  id="dropdown-1"
                  text="Ações"
                  menu-class="w-1"
                  class="btnActions"
                  size="sm"
                  variant="none"
                >
                  <template #button-content>
                    <b-icon-gear-fill class="contentActions"></b-icon-gear-fill>
                  </template>
                  <b-dropdown-item
                    v-if="
                      dataSearchNota.modelo === '2' &&
                      dataSearchNota.status === 'Não Enviada'
                    "
                    @click="sendCoupon(dataSearchNota.idVenda)"
                  >
                    Emitir NFC-e
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="dataSearchNota.status !== 'Emitida'"
                    @click="
                      dataSearchNota.modelo === '2'
                        ? editBoxFront(dataSearchNota.idVenda)
                        : editNfe(dataSearchNota.id)
                    "
                  >
                    Editar
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-b-modal.modal-center3
                    @click="getIdDocumentoToDelete(dataSearchNota.id)"
                  >
                    Excluir
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="dataSearchNota.status === 'Corrigida'"
                    @click="
                      openPdf(dataSearchNota.response_carta_correcao.dacce)
                    "
                  >
                    Imprimir Correção
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="findDetails(dataSearchNota.id)"
                    v-b-modal.modal-center2
                    v-if="dataSearchNota.status !== 'Não Enviada'"
                  >
                    Detalhes
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <td colspan="8">
            <div class="defaultSearch">
              <span>Ops!... nenhum resultado encontrado</span>
            </div>
          </td>
        </tbody>
        <div v-if="spinGeneral" class="loadingLazy">
          <div class="loader"></div>
        </div>
      </table>
    </div>
    <div class="ContainerSearchInput" v-if="tableActive.nfse">
      <div class="textRegister">
        <span>Notas de serviço Registradas</span>
      </div>
      <div class="searchContainer">
        <div class="searchAvancedContent">
          <button
            v-if="!isAdvancedSearch"
            class="searchAvanced"
            @click="enableAdvancedSearch"
          >
            Realizar Pesquisa Avançada
          </button>
          <button
            v-if="isAdvancedSearch"
            class="searchAvanced"
            @click="enableAdvancedSearch"
          >
            Pesquisa por mês
          </button>
        </div>
        <div class="searchInput" v-if="isAdvancedSearch">
          <button class="btnPeriod" size="sm">Período</button>
          <div class="dateInput">
            <input
              type="date"
              id="date"
              v-model="startDate"
              style="
                padding: 0.25rem 0.25rem 0.25rem 0.5rem;
                cursor: pointer;
                border-left: none;
                border-radius: 0px 5px 5px 0px;
              "
            />
            <input
              type="date"
              id="date"
              v-model="endDate"
              style="padding: 0.25rem 1.25rem 0.25rem 0.25rem; cursor: pointer"
            />
          </div>
        </div>
        <div class="searchInput">
          <b-form-select
            class="searchFilter"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="type"
            :options="filterCombobox"
          ></b-form-select>
          <div>
            <b-input
              placeholder="Pesquisar por..."
              v-model="textPesquisa"
              @keyup.enter="filterPerMonth()"
            ></b-input>
          </div>
          <b-button size="sm" @click="filterPerMonth()" class="searchBtn">
            <b-icon-search></b-icon-search>
          </b-button>
        </div>
        <button class="searchAll" @click="searchOrClearNfse()">ver tudo</button>
      </div>
    </div>
    <div class="ContainerSearchInput" v-if="tableActive.mdfe">
      <div class="textRegister">
        <span>Manifestos Registrados</span>
      </div>
      <div class="searchContainer">
        <div class="searchAvancedContent">
          <button
            v-if="!isAdvancedSearch"
            class="searchAvanced"
            @click="enableAdvancedSearch"
          >
            Realizar Pesquisa Avançada
          </button>
          <button
            v-if="isAdvancedSearch"
            class="searchAvanced"
            @click="enableAdvancedSearch"
          >
            Pesquisa por mês
          </button>
        </div>
        <div class="searchInput" v-if="isAdvancedSearch">
          <button class="btnPeriod" size="sm">Período</button>
          <div class="dateInput">
            <input
              type="date"
              id="date"
              v-model="startDate"
              style="
                padding: 0.25rem 0.25rem 0.25rem 0.5rem;
                cursor: pointer;
                border-left: none;
                border-radius: 0px 5px 5px 0px;
              "
            />
            <input
              type="date"
              id="date"
              v-model="endDate"
              style="padding: 0.25rem 1.25rem 0.25rem 0.25rem; cursor: pointer"
            />
          </div>
        </div>
        <div class="searchInput">
          <b-form-select
            class="searchFilter"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="type"
            :options="filterCombobox"
          ></b-form-select>
          <div>
            <b-input
              placeholder="Pesquisar por..."
              v-model="textPesquisa"
              @keyup.enter="filterPerMonthMdfe()"
            ></b-input>
          </div>
          <b-button size="sm" @click="filterPerMonthMdfe()" class="searchBtn">
            <b-icon-search></b-icon-search>
          </b-button>
        </div>
        <button class="searchAll" @click="searchOrClearMdfe()">ver tudo</button>
      </div>
    </div>
    <ProvisionServiceTable
      v-if="tableActive.nfse"
      :page="page"
      :dataFilterNfse="dataFilterNfse"
      @openToEdit="handleModals($event)"
      @tableLength="dataLengthNfse = $event"
    />

    <MdfeTable
      v-if="tableActive.mdfe"
      :page="page"
      :dataFilterMdfe="dataFilterMdfe"
      @openToEdit="handleModals($event)"
      @tableLength="dataLengthMdfe = $event"
    />

    <b-modal
      id="modal-center3"
      hide-footer
      hide-header
      centered
      title="Excluir"
    >
      <div>
        <h6 class="titleModal">Deseja excluir essa movimentação ?</h6>
        <div class="btnModal-action-nfe">
          <button class="btnContent-action-nfe" @click="deleteNfeNfce">
            Deletar
          </button>
          <button
            class="btnContentReceive-action-nfe"
            @click="$bvModal.hide('modal-center3')"
          >
            Não, Obrigado!
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-center2"
      hide-footer
      hide-header
      centered
      size="lg"
      title="Detalhes"
      @hidden="clearValuesToCancelDocument"
    >
      <div style="position: relative">
        <h6 class="titleModal">Detalhes da movimentação</h6>

        <span class="closeModalDetails" @click="$bvModal.hide('modal-center2')"
          >X</span
        >
        <div class="contentModal">
          <span> <b-icon-truck></b-icon-truck> Dados Cliente</span>
          <div class="rowModal">
            <div class="input-group-supplier">
              <input disabled class="input borderInput" v-model="client.nome" />
              <label class="user-label"> Nome </label>
            </div>
            <div class="input-group-supplier">
              <input
                disabled
                class="input borderInput"
                v-model="client.cpfCnpj"
              />
              <label class="user-label"> CNPJ/CPF </label>
            </div>
          </div>
        </div>
        <div class="contentModal">
          <span> <b-icon-file-text></b-icon-file-text> Dados da NF-e</span>
          <div class="rowModal">
            <div class="input-group">
              <input
                disabled
                type="datetime"
                class="input borderInput"
                v-model="dataDetails.data_nfe"
              />
              <label class="user-label"> Data da Emissão </label>
            </div>
            <div v-if="dataDetails.response" class="input-group">
              <input
                disabled
                class="input borderInput"
                v-model="dataDetails.response.nfe"
              />
              <label class="user-label"> N° NF-e </label>
            </div>
            <div v-if="dataDetails.response" class="input-group">
              <input
                disabled
                class="input borderInput"
                v-model="dataDetails.response.modelo"
              />
              <label class="user-label"> Modelo </label>
            </div>
            <div v-if="dataDetails.response" class="input-group">
              <input
                disabled
                class="input borderInput"
                v-model="dataDetails.response.serie"
              />
              <label class="user-label"> Série </label>
            </div>
          </div>
          <div v-if="dataDetails.response" class="rowModal">
            <div class="input-group-key">
              <b-form-input
                ref="chaveNfeInput"
                disabled
                class="input borderInput"
                v-model="dataDetails.response.chave"
              />
              <label class="user-label"> Chave da NF-e </label>
            </div>
            <button
              class="iconCopy"
              @click="copyChaveNfeToClipboard"
              style="border: none"
            >
              <b-icon-stickies scale="1" class="mr-1"></b-icon-stickies>
              Copiar
            </button>
          </div>

          <div class="rowModal">
            <button
              @click="redirectSefaz"
              class="btnContent-details-action-nfe"
            >
              Ir para a Sefaz
            </button>
          </div>
        </div>
        <div class="contentModal">
          <span> <b-icon-box-seam></b-icon-box-seam> Itens Importados </span>
          <div class="ContentTableModal">
            <table style="width: 100%">
              <thead
                style="
                  color: whitesmoke;
                  background: #3a4750;
                  text-align: center;
                  height: 29px;
                "
              >
                <tr style="width: 100%">
                  <th>Nome</th>
                  <th>Quantidade</th>
                  <th>Valor</th>
                  <th>Valor Total</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="product in itensNote"
                  style="text-align: center; height: 28px"
                  :key="product.id"
                >
                  <td>{{ product.nome }}</td>
                  <td>{{ product.quantidade }}</td>
                  <td>
                    {{
                      product.subtotal.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}
                  </td>
                  <td>
                    {{
                      (product.quantidade * product.subtotal).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="btnModal-action-nfe">
          <button
            v-if="dataDetails.response"
            @click="handlePrints(dataDetails.id, dataDetails.modelo)"
            class="btnContent-details-action-nfe"
            :disabled="isLoadingToPrint"
          >
            Abrir PDF

            <b-spinner v-if="isLoadingToPrint" class="spinnerPrint" />
          </button>
          <button
            v-if="dataDetails.response"
            @click="openTick(dataDetails.response.danfe_etiqueta)"
            class="btnContent-details-action-nfe"
          >
            Imprimir Etiqueta
          </button>
          <button
            v-if="
              dataDetails.status === 'Emitida' ||
              dataDetails.status === 'Corrigida'
            "
            class="btn-cancel-document"
            @click="openModalCancelDocument"
          >
            Cancelar Documento
          </button>
          <button
            v-if="
              dataDetails.status !== 'Não Enviada' &&
              dataDetails.status !== 'Cancelada' && dataDetails.modelo === '1'
            "
            class="btn-correction-letter-document"
            @click="openModalCorrectionLetter"
          >
            Carta de correção
          </button>
        </div>
      </div>
    </b-modal>

    <div class="setBtn">
      <button class="itemBtn" :disabled="page == 1" @click="previousPage(page)">
        <b-icon-chevron-left></b-icon-chevron-left>
      </button>
      <button class="centerItemBtn">{{ page }}</button>
      <button
        class="itemBtn"
        :disabled="dataLengthNote < 9"
        @click="nextPage(page)"
      >
        <b-icon-chevron-right></b-icon-chevron-right>
      </button>
    </div>

    <div :class="sizeModal">
      <div :class="modalContainerGeneral" v-if="colapseFiscal">
        <b-button class="closeModal" @click="modalHeight"> X </b-button>
        <NotaFiscal
          class="sizeFiscal"
          v-if="typeFiscal.nfe"
          :dataNFeAfterTransformSaleToNfe="dataNFeAfterTransformSaleToNfe"
        />
        <BoxFront v-if="typeFiscal.nfce" style="margin-top: 30px" />
        <ProvisionService
          :model="nfseModel.NFSE"
          v-if="typeFiscal.nfse"
          style="margin-top: 30px"
        />
        <Mdfe
          class="sizeFiscal"
          v-if="typeFiscal.mdfe"
          style="margin-top: 30px"
        />
      </div>
    </div>
    <Loading v-if="spinLoading" />
    <ModalCancelNota
      :idNota="{
        uuidNotaWebMania,
        idNota,
      }"
      @getNotaAfterCanceled="handleDocumentAfterModifyNota"
    />
    <ModalCorrectionLetter
      :idNota="idNota"
      @getNotaAfterCanceled="handleDocumentAfterModifyNota"
    />
  </div>
</template>
<script>
import { DateTime } from "luxon";
import { mapMutations, mapState } from "vuex";
import NotaFiscal from "../NotaFiscal/NotaFiscal.vue";
import BoxFront from "../../components/BoxFront/Index-BoxFront.vue";
import ProvisionServiceTable from "./ProvisionServiceTable.vue";
import MdfeTable from "./MdfeTable.vue";
import ProvisionService from "../ProvisionService/ProvisionService.vue";
import Mdfe from "../Mdfe/Mdfe.vue";
import Loading from "../Loading/Loading.vue";
import ModalCancelNota from "../../components/NotaFiscal/ModalCancelNota.vue";
import ModalCorrectionLetter from "../../components/NotaFiscal/ModalCorrectionLetter.vue";

import mixinSearchNotaFiscal from "../../mixins/NotaFiscal/Search";
import ServiceCustomer from "../../services/serviceCustomer";
import ServiceNotaFiscal from "../../services/serviceNotaFiscal";
import ServiceNotaItem from "../../services/serviceNotaItem";
import ServiceBill from "../../services/serviceBill";
import ServiceSale from "../../services/serviceSale";
import serviceMdfe from "../../services/serviceMdfe";

import api from "../../services/axios";
import { BillColumnsToSearchById } from "../../utils/enums/BillColumnsToSearchById";
import { NfseModel } from "../../utils/enums/NfseModel";
import { HandleErrors } from "../../utils/handleErrors";
import { TypeErrors } from "../../utils/enums/TypeErrors";
import { TypeSale } from "../../utils/enums/SalesType";
import { formatValuesFromSales } from "../../helpers/generalSales";
import serviceNotaFiscal from "../../services/serviceNotaFiscal";

export default {
  mixins: [mixinSearchNotaFiscal],
  components: {
    NotaFiscal,
    ProvisionService,
    BoxFront,
    Mdfe,
    ProvisionServiceTable,
    MdfeTable,
    Loading,
    ModalCancelNota,
    ModalCorrectionLetter,
  },
  props: {
    initialMonth: {
      type: Date,
      default: () => new Date(),
    },
  },
  data() {
    return {
      year: DateTime.local().year,
      month: DateTime.local(),
      dataNFeAfterTransformSaleToNfe: {},
      textPesquisa: "",
      type: "",
      startDate: "",
      endDate: "",
      monthSelected: "",
      yearSelected: "",
      isAdvancedSearch: false,
      spinLoading: false,
      totalNfe: 0,
      totalNfce: 0,
      TotalInvoiced: 0,
      totalMdfe: 0,
      dataDetails: [],
      dataFilterNfse: [],
      dataFilterMdfe: [],
      uuidNotaWebMania: "",
      idNota: "",
      client: [],
      itensNote: [],
      page: 1,
      dataLengthNote: 0,
      dataLengthNfse: 0,
      dataLengthMdfe: 0,
      dataNota: [],
      model: 1,
      filterCombobox: [
        { value: "nomeCliente", text: "Cliente" },
        { value: "numero", text: "Nº Operação" },
        { value: "", text: "Selecione ..." },
      ],
      filterMonth: [
        { value: "1", text: "Janeiro" },
        { value: "2", text: "Fevereiro" },
        { value: "3", text: "Março" },
        { value: "4", text: "Abril" },
        { value: "5", text: "Maio" },
        { value: "6", text: "Junho" },
        { value: "7", text: "Julho" },
        { value: "8", text: "Agosto" },
        { value: "9", text: "Setembro" },
        { value: "10", text: "Outubro" },
        { value: "11", text: "Novembro" },
        { value: "12", text: "Dezembro" },
      ],
      filterYear: [
        { value: "2021", text: "2021" },
        { value: "2022", text: "2022" },
        { value: "2023", text: "2023" },
        { value: "2024", text: "2024" },
        { value: "2025", text: "2025" },
        { value: "2026", text: "2026" },
        { value: "2027", text: "2027" },
        { value: "2028", text: "2028" },
        { value: "2029", text: "2029" },
        { value: "2030", text: "2030" },
        { value: "2031", text: "2031" },
        { value: "2032", text: "2032" },
      ],
      tableActive: {
        nfe: true,
        nfce: false,
        nfse: false,
      },
      responseNfeWebMania: {
        chave: "",
        danfe: "",
        danfe_etiqueta: "",
        danfe_simples: "",
        log: "",
        modelo: "",
        motivo: "",
        nfe: "",
        serie: "",
        status: "",
        uuid: "",
        xml: "",
      },
      nfseModel: NfseModel,
      idDocumentoToDelete: null,
      isLoadingToPrint: false,
    };
  },
  methods: {
    ...mapMutations([
      "ALTER_SIZE_MODAL",
      "ALTER_TYPE_FISCAL",
      "SET_DATA_NFE",
      "SET_DEFAULT_TYPE_FISCAL",
      "SET_COLAPSE_FISCAL",
      "ALTER_MODAL_CONTAINER_GENERAL",
      "SET_DATA_SEARCH_FROM_BOX_FRONT",
    ]),

    async modalHeight() {
      this.SET_COLAPSE_FISCAL(!this.colapseFiscal);

      this.colapseFiscal
        ? this.ALTER_SIZE_MODAL("ModalGeneral")
        : this.ALTER_SIZE_MODAL("disable");

      this.modalContainerGeneral
        ? this.ALTER_MODAL_CONTAINER_GENERAL("modalContainer")
        : this.ALTER_MODAL_CONTAINER_GENERAL("contentModalDisable");

      if (!this.colapseFiscal) {
        this.dataNFeAfterTransformSaleToNfe = {};
        this.SET_DEFAULT_TYPE_FISCAL();
      }
      await this.filterNotesPerMonth();
      await this.filterPerMonth();
      await this.filterPerMonthMdfe();

      if (this.tableActive.nfce || this.tableActive.nfe) {
        this.filterNota(1);
      }
    },

    async filterNotesPerMonth() {
      try {
        const { data } = await api.get(
          `/nota/find/and/sum/notes/per/month/search?month=${this.month.month}&year=${this.year}`
        );
        const notes = data?.data ?? [];
        this.totalNfe = this.getTotalNoteByType(notes, "1");
        this.totalNfce = this.getTotalNoteByType(notes, "2");
      } catch (error) {
        console.log(error);
      }
    },

    async filterNota() {
      if (this.tableActive.nfce) {
        this.model = "2";
      } else {
        this.model = "1";
      }
      try {
        this.spinGeneral = true;
        const { data } = await api.get(
          `/nota/find/all/notes/by/table/per/month/search?month=${
            this.month.month
          }&year=${this.year}&q=${encodeURIComponent(this.textPesquisa)}&type=${
            this.type
          }&model=${this.model}&page=${this.page}&isAdvancedSearch=${
            this.isAdvancedSearch
          }&startDate=${this.startDate}&endDate=${this.endDate}`
        );

        this.dataLengthNote = data.data.notes.length;
        this.dataNota = data.data.notes;
      } catch (error) {
        this.$toast.open({
          message: "Não foi possível listar as notas",
          type: "info",
        });
      } finally {
        this.spinGeneral = false;
      }
      if (this.dataLengthNote < 9) {
        this.clearSearch();
      }
    },

    async filterPerMonth() {
      try {
        const { data } = await api.get(
          `/nfse/per/month/search?month=${this.month.month}&year=${
            this.year
          }&model=${NfseModel.NFSE}&q=${encodeURIComponent(
            this.textPesquisa
          )}&type=${this.type}&page=${this.page}&isAdvancedSearch=${
            this.isAdvancedSearch
          }&startDate=${this.startDate}&endDate=${this.endDate}`
        );
        this.dataLengthNfse = data.data.length;
        this.dataFilterNfse = data.data;
        this.TotalInvoiced = this.getTotalOrderByType(
          this.dataFilterNfse,
          "Faturado"
        );
      } catch (error) {
        console.log(error);
      }
    },

    async filterPerMonthMdfe() {
      try {
        const { data } = await api.get(
          `/mdfe/find/per/month/?month=${this.month.month}&year=${
            this.year
          }&q=${encodeURIComponent(this.textPesquisa)}&type=${this.type}&page=${
            this.page
          }&isAdvancedSearch=${this.isAdvancedSearch}&startDate=${
            this.startDate
          }&endDate=${this.endDate}`
        );
        this.dataLengthMdfe = data.data.length;
        this.dataFilterMdfe = data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async searchAll() {
      try {
        const data = await serviceMdfe.searchMdfe(
          "",
          "",
          1,
          this.startDate,
          this.endDate
        );
        this.totalMdfe = this.getTotalMdfeByType(data.data.mdfes);
      } catch (error) {
        this.$toast.open({
          message: error?.reponse?.data?.message || "Ocorreu um erro ao listar",
          type: "info",
        });
      }
    },

    handleTypeMdfe() {
      this.type = "";
      this.textPesquisa = "";

      this.filterPerMonthMdfe();
    },

    getTotalMdfeByType(mdfes) {
      let dataArray = [];
      if (Array.isArray(mdfes)) {
        dataArray = mdfes;
      } else if (mdfes && typeof mdfes === "object") {
        dataArray = Object.values(mdfes);
      }
      return dataArray
        .filter((mdfe) => mdfe.status === "aprovado")
        .reduce((total, mdfe) => total + mdfe.valor_carga, 0);
    },

    async enableAdvancedSearch() {
      this.isAdvancedSearch = !this.isAdvancedSearch;
      if (!this.isAdvancedSearch) {
        this.startDate = "";
        this.endDate = "";
      }
    },

    monthHasChanged() {
      this.month = this.month.set({ month: parseInt(this.monthSelected) });
      this.year = this.yearSelected;
      this.filterNotesPerMonth();
      this.filterNota();
      this.filterPerMonth();
      this.filterPerMonthMdfe();
    },

    getTotalNoteByType(notes, type) {
      return notes
        .filter((note) => note.modelo === type && note.status === "Emitida")
        .reduce((total, note) => total + note.total, 0);
    },

    getTotalOrderByType(orders, type) {
      return orders
        .filter((order) => order.status === type)
        .reduce((total, order) => total + order.valorTotal, 0);
    },

    async updateMonth(direction) {
      if (direction === "prev") {
        this.month = this.month.minus({ months: 1 });
        this.year = this.yearSelected;
        this.monthSelected = this.month.month;
      } else if (direction === "next") {
        this.month = this.month.plus({ months: 1 });
        this.year = this.yearSelected;
        this.monthSelected = this.month.month;
      }

      this.clearSumMonth();
      await this.filterNotesPerMonth();
      await this.filterNota();
      await this.filterPerMonth();
      await this.filterPerMonthMdfe();
    },

    openModalCancelDocument() {
      this.uuidNotaWebMania = this.dataDetails.response.uuid;
      this.idNota = this.dataDetails.id;

      this.$bvModal.show("modalCancelNota");
    },

    openModalCorrectionLetter() {
      this.idNota = this.dataDetails.id;

      this.$bvModal.show("modalCorrectionLetter");
    },

    clearValuesToCancelDocument() {
      this.uuidNotaWebMania = "";
      this.idNota = "";
    },

    async handleDocumentAfterModifyNota() {
      await this.findDetails(this.dataDetails.id);
      await this.filterNota(1);
      this.page = 1;
    },

    clearSumMonth() {
      this.totalNfe = "R$ 0,00";
      this.totalNfce = "R$ 0,00";
    },

    copyChaveNfeToClipboard() {
      const inputElement = this.$refs.chaveNfeInput;
      try {
        navigator.clipboard.writeText(inputElement.value);
        this.$toast.open({
          message: "Chave da NF-e copiada para a área de transferência.",
          type: "success",
        });
      } catch (err) {
        this.$toast.open({
          message: "Não foi possível copiar a chave da NF-e.",
          type: "info",
        });
      }
    },

    handleModals(name) {
      this.ALTER_TYPE_FISCAL(name);
      this.modalHeight();
    },

    handleActiveTable(name) {
      this.tableActive = {
        nfe: false,
        nfce: false,
        nfse: false,
        mdfe: false,
        [name]: true,
      };

      if (name === "mdfe") {
        this.handleTypeMdfe();
        this.page = 1;
      } else {
        this.filterNota();
      }
    },

    handleDataNFeAfterTransformSaleToNfe() {
      this.dataNFeAfterTransformSaleToNfe = this.dataNFe;
      this.ALTER_TYPE_FISCAL("nfe");
      this.modalHeight();
      this.SET_DATA_NFE({});
    },

    async editNfe(id) {
      const result = await ServiceNotaFiscal.findNotaById(id);
      const products = await ServiceNotaItem.findByIdNota(id);
      const bills = await ServiceBill.getAllBillsByParamsId(
        id,
        BillColumnsToSearchById.idNota
      );
      result.products = products.noteItem;
      result.bills = bills;

      if (result.modelo === "1") {
        this.ALTER_TYPE_FISCAL("nfe");
        this.modalHeight();
        this.SET_DATA_NFE(result);
      } else {
        this.ALTER_TYPE_FISCAL("nfce");
        this.modalHeight();
        this.SET_DATA_NFE(result);
      }
    },

    async editBoxFront(idSale) {
      const sale = await ServiceSale.findById(idSale);

      const formatedBoxFront = formatValuesFromSales(
        TypeSale.frenteDeCaixa,
        sale
      );

      this.SET_DATA_SEARCH_FROM_BOX_FRONT(formatedBoxFront);

      this.ALTER_TYPE_FISCAL("nfce");
      this.modalHeight();
    },

    async findDetails(id) {
      this.dataDetails = await ServiceNotaFiscal.findNotaById(id);
      const date = this.dataDetails.data_nfe
        .split(" ")[0]
        .split("-")
        .reverse()
        .join("/");
      const hours = this.dataDetails.data_nfe.split(" ")[1];

      this.dataDetails.data_nfe = `${date} ${hours}`;
      this.client = await ServiceCustomer.findById(this.dataDetails.idCliente);
      const result = await ServiceNotaItem.findByIdNota(id);
      this.itensNote = result.noteItem;
    },

    searchOrClear() {
      this.filterNota();
      this.clearSearch();
    },

    searchOrClearNfse(page) {
      this.filterPerMonth(page);
      this.clearSearch();
    },

    searchOrClearMdfe(page) {
      this.filterPerMonthMdfe(page);
      this.clearSearch();
    },

    clearSearch() {
      this.textPesquisa = "";
      this.type = "";
      this.startDate = "";
      this.endDate = "";
    },

    async redirectSefaz() {
      try {
        window.open(
          "https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=resumo&tipoConteudo=7PhJ+gAVw2g=",
          "_blank"
        );
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao redirecionar para a página",
          type: "info",
        });
      }
    },

    async sendCoupon(idSale) {
      try {
        this.spinLoading = true;
        const data = await ServiceNotaFiscal.sendCoupon(idSale);
        this.responseNfeWebMania.danfe = data.dataWebMania.danfe;
        this.visualizarNfe(this.responseNfeWebMania.danfe);

        await this.filterNota(1);
        return this.$toast.open({
          message: "Cupom Fiscal emitido com sucesso!",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        const typeError = HandleErrors(error?.response?.data?.message);

        if (typeError && typeError?.nameError === TypeErrors.NO_PERMISSIONS) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        return this.$toast.open({
          message:
            error?.response?.data?.message ||
            error?.response?.data?.erros ||
            "Ops! Houve algum erro. Verifique os dados e tente novamente",
          type: "info",
        });
      } finally {
        this.spinLoading = false;
      }
    },

    visualizarNfe(danfe) {
      window.open(danfe, "_blank", "width=700, height=800, top=500, left=500");
    },

    async openPdf(pdf) {
      try {
        window.open(`${pdf}`, "_blank");
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao abrir o arquivo",
          type: "info",
        });
      }
    },

    async printNf(id) {
      try {
        this.isLoadingToPrint = true;
        const pdfUrl = await serviceNotaFiscal.printNf(id);

        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao abrir o arquivo",
          type: "info",
        });
      } finally {
        this.isLoadingToPrint = false;
      }
    },

    async printNfce(id) {
      try {
        this.isLoadingToPrint = true;
        const danfe = await ServiceNotaFiscal.printNfce(id);

        const win = window.open(
          danfe,
          "_blank",
          "width=700, height=800, top=500, left=500"
        );

        win.document.write(danfe);

        win.document.close();
      } catch (error) {
        console.log(error);
        this.$toast.open({
          message: "Ocorreu um erro ao abrir o arquivo",
          type: "info",
        });
      } finally {
        this.isLoadingToPrint = false;
      }
    },

    handlePrints(id, typeDocument) {
      if (typeDocument === "2") {
        return this.printNfce(id);
      }

      this.printNf(id);
    },

    async openTick(etiqueta) {
      try {
        window.open(`${etiqueta}`, "_blank");
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao abrir o arquivo",
          type: "info",
        });
      }
    },

    async deleteNfeNfce() {
      try {
        await ServiceNotaItem.deleteNotaAndItem(this.idDocumentoToDelete);

        this.idDocumentoToDelete = null;
        this.$bvModal.hide("modal-center3");
        this.filterNota(1);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Não foi possível deletar este documento fiscal!",
          type: "info",
        });

        this.$bvModal.hide("modal-center3");
      } finally {
        this.idDocumentoToDelete = null;
      }
    },

    getIdDocumentoToDelete(idDocument) {
      this.idDocumentoToDelete = idDocument;
    },

    nextPage() {
      this.tableActive.nfse
        ? this.filterPerMonth((this.page += 1))
        : this.filterNota((this.page += 1));
    },

    previousPage() {
      if (this.page === 1) {
        return;
      } else {
        this.tableActive.nfse
          ? this.filterPerMonth((this.page -= 1))
          : this.filterNota((this.page -= 1));
      }
    },
  },
  computed: {
    ...mapState([
      "sizeModal",
      "typeFiscal",
      "dataNFe",
      "colapseFiscal",
      "modalContainerGeneral",
    ]),
    monthName() {
      return this.month.toLocaleString({ month: "long", locale: "pt-BR" });
    },
  },
  async created() {
    this.monthSelected = this.month.month;
    this.yearSelected = this.year;
    await this.filterPerMonth();
    await this.filterPerMonthMdfe();
    await this.filterNotesPerMonth();
    await this.handleActiveTable("nfe");
    if (
      (Object.keys(this.dataNFe).length && this.dataNFe.nfe.idVenda) ||
      this.dataNFe?.nfe?.idVendaMtq
    ) {
      this.handleDataNFeAfterTransformSaleToNfe();
    }
  },
  beforeDestroy() {
    this.ALTER_SIZE_MODAL("disable");
    this.SET_COLAPSE_FISCAL(false);
    this.ALTER_MODAL_CONTAINER_GENERAL("contentModalDisable");
    this.SET_DEFAULT_TYPE_FISCAL();
    this.SET_DATA_NFE({});
  },
};
</script>
<style scoped>
.GeneralFiscal {
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  width: calc(100% - 100px);
  height: 100vh;
  font-size: 12px;
}

.ContainerBtnFiscal {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 95px;
  margin-top: 65px;
  width: 100%;
}

.BtnFiscal {
  width: 23%;
  border: none;
  height: 35px;
  border-radius: 3px;
  font-size: 12px;
  box-shadow: 0px 12px 4px -10px rgba(142, 142, 142, 0.78);
}

.SummaryFiscal {
  display: flex;
  height: 90px;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid rgb(199, 198, 198);
  padding: 0px 0px 5px 0px;
}

.ItemSummaryFiscal {
  width: 23%;
  height: 70px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  box-shadow: 0px 12px 4px -10px rgba(142, 142, 142, 0.78);
  cursor: pointer;
}

.searchFilter {
  width: 110px;
  border-right: none;
  height: 30px;
  font-size: 11px;
  text-align: center;
}

.searchFilter:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

.custom-select {
  left: 4px;
  position: relative;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #e3e0e0;
  -moz-appearance: none;
  appearance: none;
}

.searchFilterSituation {
  width: 230px;
  border-left: none !important;
  height: 30px;
  border-radius: 0px !important;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  position: relative !important;
}

.searchFilterSituation:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

.searchInput {
  display: flex;
  margin: 5px 0px 5px 0px;
}

.btnPeriod {
  border-radius: 5px 0px 0px 5px;
  border: 1px solid rgb(235 235 235);
  padding: 0 10px;
  height: 30px;
  font-size: 11px;
  cursor: context-menu;
}

.ContainerSearchInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 0 20px 0 0;
  max-width: 100%;
}

.textRegister {
  font-weight: bold;
  padding-left: 20px;
}

.searchContainer {
  display: flex;
  gap: 10px;
  width: 80%;
  justify-content: flex-end;
  align-items: center;
}

.searchAvancedContent {
  display: flex;
  padding: 3px 0px 3px 0;
  align-items: center;
  justify-content: flex-end;
}

.spinnerPrint {
  color: white;
  position: absolute;
  right: 5px;
  width: 20px;
  height: 20px;
}

.searchAvanced {
  justify-content: center;
  color: #3a4750;
  font-size: 12px;
  height: 30px;
  gap: 10px;
  padding: 0px 20px;
  border: solid 1px #e3e0e0;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchAvanced:hover {
  background: rgb(205, 205, 205);
}

.form-control-sm {
  padding: 0.25rem 1.5rem;
  height: 30px;
}

.form-control {
  font-size: 13px;
}

.dateInput {
  display: flex;
  gap: 10px;
}

input {
  border: 1px solid #e3e0e0;
  border-radius: 5px;
  height: 30px;
}

.searchBtn {
  right: 10px;
  height: 30px;
  position: relative;
  background: whitesmoke;
  color: black;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  border-color: #e3e0e0;
}

input:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

button:focus {
  box-shadow: none;
}

.ContentTable {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  padding: 10px 20px;
  width: 100%;
  max-width: 100%;
}

.actiontd {
  display: flex;
  justify-content: center;
  margin-right: 50px;
}

tr:nth-child(even) {
  background: #e2e3e4;
}

.defaultSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.defaultSearch span {
  font-size: 1rem;
}

.loadingLazy {
  position: absolute;
  bottom: 20px;
  height: 50vh;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(140, 140, 140);
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  opacity: 0.5;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #403b74;
  box-shadow: 30px 0 0 #f2a83e;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #3e66ae;
  box-shadow: 30px 0 0 #48b865;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f2a83e;
  }

  50% {
    box-shadow: 0 0 0 #f2a83e;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f2a83e;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #48b865;
  }

  50% {
    box-shadow: 0 0 0 #48b865;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #48b865;
    margin-top: 0;
  }
}

.titleModal {
  font-size: 12px;
  border-bottom: 1px solid #e6e7e8;
}

.btnContent-action-nfe {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContent-details-action-nfe {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 20%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnContentReceive-action-nfe {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.btn-cancel-document {
  font-size: 11px;
  border: 1px solid brown;
  border-radius: 3px;
  width: 20%;
  height: 28px;
  position: relative;
  color: white;
  background: brown;
}

.btn-correction-letter-document {
  font-size: 11px;
  border: 1px solid rgb(242, 168, 62);
  border-radius: 3px;
  width: 20%;
  height: 28px;
  position: relative;
  color: white;
  background: rgb(242, 168, 62);
}

.setBtn {
  display: flex;
  flex-direction: row;
  margin: 0 1rem 1rem 0;
  gap: 10px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.itemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #3a4750;
}

.itemBtn:hover {
  background: #3a4750;
  color: whitesmoke;
}

.itemBtn:disabled {
  opacity: 0;
}

.centerItemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #1b3159;
  cursor: auto;
}

.ModalGeneral {
  position: fixed !important;
  z-index: 900;
  top: 50px;
  left: 61px;
  height: calc(100% - 53px);
  max-height: calc(100% - 53px);
  overflow: auto;
  width: calc(100% - 62px);
  background: #ffff;
  transition: all 0.7s ease-in-out;
}

.searchAll {
  justify-content: center;
  color: #3a4750;
  font-size: 13px;
  padding: 5px 1.5rem;
  font-weight: bold;
  border: solid 1px #e3e0e0;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchAll:hover {
  background: rgb(205, 205, 205);
}

.disable {
  position: absolute;
  width: calc(100% - 62px);
  height: 0;
  left: 61px;
  top: 100vh;
  background: #b0b0b000;
  visibility: hidden;
  transition: all 0.7s ease-in-out;
}

.closeModal {
  position: fixed;
  top: 51px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
  font-size: 18px;
  height: 30px;
  background: white;
  border: none;
  color: rgb(6, 6, 6);
}

.closeModal:hover {
  background: whitesmoke;
  color: red;
}

.closeModal:focus {
  background: red;
  border: none;
  box-shadow: none;
}

.sizeFiscal {
  margin-top: 20px;
  width: 80vw;
}

.contentModalDisable {
  display: none;
  background: white;
  opacity: 0;
}

.lineSearch {
  display: flex;
  align-items: center;
}

.searchByMonth {
  width: 30%;
  display: flex;
  align-items: center;
  margin: 5px 0px 8px 20px;
  border-radius: 5px;
  justify-content: space-around;
  border: 1px solid #e3e0e0;
}

.pagMonth {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.searchMonth {
  width: 100px;
  height: 30px;
  border-radius: 5px 0px 0px 5px;
  font-size: 11px;
  text-align: center;
  font-family: Poppins-Regular, sans-serif !important;
}

.searchMonth:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

.searchYear {
  width: 50px;
  height: 30px;
  border-radius: 0px 5px 5px 0px;
  border: 1px solid rgb(195, 193, 193);
  font-size: 11px;
  text-align: center;
  font-family: Poppins-Regular, sans-serif !important;
}

.searchYear:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

.custom-select3 {
  left: 4px;
  -moz-appearance: none;
  appearance: none;
  background: none;
  font-weight: bold;
  padding: 0 !important;
}

.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnActions {
  margin-left: 30px;
  height: 25px;
  display: flex;
}

.dropdown-menu {
  font-size: 12px;
  min-width: 5rem !important;
  width: 5rem !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  color: red;
  background: red;
  text-align: center;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
}

.typeSale {
  color: #403b74;
  font-weight: bold;
}

.typeSale2 {
  color: #48b865;
  font-weight: bold;
}

.typeSale3 {
  color: #f2a83e;
  font-weight: bold;
}

.titleModal {
  font-size: 12px;
  border-bottom: 1px solid #e6e7e8;
}

.btnModal-action-nfe {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.contentModal {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  font-size: 13px;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
}

.rowModal {
  display: flex;
  width: 100%;
  padding: 20px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.closeModalDetails {
  width: 22px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -8px;
  top: -13px;
  color: red;
  border-radius: 5px;
  cursor: pointer;
  border: solid 1px red;
  transition: ease-in all 0.2s;
  font-size: 14px;
  background-color: rgb(239, 239, 239);
}

.closeModalDetails:hover {
  background-color: red;
  color: white;
}

.input-group-supplier {
  width: 45%;
  position: relative;
}

.input-group-key {
  width: 100%;
  position: relative;
}

.ContentTableModal {
  display: flex;
  flex-direction: column;
  padding: 10px 20px 0 20px;
  width: 100%;
}

.user-label {
  position: absolute;
  left: 15px;
  font-size: 11px;
  z-index: 30;
  bottom: 21px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.iconCopy {
  position: absolute;
  right: 10px !important;
  z-index: 10;
  background-color: #c1c1c1;
  color: rgb(0, 0, 0);
  height: 30px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}

.input {
  font-size: 12px !important;
  width: 100%;
  padding: 0 0 0 10px;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
  font-weight: 600;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .GeneralFiscal {
    margin-left: 60px;
    width: calc(100% - 60px);
    font-size: 11px;
  }
  .ItemSummaryFiscal {
    width: 24%;
    height: 50px;
    gap: 0px;
  }
  .ContainerBtnFiscal {
    padding: 0px 0px 5px 0px;
  }
  .searchContainer {
    height: 40px;
  }
  .searchInput {
    width: auto;
    margin: 10px 0px 10px 10px;
  }
  .searchFilter {
    width: 93px;
  }
  .btnPeriod {
    width: 60px;
    font-size: 10px;
  }
  .ContentTable {
    padding: 5px 10px;
  }
  .setBtn {
    margin: 0 1rem 10px 0;
  }
  .itemBtn {
    width: 18px;
    height: 18px;
  }
  .centerItemBtn {
    width: 18px;
    height: 18px;
  }
  .FooterPagination {
    padding: 0px;
  }
}

@media screen and (max-width: 700px) {
  .GeneralFiscal {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    width: 100%;
    height: 100vh;
    font-size: 11px;
  }

  .SummaryFiscal {
    margin-top: 10px;
  }

  .lineSearch {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchByMonth {
    width: 80%;
    display: flex;
    align-items: center;
    margin: 10px 0px 0px 0px;
  }

  .ContainerBtnFiscal {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: auto;
    margin-top: 55px;
    width: 100%;
    padding: 0px 0px 10px 0px;
    border-bottom: 1px solid rgb(199, 198, 198);
  }

  .BtnFiscal {
    width: 24%;
    height: 28px;
    font-size: 11px;
  }

  .ContainerSearchInput {
    display: none;
  }

  .searchContainer {
    display: flex;
    height: 90px;
    padding: 10px 0 0 0;
    gap: 10px;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .btnPeriod {
    width: 70px;
    height: 30px;
  }

  .searchInput {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .ContentTable {
    padding: 5px;
  }
  .ModalGeneral {
    position: fixed !important;
    z-index: 900;
    top: 50px;
    left: 0;
    width: 100%;
  }

  .disable {
    width: 100%;
    height: 0;
    left: 0;
  }

  .sizeFiscal {
    width: 100vw !important;
    padding: 0 10px;
  }
  .btnActions {
    margin-left: 0px;
  }

  .actiontd {
    margin-right: 0px;
  }
}
</style>