<template>
  <section class="containerGeralAccounting">
    <div class="customCard">
      <span class="title">
        <b-icon-calculator-fill class="icons" />
        <p>CONTABILIDADE</p>
      </span>

      <div class="rowCustomAccounting">
        <span class="contentInputAccounting">
          <Input
            label="Nome"
            type="text"
            :labelFixed="true"
            :dataInput.sync="dataAccounting.nome"
            :isDisabled="isDisabledInputs"
            ref="nome"
          />
        </span>

        <span class="contentInputAccounting">
          <InputCnpj
            label="CNPJ"
            type="text"
            :labelFixed="true"
            :dataInput.sync="dataAccounting.cnpj"
            :mask="dataAccounting.cnpj"
            :isDisabled="isDisabledInputs"
            ref="cnpj"
          />
        </span>

        <span class="contentInputAccounting">
          <Input
            label="CRC"
            type="text"
            :labelFixed="true"
            :dataInput.sync="dataAccounting.crc"
            :isDisabled="isDisabledInputs"
            maxlength="30"
            ref="crc"
          />
        </span>
      </div>

      <div class="rowCustomAccounting">
        <span class="contentInputAccounting" style="width: 40%">
          <InputCep
            label="CEP"
            type="text"
            showBtnCep
            isMaskCep
            :labelFixed="true"
            :dataInput.sync="dataAccounting.cep"
            :mask="dataAccounting.cep"
            :isDisabled="isDisabledInputs"
            :maxlength="9"
            :clickBtnCep="() => handleCep(dataAccounting.cep)"
            ref="cep"
          />
        </span>

        <span class="contentInputAccounting">
          <Input
            label="Endereço"
            type="text"
            :labelFixed="true"
            :dataInput.sync="dataAccounting.endereco"
            :isDisabled="isDisabledInputs"
            ref="endereco"
          />
        </span>

        <span class="contentInputAccounting">
          <Input
            label="Bairro"
            type="text"
            :labelFixed="true"
            :dataInput.sync="dataAccounting.bairro"
            :isDisabled="isDisabledInputs"
            ref="bairro"
          />
        </span>

        <span class="contentInputAccounting" style="width: 30%">
          <Input
            label="Nº"
            type="text"
            :labelFixed="true"
            :dataInput.sync="dataAccounting.numero"
            :isDisabled="isDisabledInputs"
            ref="numero"
          />
        </span>
      </div>

      <div class="rowCustomAccounting">
        <span class="contentInputAccounting">
          <Input
            label="Responsável"
            type="text"
            :labelFixed="true"
            :dataInput.sync="dataAccounting.responsavel"
            :isDisabled="isDisabledInputs"
            ref="responsavel"
          />
        </span>

        <span
          class="contentInputAccounting"
          style="width: 30%; min-width: 130px"
        >
          <InputPhone
            label="Telefone"
            type="text"
            :labelFixed="true"
            :dataInput.sync="dataAccounting.telefone"
            :mask="dataAccounting.telefone"
            :isDisabled="isDisabledInputs"
            ref="telefone"
          />
        </span>

        <span class="contentInputAccounting">
          <Input
            label="E-mail"
            type="text"
            :labelFixed="true"
            :dataInput.sync="dataAccounting.email"
            :isDisabled="isDisabledInputs"
            ref="email"
          />
        </span>
      </div>

      <div class="rowCustomContentBtsActions">
        <span style="width: 100px">
          <button
            size="sm"
            :class="`btnGeneral btnCustom ${isDisabledInputs && 'btnDisabled'}`"
            variant="none"
            @click="handleSaveOrUpdate"
          >
            <p>{{ dataAccounting.id ? "Atualizar" : "Salvar" }}</p>
          </button>
        </span>

        <span style="width: 100px">
          <button
            size="sm"
            class="btnGeneral"
            variant="none"
            @click="handleIsDisabledInputs"
          >
            <p>{{ isDisabledInputs ? "Editar" : "Cancelar" }}</p>
          </button>
        </span>
      </div>
    </div>

    <div class="customCard">
      <span class="title" style="justify-content: space-between">
        <span style="display: flex; gap: 5px; align-items: center">
          <b-icon-chat-text-fill class="icons" />
          <p>Mensagens e Documentos</p>
        </span>

        <div>
          <span style="width: 200px">
            <button
              size="sm"
              :class="`btnGeneral ${!dataAccounting.id && 'btnDisabled'}`"
              variant="none"
              @click="openModals('modalSendMessage')"
            >
              <p>Nova Solicitação</p>
            </button>
          </span>
        </div>
      </span>

      <div class="contentTable">
        <table>
          <thead
            style="
              color: whitesmoke;
              background: #3a4750;
              text-align: center;
              height: 29px;
            "
          >
            <tr style="width: 100%">
              <th>Data</th>
              <th>Assunto</th>
              <th>Solicitante</th>
              <th>Departamento</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody v-if="solicitations.length">
            <tr
              class="trCustom"
              v-for="solicitation in solicitations"
              :key="solicitation.id"
              style="text-align: center; height: 28px; cursor: context-menu"
            >
              <td>
                {{
                  solicitation.data.split(" ")[0].split("-").reverse().join("/")
                }}
              </td>
              <td>{{ solicitation.assunto }}</td>
              <td>{{ solicitation.solicitante }}</td>
              <td>{{ solicitation.departamento }}</td>
              <td>{{ solicitation.status }}</td>

              <td>
                <div class="btnActions">
                  <b-dropdown
                    id="dropdown-1"
                    text="Ações"
                    menu-class="w-1"
                    class="btnActions"
                    size="sm"
                    variant="none"
                  >
                    <template #button-content>
                      <b-icon-gear-fill
                        class="contentActions"
                      ></b-icon-gear-fill>
                    </template>
                    <b-dropdown-item
                      @click="deleteSolicitation(solicitation.id)"
                    >
                      Excluir
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="handleFindConversations(solicitation.id)"
                    >
                      Conversas
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr colspan="5">
              <div class="defaultSearch">
                <span>Não há dados para exibir ...</span>
              </div>
            </tr>
          </tbody>
        </table>

        <div class="setBtn">
          <button @click="previousPage" :disabled="page === 1" class="itemBtn">
            <b-icon-chevron-left></b-icon-chevron-left>
          </button>
          <button class="centerItemBtn">{{ page }}</button>
          <button v-if="solicitations.length" @click="nextPage" class="itemBtn">
            <b-icon-chevron-right></b-icon-chevron-right>
          </button>
        </div>
      </div>
    </div>

    <div class="customCard">
      <span class="title">
        <b-icon-cloud-arrow-down-fill class="icons" />
        <p>Relatórios</p>
      </span>

      <div class="rowCustom">
        <span class="contentInputReports">
          <Input
            label="Data inicial"
            type="date"
            :labelFixed="true"
            :dataInput.sync="dataReport.data_inicio"
          />
        </span>

        <span class="contentInputReports">
          <Input
            label="Data final"
            type="date"
            :labelFixed="true"
            :dataInput.sync="dataReport.data_final"
          />
        </span>

        <span class="contentInputReports">
          <InputSelect
            label="Modelo do documento"
            :enableIconCreate="false"
            colorBackground="#ffffff"
            :items="typeDocument"
            valueField="value"
            textField="text"
            :dataSelect.sync="dataReport.modelo"
          />
        </span>

        <span class="contentInputReports">
          <InputSelect
            label="Situação do documento"
            :enableIconCreate="false"
            colorBackground="#ffffff"
            :items="situationDocument"
            valueField="value"
            textField="text"
            :dataSelect.sync="dataReport.status"
          />
        </span>

        <span class="contentInputReports">
          <InputSelect
            label="Tipo do relatório"
            :enableIconCreate="false"
            colorBackground="#ffffff"
            :items="typeReport"
            valueField="value"
            textField="text"
            :dataSelect.sync="dataReport.relatorio"
          />
        </span>

        <div>
          <button
            size="sm"
            class="btnGeneral btnCustom"
            variant="none"
            @click="generateReport"
          >
            <p>Realizar Download</p>
          </button>
        </div>
      </div>
    </div>

    <div class="customCard">
      <span class="title">
        <b-icon-file-text-fill class="icons" />
        <p>Sintegra</p>
      </span>

      <div class="rowCustom">
        <span class="contentInputReports">
          <Input
            label="Data inicial"
            type="date"
            :labelFixed="true"
            :dataInput.sync="dataToGenerateSintegra.startDate"
          />
        </span>

        <span class="contentInputReports">
          <Input
            label="Data final"
            type="date"
            :labelFixed="true"
            :dataInput.sync="dataToGenerateSintegra.endDate"
          />
        </span>

        <span class="contentInputReports">
          <Input
            label="Responsável"
            type="text"
            :labelFixed="true"
            :dataInput.sync="dataToGenerateSintegra.responsible"
          />
        </span>

        <span class="contentInputSintegra">
          <label for="isReg74" class="isReg74">
            <input
              id="isReg74"
              type="checkbox"
              v-model="dataToGenerateSintegra.includesReg74"
            />
            <p>Gerar registro 74 ?</p>
          </label>
        </span>

        <div>
          <button
            size="sm"
            class="btnGeneral"
            variant="none"
            @click="generateSintegra"
          >
            <p>Gerar Sintegra</p>
          </button>
        </div>

        <div>
          <button
            size="sm"
            class="btnGeneral btnCustom"
            variant="none"
            @click="downloadSintegra"
          >
            <p>Download Sintegra</p>
          </button>
        </div>
      </div>

      <div
        class="rowCustom"
        style="flex-direction: column"
        v-if="logsErrors.length"
      >
        <span class="conentTitleError">
          <p>* Erros encontrados, corrija-os e gere o sintegra novamente.</p>
        </span>

        <span class="conentError">
          <p v-for="(log, index) in logsErrors" :key="index">{{ log }}</p>
        </span>
      </div>
    </div>

    <div class="customCard">
      <span class="title">
        <b-icon-file-text-fill class="icons" />
        <p>SPED</p>
      </span>

      <div class="rowCustom">
        <span class="contentInputReports">
          <Input
            label="Data inicial"
            type="date"
            :labelFixed="true"
            :dataInput.sync="dataSped.startDate"
          />
        </span>

        <span class="contentInputReports">
          <Input
            label="Data final"
            type="date"
            :labelFixed="true"
            :dataInput.sync="dataSped.endDate"
          />
        </span>

        <div>
          <button
            size="sm"
            class="btnGeneral"
            variant="none"
            @click="generateSped"
          >
            <p>Gerar SPED</p>
          </button>
        </div>
      </div>
    </div>

    <ModalNewSolicitation
      :idAccounting="dataAccounting.id"
      @registeredNewSolicitation="findSolicitations"
    />

    <div :class="sizeModal">
      <div :class="modalContainerGeneral" v-if="colapseGeneral">
        <b-button class="closeModal" @click="handleModals"> X </b-button>
        <AccountingConversations
          v-if="enableModalAccountingConversation"
          :idSolicitation="idSolicitationSelected"
        />
      </div>
    </div>
  </section>
</template>

<script>
import api from "../../services/axios";
import { mapState, mapMutations } from "vuex";
import Input from "../UI/CustomInputs/Input.vue";
import InputCep from "../UI/CustomInputs/InputCep.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import InputCnpj from "../UI/CustomInputs/InputCnpj.vue";
import InputPhone from "../UI/CustomInputs/InputPhone.vue";
import ModalNewSolicitation from "./ModalNewSolicitation.vue";
import AccountingConversations from "./AccountingConversations.vue";

import ServiceNotaFiscal from "../../services/serviceNotaFiscal";
import ServiceAccounting from "../../services/serviceAccounting";
import ServiceAccountingSolicitations from "../../services/serviceAccountingSolicitations";
import ServiceAccountingMessages from "../../services/serviceAccountingMessages";
import ServiceAuxiliaresSintegra from "../../services/serviceAuxiliaresSintegra";
import ServiceSintegra from "../../services/serviceSintegra";

import { HandleErrors } from "../../utils/handleErrors";
import { TypeErrors } from "../../utils/enums/TypeErrors";
import { departaments } from "../../common/index";

export default {
  components: {
    Input,
    InputCep,
    InputSelect,
    InputCnpj,
    InputPhone,
    ModalNewSolicitation,
    AccountingConversations,
  },
  data() {
    return {
      dataReport: {
        data_inicio: null,
        data_final: null,
        modelo: null,
        relatorio: null,
        status: null,
      },
      dataAccounting: {
        id: null,
        nome: null,
        cnpj: null,
        crc: null,
        responsavel: null,
        telefone: null,
        email: null,
        endereco: null,
        bairro: null,
        numero: null,
        cep: null,
      },
      dataToGenerateSintegra: {
        startDate: "",
        endDate: "",
        includesReg74: false,
        responsible: "",
      },
      typeDocument: [
        { text: "NF-e", value: "nfe" },
        { text: "NFC-e", value: "nfce" },
      ],
      typeReport: [
        { text: "CSV", value: "csv" },
        { text: "Excel", value: "excel" },
        { text: "Xml", value: "xml" },
        { text: "Danfe", value: "danfe" },
        { text: "Danfe Simples", value: "danfe_simples" },
        { text: "Danfe Etiqueta", value: "danfe_etiqueta" },
      ],
      situationDocument: [
        { text: "Emitidas", value: "emitidas" },
        { text: "Canceladas", value: "canceladas" },
        { text: "Denegadas", value: "denegadas" },
        { text: "Inutilizadas", value: "inutilizadas" },
      ],
      isDisabledInputs: true,
      page: 1,
      solicitations: [],
      conversations: [],
      idSolicitationSelected: "",
      logsErrors: [],
      sintegraIsReady: { countLogs: 0, countSintegra: 0 },
      dataSped: {
        startDate: "",
        endDate: "",
      },
    };
  },
  methods: {
    ...mapMutations([
      "SET_ACCOUTING_CONVERSATIONS",
      "SET_ACCOUTING_NUMBER_NEW_MESSAGES",
      "SET_COLAPSE_GENERAL",
      "ALTER_SIZE_MODAL",
      "ALTER_MODAL_CONTAINER_GENERAL",
      "SET_OVERFLOW",
      "ALTER_TYPE_ACCOUNTING_CONVERSATION",
    ]),

    async saveAccounting() {
      try {
        const { id } = await ServiceAccounting.save(this.dataAccounting);
        this.dataAccounting.id = id;

        this.isDisabledInputs = true;
        this.$toast.open({
          message: "Contabilidade registrada com sucesso!",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        const typeError = HandleErrors(error?.response?.data?.message);

        if (
          typeError &&
          typeError?.nameError === TypeErrors.PLAN_WITHOUT_PERMISSION
        ) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message:
            `${error.response.data.erros || error.response.data.message}` ||
            "Ocorreu um erro ao salvar a contabilidade!",
          type: "info",
        });
      }
    },

    async updateAccounting() {
      try {
        await ServiceAccounting.update(
          this.dataAccounting,
          this.dataAccounting.id
        );

        this.isDisabledInputs = true;
        this.$toast.open({
          message: "Contabilidade atualizada com sucesso!",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (
          typeError &&
          typeError?.nameError === TypeErrors.PLAN_WITHOUT_PERMISSION
        ) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message:
            `${error.response.data.erros || error.response.data.message}` ||
            "Ocorreu um erro ao atualizar a contabilidade!",
          type: "info",
        });
      }
    },

    async handleSaveOrUpdate() {
      if (this.isDisabledInputs) {
        return;
      }

      this.dataAccounting.id
        ? await this.updateAccounting()
        : await this.saveAccounting();
    },

    async findActivated() {
      const accounting = await ServiceAccounting.findActivated();
      return accounting;
    },

    async handleAccoutingAndRefs() {
      try {
        const accounting = await this.findActivated();
        Object.assign(this.dataAccounting, accounting);

        this.$refs.nome.valueInput = accounting.nome;
        this.$refs.cnpj.valueInput = accounting.cnpj;
        this.$refs.responsavel.valueInput = accounting.responsavel;
        this.$refs.telefone.valueInput = accounting.telefone;
        this.$refs.email.valueInput = accounting.email;
        this.$refs.crc.valueInput = accounting.crc;
        this.$refs.endereco.valueInput = accounting.endereco;
        this.$refs.bairro.valueInput = accounting.bairro;
        this.$refs.numero.valueInput = accounting.numero;
        this.$refs.cep.valueInput = accounting.cep;
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (
          typeError &&
          typeError?.nameError === TypeErrors.PLAN_WITHOUT_PERMISSION
        ) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message:
            `${error.response.data.erros || error.response.data.message}` ||
            "Ocorreu um erro ao atualizar a contabilidade!",
          type: "info",
        });
      }
    },

    async generateReport() {
      try {
        const result = await ServiceNotaFiscal.downloadXml(this.dataReport);
        if (result && Object.keys(result).length && result?.url) {
          window.open(result.url);
        } else {
          this.$toast.open({
            message: "Aguarde, seu download ainda esta sendo processado",
            type: "info",
          });
        }
      } catch (error) {
        this.$toast.open({
          message: `${
            error.response.data.message || "Ocorreu um erro realizar o download"
          }`,
          type: "info",
        });
      }
    },

    async generateSintegra() {
      try {
        this.logsErrors = [];
        await ServiceAuxiliaresSintegra.generate(this.dataToGenerateSintegra);

        this.$toast.open({
          message: "Aguarde, o SINTEGRA esta sendo processado!",
          type: "info",
        });
      } catch (error) {
        this.$toast.open({
          message: `${
            error.response.data.message || "Ocorreu um erro gerar o sintegra"
          }`,
          type: "info",
        });
      }
    },

    async handleDownload() {
      await this.existsLogs();
      if (this.logsErrors) {
        return this.$toast.open({
          message: "Erros encontrados, corrija-os e gere novamente o SINTEGRA",
          type: "info",
        });
      }
    },

    async downloadSintegra() {
      try {
        const result = await ServiceSintegra.download();
        if (result) {
          this.logsErrors = result.split("!");
        }
      } catch (error) {
        this.$toast.open({
          message: error.message || "Ocorreu um erro ao buscar as conversas!",
          type: "info",
        });
      }
    },

    async existsLogs() {
      const logs = await ServiceSintegra.existsLogs();
      if (logs) {
        this.logsErrors = logs.split("!");
      }
    },

    async findSolicitations(page = 1) {
      const solicitations =
        await ServiceAccountingSolicitations.findPerPagination(page);

      this.solicitations = solicitations.map((solicitation) => {
        solicitation.departamento = departaments.find(
          (e) => e.value === solicitation.departamento
        ).text;

        return solicitation;
      });
    },

    async nextPage() {
      if (!this.solicitations.length) {
        return;
      }

      this.page += 1;
      await this.findSolicitations(this.page);
    },

    async previousPage() {
      if (this.page === 1) {
        return;
      } else {
        this.page -= 1;
        await this.findSolicitations(this.page);
      }
    },

    handleIsDisabledInputs() {
      this.isDisabledInputs = !this.isDisabledInputs;
    },

    async handleFindConversations(id) {
      try {
        const { messages, numberOfNewMessages } =
          await ServiceAccountingMessages.findByIdSolicitation(id);

        this.SET_ACCOUTING_CONVERSATIONS(messages.reverse());
        this.SET_ACCOUTING_NUMBER_NEW_MESSAGES(numberOfNewMessages);
        this.idSolicitationSelected = id;

        this.handleModals();
      } catch (error) {
        console.log(error);
        this.$toast.open({
          message: "Ocorreu um erro ao buscar as conversas!",
          type: "info",
        });
      }
    },

    async deleteSolicitation(id) {
      try {
        await ServiceAccountingSolicitations.delete(id);
        this.$toast.open({
          message: "Solicitação deletada com sucesso!",
          type: "sucess",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data.message ||
            "Ocorreu um erro ao buscar deletar!",
          type: "info",
        });
      }
    },

    async handleCep(cep) {
      try {
        const formatedCep = cep.replace(/\D/g, "");
        const { data } = await api.get(
          `https://brasilapi.com.br/api/cep/v1/${formatedCep}`
        );

        this.dataAccounting.bairro = data?.neighborhood || "";
        this.dataAccounting.endereco = data?.street || "";

        this.$refs.bairro.valueInput = data?.neighborhood || "";
        this.$refs.endereco.valueInput = data?.street || "";
      } catch (error) {
        this.$toast.open({
          message:
            "Ocorreu um erro ao buscar as informações , verifique o CEP e tente novamente",
          type: "info",
        });
      }
    },

    modalHeight() {
      this.overflow === "hidden"
        ? this.SET_OVERFLOW("auto")
        : this.SET_OVERFLOW("hidden");

      this.SET_COLAPSE_GENERAL(!this.colapseGeneral);

      this.colapseGeneral
        ? this.ALTER_SIZE_MODAL("ModalGeneral")
        : this.ALTER_SIZE_MODAL("disable");

      this.modalContainerGeneral
        ? this.ALTER_MODAL_CONTAINER_GENERAL("modalContainer")
        : this.ALTER_MODAL_CONTAINER_GENERAL("contentModalDisable");
    },

    handleModals() {
      this.ALTER_TYPE_ACCOUNTING_CONVERSATION();

      this.modalHeight();
    },

    openModals(name) {
      this.$bvModal.show(name);
    },

    async generateSped() {
      try {
        await api.post(
          `sped/generate?startDate=${this.dataSped.startDate}&endDate=${this.dataSped.endDate}`
        );

        this.$toast.open({
          message: "Aguarde, o SPED esta sendo processado!",
          type: "info",
        });
        
        this.dataSped.startDate = "";
        this.dataSped.endDate = "";
      } catch (error) {
        this.$toast.open({
          message: `${
            error.response.data.message || "Ocorreu um erro gerar o sped"
          }`,
          type: "info",
        });
      }
    },
  },
  computed: {
    ...mapState({
      colapseGeneral: (state) => state.colapseGeneral,
      modalContainerGeneral: (state) => state.modalContainerGeneral,
      sizeModal: (state) => state.sizeModal,
      overflow: (state) => state.overflow,
      enableModalAccountingConversation: (state) =>
        state.enableModalAccountingConversation,
    }),
    maskTelefone() {
      return "(##) ####-####";
    },
  },
  mounted() {
    this.handleAccoutingAndRefs();
    this.findSolicitations(1);
    this.existsLogs();
  },
};
</script>

<style scoped>
.containerGeralAccounting {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  padding: 2rem 2rem;
}

.customCard {
  width: 100%;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 10px 10px;
  background: #f9f9f94d;
  margin-bottom: 5px;
}

.title {
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: default;
  margin-top: 5px;
}

.title p {
  margin: 0;
  font-weight: 600;
  font-size: 13px;
  color: rgb(95, 95, 95);
}

.title .icons {
  color: rgb(95, 95, 95);
}

.isReg74 {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: default;
  margin-top: 5px;
}

.isReg74 p {
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  color: rgb(95, 95, 95);
}

.isReg74 .icons {
  color: rgb(95, 95, 95);
}

.rowCustom {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px 0 5px 0;
  margin-top: 1rem;
}

.rowCustomAccounting {
  display: flex;
  gap: 20px;
  padding: 10px 0 5px 0;
  margin-top: 1rem;
}

.rowCustomContentBtsActions {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 1rem;
}

.rowCustomSintegra {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0px 0 5px 0;
  margin-top: 1rem;
}

.contentInputReports {
  width: 180px;
}

.contentInputSintegra {
  width: 140px;
}

.contentInputAccounting {
  width: 100%;
}

.btnGeneral {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 12px;
  font-weight: normal;
  border: none;
  outline: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.179) !important;
  background-color: #5851a0;
  border-radius: 5px;
  transition: ease-in all 0.2s;
  cursor: pointer;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}

.btnGeneral p {
  margin: 0;
  font-weight: normal;
  color: white;
}

.btnCustom {
  background-color: rgb(72, 184, 101);
}

.btnCustom:hover {
  background-color: rgb(59, 149, 83) !important;
  color: white;
}

.btnGeneral:hover {
  background-color: #322f5b;
  color: white;
}

.btnDisabled {
  background-color: gray;
  pointer-events: none;
}

.contentTable {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  max-width: 100%;
  margin-top: 1rem;
  height: 330px;
}

tr:nth-child(even) {
  background: #f2f3f3;
}

table {
  width: 100%;
  height: 100%;
}

thead,
tbody {
  display: block;
}

tbody {
  overflow: auto;
  width: 100%;
  max-height: 250px;
  height: 100%;
  color: #3a4750;
}

th {
  font-weight: 500;
  font-size: 12px;
}

tr {
  display: flex;
  justify-content: space-between;
}

th,
td {
  padding: 5px;
  width: 20%;
}

td {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  font-size: 13px;
}

.trCustom > td:not(:last-child),
th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

span {
  cursor: default;
}

.btnActions {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-menu {
  font-size: 12px;
  min-width: 5rem !important;
  width: 5rem !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  color: red;
  background: red;
  text-align: center;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
}

.setBtn {
  display: flex;
  flex-direction: row;
  gap: 10px;
  bottom: 0;
  right: 0;
  padding: 0 0.5rem;
  position: absolute;
}

.itemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #3a4750;
}

.itemBtn:hover {
  background: #3a4750;
  color: whitesmoke;
}

.itemBtn:disabled {
  opacity: 0;
}

.centerItemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #1b3159;
  cursor: auto;
}

.defaultSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30vh;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.defaultSearch span {
  font-size: 1rem;
  font-weight: bold;
  color: gray;
}

.ModalGeneral {
  position: fixed !important;
  z-index: 900;
  top: 50px;
  left: 61px;
  height: calc(100% - 53px);
  max-height: calc(100% - 53px);
  overflow: auto;
  width: calc(100% - 62px);
  background: #ffff;
  transition: all 0.7s ease-in-out;
}

.disable {
  position: absolute;
  width: calc(100% - 62px);
  height: 0;
  left: 61px;
  top: 100vh;
  background: #b0b0b000;
  visibility: hidden;
  transition: all 0.7s ease-in-out;
}

.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.contentModalDisable {
  display: none;
  background: white;
  opacity: 0;
}

.closeModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  z-index: 900;
  font-size: 18px;
  height: 30px;
  background: transparent;
  border: none;
  color: rgb(6, 6, 6);
}

.closeModal:hover {
  background: whitesmoke;
  color: red;
}

.closeModal:focus {
  background: red;
  border: none;
  box-shadow: none;
}

.conentTitleError p {
  margin: 0;
  font-size: 12px;
  color: red;
}

.conentError p {
  margin: 0;
  font-size: 12px;
  color: red;
}

@media screen and (max-width: 670px) {
  .contentInputReports {
    width: 100%;
  }
}
</style>